import React, { useContext, useState, useEffect, useRef } from "react";
import { useHistory } from "react-router-dom";
import TextSection from '../../elements/TextSection';
import Button from '../../elements/Button';
import Input from "../../elements/Input";
import DropdownMenu from "../../elements/DropDownMenu";
import TableSection from '../../sections/TableSection';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import AppointmentsDataClass from '../../data/pages/appointment/AppointmentsData';
import { fetchAppointments } from "../../../triggers/appointment/appointments";
import Notification from '../../sections/Notification';
import { gapi } from 'gapi-script';

function Appointments() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const history = useHistory();
    const notificationRef = useRef(null);
    const [loadingStatus, setLoadingStatus] = useState(true)
    const [appointments, setAppointments] = useState({});
    const [showAppointments, setShowAppointments] = useState({});
    const [searchInput, setSearchInput] = useState('');
    const [link, setLink] = useState('');
    const [searchParameter, setSearchParameter] = useState('');

    const CLIENT_ID = '983631550456-daq5frq3laovqgfglq04l2v172k2i5ln.apps.googleusercontent.com';
    const API_KEY = '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 ';
    const SCOPES = 'https://www.googleapis.com/auth/calendar.events';

    useEffect(() => {
        function start() {
            gapi.client.init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                scope: SCOPES,
            });
        }
        gapi.load('client:auth2', start);
    }, []);

    const handleAuthClick = (event) => {
        event.preventDefault(); // Prevent default action
        const authInstance = gapi.auth2.getAuthInstance();
        authInstance.signIn().then(() => {
            console.log('User signed in');
            listUpcomingEvents();
        });
    };

    const listUpcomingEvents = () => {
        gapi.client.calendar.events.list({
            'calendarId': 'primary',
            'timeMin': (new Date()).toISOString(),
            'showDeleted': false,
            'singleEvents': true,
            'maxResults': 10,
            'orderBy': 'startTime'
        }).then(response => {
            const events = response.result.items;
            if (events.length > 0) {
                console.log('Upcoming events:');
                events.forEach(event => {
                    const start = event.start.dateTime || event.start.date;
                    console.log(`${start} - ${event.summary}`);
                });
            } else {
                console.log('No upcoming events found.');
            }
        });
    };

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                history.push("/");
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice])

    const getData = async () => {
        const [appointmentsData, link] = await fetchAppointments();
        setLink(link);
        setShowAppointments(appointmentsData);
        setAppointments(appointmentsData);
        setLoadingStatus(false);
    }

    const copyButton = () => {
        navigator.clipboard.writeText(link);
        notificationRef.current.show();
    }
    const searchName = () => {
        const filtered = appointments.filter(appointment => appointment.name.toLowerCase().includes(searchInput.toLowerCase()));
        setShowAppointments(filtered);
    }; 
    
    const searchPhoneNumber = () => {
        const filtered = appointments.filter(appointment => appointment.phone_number.includes(searchInput));
        setShowAppointments(filtered);
    };

    const search = {
        "": () => setShowAppointments(appointments),
        name: searchName,
        phone_number: searchPhoneNumber
    };

    const searchFilter = (value) => {
        console.log(appointments);
        if(value != ""){
            const sortedAppointments = appointments.filter(appointment => appointment.status === value);
            setShowAppointments(sortedAppointments);
        }else
        setShowAppointments(appointments);
    }

    return (
        <div>

            {
                !loadingStatus
                ?
                (
                    <>
                        <Notification
                            ref={notificationRef}
                            message="Linkul pentru formularul de programari a fost copiat!"
                        />
                        <div className="flex items-center justify-between">
                            <TextSection textSection={AppointmentsDataClass.AppointmentsText(showAppointments, link).header} />
                            <div className="=flex">
                            <Button onClick={() => copyButton()} button={AppointmentsDataClass.AppointmentsText(showAppointments, link).copyLinkButton} />
                            <Button onClick={() => window.location.href = "/appointment"} button={AppointmentsDataClass.AppointmentsText(showAppointments, link).addAppointment} />
                            </div>
                        </div>
                        <div className="flex justify-between">
                            <div className="flex">
                                <Input onChange={e => setSearchInput(e.target.value)} input={AppointmentsDataClass.AppointmentsText(showAppointments, link).searchBoxAppointments} />
                                <DropdownMenu onChange={(e) => setSearchParameter(e.target.value)} value={searchParameter} dropdown={AppointmentsDataClass.AppointmentsText(showAppointments, link).dropDownMenuParameterSearch}/>
                                <Button onClick={() => search[searchParameter]()} button={AppointmentsDataClass.AppointmentsText(showAppointments, link).searchAppointment} />
                            </div>
                            <div className="flex">
                                <DropdownMenu onChange={(e) => searchFilter(e.target.value)} dropdown={AppointmentsDataClass.AppointmentsText(showAppointments, link).dropdownMenuFilter}/>
                                {/* <Button onClick={handleAuthClick} button={AppointmentsDataClass.AppointmentsText(showAppointments, link).googleCalendar} /> */}
                            </div>
                        </div>
                        <TableSection tableSection={AppointmentsDataClass.AppointmentsText(showAppointments, link).appointmentsList} />
                    </>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Appointments;
