import React from 'react';

function Image({ image }) {
  return (
    <img
      className={`object-contain ${image.classnames}`}
      src={image.src}
      width={image.width}
      height={image.height}
      alt={image.alt}
      title={image.title ? image.title : null}
    />
  );
}

export default Image;
