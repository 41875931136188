import React, { useContext, useEffect, useState } from 'react';
import TextSection from '../../elements/TextSection';
import Button from '../../elements/Button';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import helpData from '../../data/pages/multiplePurpose/HelpData';

function Help() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    
    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.hreh = "/welcome";
            if(!isAuthenticated) {
                localStorage.clear();
                window.location.href = "/"
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    return (
        <div className={helpData.classNameContainerPage}>
            <div className={helpData.classNameContainerCard}>
                <TextSection textSection={helpData.textSectionHeader} />
                <TextSection textSection={helpData.textSectionTutorials} />
                <div className='flex'>
                    <TextSection textSection={helpData.textSectionVideo} />
                    <Button onClick={() => window.open('https://www.youtube.com/@EffluenceSolutions')} button={helpData.videoButton} />
                </div>
                <div className='flex'>
                    <TextSection textSection={helpData.textSectionWritten} />
                    <Button onClick={() => window.open('https://tutorial.effluence.space')} button={helpData.writtenButton} />
                </div>
                <TextSection textSection={helpData.textSectionAlternative} />
                <div className='flex'>
                    <TextSection textSection={helpData.textSectionEmail} />
                    <Button onClick={() => window.open('mailto:support@effluence.space')} button={helpData.emailButton} />
                </div>
                <div className='flex'>
                    <TextSection textSection={helpData.textSectionPhone} />
                    <Button button={helpData.phoneButton} />
                </div>
            </div>
        </div>
    );
}

export default Help;
