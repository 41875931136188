class ClientsDataClass {
    constructor(){

    }

    static ClientsDataText(clients) {
        const headerSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Lista Clienti",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }
        
        const addClientManual = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
            tag: "button",
            text: "Adauga client manual"
        }
        
        const addClientScan =  {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
            tag: "button",
            text: "Adauga client scan"
        }
        
        const clientsList = {
            classNameContainer: 'your-container-class-name',
            headers: ['Nume', 'Email', 'Numar de telefon', 'Detalii'],
            rows: clients.map((item) => (
                {
                    cell: [item.name, item.email !== null ? item.email : "Emailul nu este disponibil" , item.phone_number],
                    additionalComponents: [
                        {
                            tagComponent: "button",
                            data: {
                                classnames: "text-white bg-blue-600 px-2 py-1 rounded",
                                tag: "button",
                                text: "Detalii"
                            },
                            location: "/client/" + item.client_id
                        }
                    ]
                }
            ))
        }
        
        const searchBoxClients = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded p-2 w-full  ",
            name: "",
            placeholder: "Cauta Client",
            value: ""
        }

        const dropDownMenuParameterSearch = {
            tagDropdown: "select",
            classNameContainer: "mx-2",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Selecteaza Parametrul"
                },
                {
                    value: "name",
                    label: "Nume"
                },
                {
                    value: "phone_number",
                    label: "Numar de telefon"
                }
            ]
        }

        const searchClient = {
            classnames: "text-white bg-blue-600 px-2 py-0.5 rounded mt-4 h-10",
            tag: "button",
            text: "Cauta Client"
        }
        
        return {
            header: headerSection,
            addClientManual: addClientManual,
            addClientScan: addClientScan,
            searchBoxClients: searchBoxClients,
            dropDownMenuParameterSearch: dropDownMenuParameterSearch,
            searchClient: searchClient,
            tableListClients: clientsList
        }
    }
}

export default ClientsDataClass;