const textSectionHeader ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Ajutor",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const textSectionTutorials ={
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "",
    header: "",
    textArray: [
        {
            classNameText: "text-md font-semibold",
            text: "Tutorile:"
        }
    ]
}

const textSectionVideo ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Video:"
        }
    ]
};

const videoButton = {
    classnames: "text-blue-500 text-section-container mx-4",
    tag: "button",
    text: "https://www.youtube.com/@EffluenceSolutions"
};

const textSectionWritten ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Text:"
        }
    ]
};

const writtenButton = {
    classnames: "text-blue-500 text-section-container mx-4",
    tag: "button",
    text: "https://tutorial.effluence.space"
};

const textSectionAlternative ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "",
    header: "",
    textArray: [
        {
            classNameText: "text-md font-semibold",
            text: "Contact:"
        }
    ]
};

const textSectionPhone ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Numar de telefon suport:"
        }
    ]
};

const phoneButton = {
    classnames: "text-blue-500 text-section-container mx-4",
    tag: "button",
    text: "0732572486"
};

const textSectionEmail ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Email suport:"
        }
    ]
};

const emailButton = {
    classnames: "text-blue-500 text-section-container mx-4",
    tag: "button",
    text: "support@effluence.space"
};


const addnotData = {
    classNameContainerPage: "flex",
    classNameContainerCard: "m-auto bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
    classNameContainerQuestion: "",
    textSectionHeader: textSectionHeader,
    textSectionTutorials: textSectionTutorials,
    textSectionVideo: textSectionVideo,
    videoButton: videoButton,
    textSectionWritten: textSectionWritten,
    writtenButton: writtenButton,
    textSectionAlternative: textSectionAlternative,
    textSectionPhone: textSectionPhone,
    phoneButton: phoneButton,
    textSectionEmail: textSectionEmail,
    emailButton: emailButton
};

export default addnotData;