import secureStorage from '../../modules/secureStorage/secure.js';
import { handleCreateAppointment, handleGetAllAppointments, handleDeleteAppointmentById, handleGetAppointmentById, handleUpdateAppointment, handleCreateAppointmentCustomer } from '../../modules/appointment/appointment.js';

export async function fetchAppointments() {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const respoonse = await handleGetAllAppointments({
            office_id: secure.getItem("UDP_UN").office_id
        });
        return [respoonse.sort((a, b) => {return a.status - b.status}), window.location.origin.toString() + "/add-appointment/" + secure.getItem("UDP_UN").office_id];
    } catch(error) {

    }
}

export async function fetchAppointmentData(id) {
    try {
        const response = await handleGetAppointmentById({
            appointment_id: id
        });
        const content = {...response};
        content['phoneNumber'] = content['phone_number']
        return content;
    } catch(error) {
        console.log(error);
    }
}

export async function deleteAppointment(id) {
    try {
        const response = await handleDeleteAppointmentById({
            appointment_id: id
        });
        console.log(response)
        const content = {...response};
        window.location.href = "/appointments"
        return content;
    } catch(error) {
        console.log(error);
    }
}

export async function submit(id, appointmentData) {
    try {
        const content = { ...appointmentData };
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        if(id) {
            await handleUpdateAppointment({
                office_id: secure.getItem("UDP_UN").office_id,
                appointment_id: id,
                name: content["name"],
                email: content["email"] ? content["email"] : "",
                phone_number: content["phoneNumber"],
                start_timedate: content["start_timedate"],
                category: content["category"],
                status: content["status"]
            })
        } else {
            await handleCreateAppointment({
                office_id: secure.getItem("UDP_UN").office_id,
                name: content["name"],
                email: content["email"],
                phone_number: content["phoneNumber"],
                start_timedate: content["start_timedate"],
                category: (content["category"] === undefined || content["category"] === null || content["category"] === "") ? "Altele" : content["category"],
            })
        }
    } catch(error) {
        console.log(error);
    }
    window.location.href = "/appointments";
}



export async function submitUnauthorized(id, appointmentData) {
    try {
        const content = { ...appointmentData };
        const response = await handleCreateAppointmentCustomer({
            office_id: id,
            name: content["name"],
            email: content["email"] ? content["email"] : "",
            phone_number: content["phoneNumber"],
            start_timedate: content["start_timedate"],
            category: content["category"]
        })
        console.log(response)
        return true
    } catch(error) {
        console.log(error);
    }
}
