const textSectionCardSectionAppointmentFeature = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold ",
    header: "Programări organizate",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container text-white",
    classNameHeader: "text-header text-white",
    header: "Simplificarea Programărilor pentru Clienți",
    textArray: [
        {
            text: "Prin acest sistem, clienții dvs. pot programa cu ușurință o zi și o oră convenabilă, eliminând necesitatea de a petrece timp la telefon",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container text-white",
    classNameHeader: "text-header text-white",
    header: "Programări Personalizate și Îndrumarea Documentelor",
    textArray: [
        {
            text: "Atunci când clienții se programează, li se solicită să furnizeze și motivul programării, astfel încât să poată primi o listă cu documentele necesare pe care trebuie să le aducă",
            classNameText: "text-sm text-white"
        }
    ]
}

const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatureAppointment = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'center grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-10',
    textSection: textSectionCardSectionAppointmentFeature,
    cards: [
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatureAppointment;