
function Button({ onClick, button }) {

  const ComponentButton = button.tag;

  return (
    <ComponentButton onClick={onClick} className={button.classnames}>
        {button.image ? 
          <img 
            src={button.image}
            className={button.imageClassName}
          /> : null
        }
        {button.text}
    </ComponentButton>
  );
}

export default Button;
