const textSectionCardSectionAppointmentFeature = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold ",
    header: "Contract Management",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Streamlined Collaboration: Defining Roles After Appointment Confirmation",
    textArray: [
        {
            text: "Through this process, after confirming the appointment, both the notary and the team members responsible for drafting the document delineate their responsibilities, ensuring clear distribution of work",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Flexible Delegation: Empowering Notaries and Ensuring Smooth Transitions",
    textArray: [
        {
            text: "The notary has the option to delegate the responsibility of drafting the document, and in case the designated person encounters difficulties, the document can be transferred to someone else",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Document Drafting Insights: Tracking and Analyzing Workflow History",
    textArray: [
        {
            text: "The history of those responsible for drafting the documents is preserved, generating statistics on the document drafting process",
            classNameText: "text-sm text-white"
        }
    ]
}

const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatureAppointment = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'center grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-10',
    textSection: textSectionCardSectionAppointmentFeature,
    cards: [
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatureAppointment;