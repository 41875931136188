const textSectionsTerms = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mt-8 mb-8 pl-2 w-full text-left shadow-xl bg-white", // Updated background color to grey
        classNameHeader: "text-blue-600 text-center mt-4 mb-8 text-header", // Updated header color to blue
        header: "Terms & Conditions",
        textArray: [
            {
                text: "1.1 We mean: the developers of the EFLUENCE application, an application owned by the company TECOOW SRL, established in accordance with the laws of Romania.",
                classNameText: "text-black" // Making the text black
            },
            {
                text: "1.2 You/User means: notarial societies or notarial offices that access the website and use our services.",
                classNameText: "text-black"
            },
            {
                text: "1.3 Services mean: the services presented on the Website, specific to the notarial profession, which we provide with the aim of digitizing and improving notarial activities.",
                classNameText: "text-black"
            },
            {
                text: "1.4 Terms and Conditions mean: all the provisions presented below that apply to the relationship between You and Us when you access or use the Website.",
                classNameText: "text-black"
            },
            {
                text: "1.5 GDPR Policies mean: The Cookie Policy available here.",
                classNameText: "text-black"
            },
            {
                text: "2. Applicability of Terms and Conditions. Binding Force",
                classNameText: "text-blue-600 text-center mt-8 mb-2 "
            },
            {
                text: "2.1 These Terms and Conditions, together with the GDPR Policies, apply every time You access and use the Website. Thus, to the extent that you navigate and use the Website, you understand and agree that the relationship between You and Us will be subject to these Terms and Conditions and the GDPR Policies, the provisions presented having binding legal force. In other words, accessing and navigating the Website constitutes your agreement to the application of these Terms and Conditions. To the extent that you do not agree with these Terms and Conditions or the GDPR Policies, please do not use the Website.",
                classNameText: "text-black"
            },
            {
                text: "2.2 Also, by accessing and using the Website, you declare that you meet all the legal conditions for issuing the above agreement and that there is no other legal impediment that would lead to the non-application of these Terms and Conditions. We do not assume any responsibility in case the information provided by You is not correct.",
                classNameText: "text-black"
            },
            {
                text: "2.3 By accepting the Terms and Conditions, you must take into account that no legal assistance contract is concluded between You and Us.",
                classNameText: "text-black"
            },
            {
                text: "3. The services we offer",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "3.1 Development of websites for users, websites containing various functions. The functions will be established by the user together with us in advance.",
                classNameText: "text-black" 
            },
            {
                text: "3.2 The Efluence platform offers an environment in which the user can organize their internal activities for drafting notarial documents, so they can delegate/undelegate who will handle each file.",
                classNameText: "text-black"
            },
            {
                text: "3.3 Furthermore, it will have a history of file delegations.",
                classNameText: "text-black"
            },
            {
                text: "3.4 The Efluence platform will offer the possibility to see statistics of employees assigned to draft notarial documents.",
                classNameText: "text-black"
            },
            {
                text: "3.5 The Effluence platform offers the user the option to see the appointments made by their clients on the websites we create.",
                classNameText: "text-black"
            },
            {
                text: "3.6 Effluence offers users the option to scan an identity card, in order to streamline the process of drafting notarial documents, thus being able to enter the data from the identity card through the 'copy paste' action.",
                classNameText: "text-black"
            },
            {
                text: "4. Privacy Policy",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "Last updated: 26.09.2020",
                classNameText: "text-black"
            },
            {
                text: " We respect the confidentiality of your personal data and are committed to protecting and managing these data responsibly. This privacy policy describes how we collect, store,",
                classNameText: "text-black"
            },
            {
                text: " Data Collected:",
                classNameText: "text-black"
            },
            {
                text: " We collect the following types of personal data from you:",
                classNameText: "text-black"
            },
            {
                text: "Contact information, such as name, email address, phone number.",
                classNameText: "text-black"
            },
            {
                text: "Identification information, such as tax identification number or billing information.",
                classNameText: "text-black"
            },
            {
                text: "Demographic information, such as age, gender, or geographic location.",
                classNameText: "text-black"
            },
            {
                text: "Information about your use of our services, such as authentication data, login information, information about your activity on our site, or browsing data.",
                classNameText: "text-black"
            },
            {
                text: "       Data Use:",
                classNameText: "text-black"
            },
            {
                text: " We use your personal data for the following purposes:",
                classNameText: "text-black"
            },
            {
                text: "To provide you access and manage your account on our site.",
                classNameText: "text-black"
            },
            {
                text: "To provide you with the services and fulfill your requests or orders.",
                classNameText: "text-black"
            },
            {
                text: "To send you updates and communications regarding our services.",
                classNameText: "text-black"
            },
            {
                text: "To improve our services, including data analysis and user behavior analysis.",
                classNameText: "text-black"
            },
            {
                text: "To comply with applicable laws and regulations.",
                classNameText: "text-black"
            },
            {
                text: "      Data Protection:",
                classNameText: "text-black"
            },
            {
                text: " We are committed to protecting your personal data and adopting appropriate security measures to prevent unauthorized access, disclosure, or modification.",
                classNameText: "text-black"
            },
            {
                text: "    Your Privacy Rights:",
                classNameText: "text-black"
            },
            {
                text: "You have the right to access, correct, or delete your personal data, as well as to withdraw your consent for their processing. To exercise these rights or for questions regarding our privacy policy, please contact us at the contact details displayed on the site.",
                classNameText: "text-black"
            },
            {
                text: "This is a general privacy policy and may be subject to subsequent changes. We recommend that you periodically review this policy to stay informed of the latest updates.",
                classNameText: "text-black"
            },
            {
                text: "5. Payment Methods:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "By using this service, the parties agree that the information processing system will be Netopia, in compliance with all conditions specified in this agreement.",
                classNameText: "text-black"
            },
            {
                text: "6. Applicable Law and Jurisdiction:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "6.1 By accepting the Terms and Conditions, you understand and agree that the applicable law will be the law of Romania, both in terms of substance and form.",
                classNameText: "text-black"
            },
            {
                text: "6.2 The place of acceptance of the Terms and Conditions is considered to be Romania.",
                classNameText: "text-black"
            },
            {
                text: "6.3 By accepting these Terms and Conditions, you agree that any disputes arising between You and Us will be resolved by the courts of Bucharest, Romania.",
                classNameText: "text-black"
            },
            {
                text: "7. Payment Methods:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "By using this service, the parties agree that the information processing system will be Netopia, in compliance with all conditions specified in this agreement.",
                classNameText: "text-black"
            },
            {
                text: "8. Dispute Resolution:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "In case of any disputes or complaints related to the services offered on this site, users can contact our support team at [contact address]. We will make efforts to resolve any issue promptly and efficiently.",
                classNameText: "text-black"
            },
            {
                text: "9. Data Protection:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "Personal data collected from this site will be processed in accordance with applicable data protection regulations, including the General Data Protection Regulation (GDPR). Users have the right to access, correct, or delete their personal data in accordance with our privacy policy.",
                classNameText: "text-black"
            },
            {
                text: "10. Disclaimer:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "By using the Effluence application, you agree that our team and the application itself disclaim any responsibility for any future misunderstandings or disputes that may arise during the use of our services in the free trial phase.",
                classNameText: "text-black"
            },
            {
                text: "11. Modification of Terms and Conditions Agreement:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "We reserve the right to modify or update this Terms and Conditions Agreement at any time, without prior notice. Users are responsible for periodically checking this document to stay informed of any changes.",
                classNameText: "text-black"
            },    
            {
                text: "12. Using the Effluence application, you agree that our team and the application itself disclaim any responsibility for any future misunderstandings or disputes that may arise during the use of our services in the free trial phase.",
                classNameText: "text-black"
            },
            {
                text: "13. Monthly Subscription Cancellation Policy:",
                classNameText: "text-blue-600 text-center mt-8 mb-2" 
            },
            {
                text: "We aim to provide you with the best possible experience with our monthly subscription. We understand that changes in your schedule and needs may occur, so we have created a flexible cancellation policy to help you manage your subscription conveniently.",
                classNameText: "text-black"
            },
            {
                text: "Cancellation with Advance Notice of 2 Days:",
                classNameText: "text-black"
            },
            {
                text: "You have the right to cancel your monthly subscription with at least 2 days' notice before the start date of a new payment month. For example, if your subscription renews on the 1st of each month, you must notify the cancellation by the 28th of the previous month to avoid a new payment fee.",
                classNameText: "text-black"
            },
            {
                text: "Quick Cancellation Process:",
                classNameText: "text-black"
            },
            {
                text: "To cancel the subscription, you can contact us through the contact methods displayed on the site or use the cancellation option available in your online account. Please provide the necessary information to identify you and process the cancellation efficiently.",
                classNameText: "text-black"
            },
            {
                text: "No Refund for Late Cancellations:",
                classNameText: "text-black"
            },
            {
                text: "Cancellations made less than 2 days before the start of the new payment month will not be refunded, and the subscription will continue until the end of the respective billing period.",
                classNameText: "text-black"
            },
            {
                text: "This cancellation policy is designed to provide you with the flexibility you need in managing your monthly subscription while ensuring efficient resource planning on our part.",
                classNameText: "text-black mb-4"
            }
        ]
    }
]; 

const buttonsTerms = [
    {
        refference: "/",
        button: {
            classnames: "text-blue-600 px-1.5 py-0.5 text-lg bg-white mt-6 ml-10 rounded mx-auto",
            tag: "button",
            text: "Go Back"
        }
    }
]

const terms = {
    classNameContainer: "flex flex-col text-left bg-blue-600 rounded-lg mt-6 mb-6", // Updated background color to blue
    textSections: textSectionsTerms,
    button : buttonsTerms
}

export default terms;
