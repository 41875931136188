class ClientDataClass {
    static ClientDataText(userInfo, files){
        const formatDate = {
            start: new Date(userInfo.document_validity_start),
            end: new Date(userInfo.document_validity_end)
        }
        const displayDate = {
            start: userInfo.document_validity_start !== null ? formatDate.start.getDate() + "/" + (Number(formatDate.start.getMonth()) + 1).toString().padStart(2, "0") + "/" + formatDate.start.getFullYear() : null,
            end: userInfo.document_validity_end !== null ? formatDate.end.getDate() + "/" + (Number(formatDate.end.getMonth()) + 1).toString().padStart(2, "0") + "/" + formatDate.end.getFullYear() : null
        }
        const textSectionCredentials = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: userInfo.name,
            textArray: [
                {
                    classNameText: "text-content",
                    text: "Numar de Telefon: " + userInfo.phone_number
                },
                {
                    classNameText: "text-content",
                    text: userInfo.email !== null ? "Email Personal: " + userInfo.email : "Emailul Personal: Nu este disponibil"
                },
                {
                    classNameText: "text-content",
                    text: "CNP: " + userInfo.personal_code
                },
                {
                    classNameText: "text-content",
                    text: userInfo.series !== null ? "Serie Buletine: " + userInfo.series : "Serie Buletine: Seria buletinului nu este disponibila"
                },
                {
                    classNameText: "text-content",
                    text:userInfo.series_no !== null ?  "Numar Buletin: " + userInfo.series_no :  "Numar Buletin: Numarul din buletin nu este disponibil"
                },
                {
                    classNameText: "text-content",
                    text: userInfo.citizenship !== null ? "Cetatenie: " + userInfo.citizenship : "Cetatenie: Cetatenia nu este disponibila"
                },
                {
                    classNameText: "text-content",
                    text: displayDate.start !== null ? "Valabilitate act de identitate: " + displayDate.start + " - " + displayDate.end : "Valabilitate act de identitate: Aceste date nu sunt disponibile"
                },
                {
                    classNameText: "text-content",
                    text: userInfo.address_document ? "Adresa de rezidenta Buletin: " + userInfo.address_document : "Adresa de rezidenta Buletin: Adresa nu este disponibila"
                }
            ]
        }

        const buttonCredentials = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
            tag: "button",
            text: "Editeaza Cont"
        }

        const imageCredentials = {
            classnames: "",
            src: "../images/profile-icon.svg",
            width: "200",
            height: "200",
            alt: ""
        }

        const profileCredentials = {
            classNameContainer: "",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: textSectionCredentials,
                    button: buttonCredentials,
                    image: imageCredentials,
                    onClick: () => {
                        window.location.href = "/client-add/" + userInfo.client_id;
                    }
                }
            ]
        }

        const tasks = {
            classNameContainer: "",
            cards: files.map((item) => (
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: {
                        tagHeader: "h1",
                        tagText: "div",
                        classNameContainer: "text-section-container",
                        classNameHeader: "text-header",
                        header: item.title,
                        textArray: [
                            {
                                classNameText: "text-content",
                                text: "Categorie: " + item.category
                            },
                            {
                                classNameText: "text-content",
                                text: "Cod Document: " + item.document_code
                            }
                        ]
                    },
                    button: {
                        classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
                        tag: "button",
                        text: "Detalii"
                    },
                    image: {
                        classnames: "",
                        src: "",
                        width: "",
                        height: "",
                        alt: ""
                    },
                    onClick: () => {
                        window.location.href = "/file/" + item.file_id;
                    }
                }
            ))
        }

        const textSectionFiles = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container bg-white py-3 p-2 rounded shadow-md",
            classNameHeader: "text-header",
            header: "Cazuri in care " + userInfo.name + " a fost implicat(a):",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }

        return {
            classNameContainer: "",
            profileCredentials: profileCredentials,
            classNameContainerCards: "",
            textSectionFiles: textSectionFiles,
            tasks: tasks
        }

    }
}

export default ClientDataClass;