import secureStorage from '../../modules/secureStorage/secure.js';
import { handleGetAllTasksByOfficeId, handleGetTask, handleCreateTask, handleUpdateTask, handleGetAllTasksByUserId, handleDeleteTask } from '../../modules/tasks/tasks.js';

export async function fetchAllTasksByOfficeId() {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetAllTasksByOfficeId({
            office_id: secure.getItem("UDP_UN").office_id
        });
        
        return response;
    } catch(error) {
        console.error(error);
    }
}

export async function fetchAllTasksByUserId(id) {
    try {
        if(id) {
            const responseTasksPerUser = await handleGetAllTasksByUserId({
                asignee_id: id
            });
            console.log(responseTasksPerUser)
            return responseTasksPerUser;
        } else {
            const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
            const responseTasksPerUser = await handleGetAllTasksByUserId({
                asignee_id: secure.getItem("UDP_UN").notary_id
            });
            return responseTasksPerUser;
        }
    } catch(error) {
        console.error(error);
    }
}

export async function fetchTask(id) {
    if(id) {
        try {
            const response = await handleGetTask({
                task_id: id
            });
            console.log(response)
            return response;
        } catch(error) {
            console.error(error);
        }
    }
}

export async function handleMarkAsDone(task) {
    try {
        console.log(task)
        const updatedTask = { ...task, status: true };
        console.log("updated undo:", updatedTask);
        await updateTask(updatedTask, task.task_id);
        window.location.reload(); 
    } catch (error) {
        console.error("Error updating task status:", error);
    }
};

export async function handleUndoDone(task) {
    try {
        const updatedTask = { ...task, status: false };
        console.log("updated undo:", updatedTask);
        await updateTask(updatedTask, task.task_id);
        window.location.reload(); 
    } catch (error) {
        console.error("Error updating task status:", error);
    }
};

export async function updateTask(data, id) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const content = { ...data };
        if(id){
            console.log(JSON.stringify)
            await handleUpdateTask({
                office_id: secure.getItem("UDP_UN").office_id,
                task_id: id,
                title: content["title"],
                description: content["description"],
                date_time_deadline: content["date_time_deadline"],
                date_time_creation: content["date_time_creation"],
                asignee_id: content["asignee_id"],
                creator_id: content["creator_id"],
                status: content["status"]
            });
        } else {
            const taskData = {
                title: content["title"],
                description: content["description"],
                date_time_deadline: content["date_time_deadline"],
                asignee_id: content["asignee_id"],
                creator_id: secure.getItem("UDP_UN").notary_id,
                status: content["status"],
                office_id: secure.getItem("UDP_UN").office_id,
            }
            await handleCreateTask(taskData);
        }
    } catch(error) {
        console.error(error);
    }
    window.location.href = "/tasks";
}

export async function deleteTask(item) {
    try {
        if(item.task_id) {
            try {
                await handleDeleteTask({
                    task_id: item.task_id
                });
            } catch(error) {
                console.error(error);
            }
        }
    } catch(error) {
        console.error(error);
    }
    window.location.href = "/tasks";
}

