import React, { useContext, useEffect, useState } from "react";
import Header from '../../elements/TextSection';
import Button from '../../elements/Button';
import TableSection from '../../sections/TableSection';
import Input from '../../elements/Input';
import DropdownMenu from "../../elements/DropDownMenu";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import ClientsDataClass from '../../data/pages/clients/ClientsData';
import { fetchClients } from "../../../triggers/clients/clients";

function Clients() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [searchParameter, setSearchParameter] = useState('');
    const [searchInput, setSearchInput] = useState("");
    const [clients, setClients] = useState('{}');
    const [showClients, setShowClients] = useState('{}');

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else window.location.href = "/signin"
        }
    }, [loading, isAuthenticated, isUserOfOffice])

    const getData = async () => {
        const data = await fetchClients();
        setClients(data);
        setShowClients(data);
        setLoadingStatus(false);
    }

    const searchName = () => {
        const filtered = clients.filter(client => client.name.includes(searchInput));
        setShowClients(filtered);
    };

    const searchPhoneNumber = () => {
        const filtered = clients.filter(client => client.phone_number.includes(searchInput));
        setShowClients(filtered);
    };  

    const search = {
        "": () => setShowClients(clients),
        name: searchName,
        phone_number: searchPhoneNumber,
    };
    
    return (
        <div>
            {
                !loadingStatus
                ?
                (
                    <>
                        <div className="flex items-center justify-between">
                        <Header textSection={ClientsDataClass.ClientsDataText(showClients).header} />
                            <div>
                                <Button onClick={() => window.location.href = "client-add"} button={ClientsDataClass.ClientsDataText(showClients).addClientManual} />
                                {/* <Button onClick={() => window.location.href = "client-add-scan"} button={ClientsDataClass.ClientsDataText(showClients).addClientScan} /> */}
                            </div>
                        </div>
                        <div className="flex">
                            <Input onChange={e => setSearchInput(e.target.value)} input={ClientsDataClass.ClientsDataText(showClients).searchBoxClients} />
                            <DropdownMenu onChange={(e) => setSearchParameter(e.target.value)} value={searchParameter} dropdown={ClientsDataClass.ClientsDataText(showClients).dropDownMenuParameterSearch}/>
                            <Button onClick={() => search[searchParameter]()} button={ClientsDataClass.ClientsDataText(showClients).searchClient} />
                        </div>
                        <TableSection tableSection={ClientsDataClass.ClientsDataText(showClients).tableListClients} />
                    </>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Clients;
