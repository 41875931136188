class AppointmentsDataClass{
    constructor(){
    }

    static AppointmentsText(appointments, deleteAppointment, link){
        const headerSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Lista Programari",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }


        const copyLinkButton = {
            classnames: "text-blue-500 bg-white border border-blue-500 p-1 rounded mt-4 mr-2 h-10",
            tag: "button",
            image: "./images/share.svg",
            imageClassName: "w-5 h-5 inline-block ml-2 mr-2",
            text: "Distribuie formularul"
        }
        
        const addFolder = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
            tag: "button",
            text: "Creeaza o programare"
        }
        
        
        const searchBoxAppointments = {
            classnames: "mt-4 h-10",
            classnamesInput: "border border-blue-500 rounded p-2",
            name: "",
            placeholder: "Cauta Programare",
            value: ""
        }

        const dropDownMenuParameterSearch ={
            tagDropdown: "select",
            classNameContainer: "mx-2",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Selecteaza Parametrul"
                },
                {
                    value: "name",
                    label: "Nume client"
                },
                {
                    value: "phone_number",
                    label: "Numar de Telefon"
                }
            ]
        }

        const searchAppointment = {
            classnames: "text-white bg-blue-600 px-2 py-0.5 rounded mt-4 h-10",
            tag: "button",
            text: "Cauta programare"
        }

        const dropdownMenuFilter ={
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Filtreaza"
                },
                        {
                    value: "101",
                    label: "In asteptare"
                },
                {
                    value: "200",
                    label: "In lucru"
                },
                {
                    value: "500",
                    label: "Finalizate"
                }
            ]
        }

        const googleCalendar = {
            classnames: "text-white bg-blue-600 px-2 py-0.5 rounded ml-2 mt-4 h-10",
            tag: "button",
            image: "./images/calendar.svg",
            imageClassName: "w-5 h-5 inline-block ml-2 mr-2 mb-1",
            text: "Google"
        }
    
        
        const appointmentsList = {
            classNameContainer: 'your-container-class-name',
            headers: ['Nume Client', 'Numar de telefon', 'Dosar', 'Status', 'Detalii'],
            rows: appointments.map((item) => ({
                cell: [item.name, item.phone_number, item.category],
                additionalComponents: [
                    {
                        tagComponent: "image",
                        data: {
                            classnames: "w-8 h-8 px-1 py-1 mr-3 flex items-center justify-center",
                            src: (item.status === "101" 
                                ? "./images/status-to-do-icon.svg"
                                : (item.status === "200"
                                    ? "./images/status-in-progress-icon.svg"
                                    : "./images/status-archived-icon.svg")),
                            width: "",
                            height: "",
                            alt: "Status Image",
                        }
                    },
                    {
                        tagComponent: "button",
                        data: {
                            classnames: "text-white bg-blue-600 px-2 py-1 rounded",
                            tag: "button",
                            text: "Detalii",
                        },
                        location: '/appointment/' + item.appointment_id,
                    }
                ]
            }))
        };
        
        return {
            header: headerSection,
            addAppointment: addFolder,
            searchBoxAppointments: searchBoxAppointments,
            dropDownMenuParameterSearch: dropDownMenuParameterSearch,
            searchAppointment: searchAppointment,
            copyLinkButton: copyLinkButton,
            googleCalendar: googleCalendar,
            dropdownMenuFilter: dropdownMenuFilter,
            appointmentsList: appointmentsList
        }
    }
}

export default AppointmentsDataClass;