class AddClientDataScanClass {
    static AddClientDataScanText() {
        const textSectionAddClient ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Adaugă Buletinul",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };

        
        const upload = {
            classnames: "hidden",
            type: "file",
            name: "scan",
            text: ""
        };
   
        const createButton = {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: "Extrage date"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "min-h-screen flex items-center justify-center ",
            classNameContainerCard: "min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            textSectionAddClient: textSectionAddClient,
            upload: upload,
            addButton: createButton,
            cancelButton: cancelButton
            
        };
    }
}

export default AddClientDataScanClass;
