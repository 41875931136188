import React from 'react';
import Button from '../elements/Button';

function HeroWithVideo({ heroWithVideo }) {
    return (
        <div className="flex flex-col lg:flex-row items-center justify-between py-10 lg:py-20 px-4 lg:px-8">
            <div className="w-full lg:w-1/2 mb-10 lg:mb-0 lg:pr-8 text-center lg:text-left">
                <h1 className="text-3xl lg:text-4xl xl:text-5xl font-bold mb-4">{heroWithVideo.title}</h1>
                <p className="text-lg lg:text-xl mb-6">{heroWithVideo.subtitle}</p>
                <Button 
                    button={{
                        classnames: "bg-blue-600 cursor-pointer text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300",
                        tag: "a",
                        text: heroWithVideo.buttonText
                    }}
                    onClick={() => window.location.href = heroWithVideo.buttonLink}
                />
            </div>
            <div className="w-full lg:w-1/2 mt-10 lg:mt-0">
                <div className="relative w-full rounded-2xl overflow-hidden shadow-2xl" style={{paddingBottom: '52%'}}> {/* 16:9 Aspect Ratio */}
                    <div className="absolute inset-0 bg-gradient-to-r from-blue-500 to-cyan-500 opacity-20"></div>
                    <video 
                        className="absolute top-0 left-0 w-full h-full object-cover rounded-lg shadow-lg"
                        controls
                        autoPlay
                        muted
                        loop
                        playsInline
                    >
                        <source src="https://video-effluence-website.s3.eu-north-1.amazonaws.com/hero-video.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    );
}

export default HeroWithVideo;