import React from 'react';
import Menu from '../../components/sections/Menu.js';

const LayoutDefault = ({ children }) => (
  <div className="flex bg-grey-100">
    <Menu navPosition="right" className="reveal-from-bottom w-1/5" />
    <main className="site-content w-4/5 mt-14 m-auto">
      {children}
    </main>
  </div>
);

export default LayoutDefault;
