import React from "react";

function DropdownMenu({ onChange, value, dropdown }) {
    const DropdownComponent = dropdown.tagDropdown;

    return (
        <div className={dropdown.classNameContainer}>
            <DropdownComponent 
                value={value}
                onChange={onChange}
                className={dropdown.classNameDropdown}
            >
                {dropdown.options.map((option, index) => (
                    <option key={index} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </DropdownComponent>
        </div>
    );
}

export default DropdownMenu;
