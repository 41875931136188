import SecureStorage from 'secure-web-storage';
import CryptoJS from 'crypto-js';

class SecureStorageClass {
    constructor() {
        this._SECRET_KEY = 'default key';
    }

    set SECRET_KEY(value) {
        this._SECRET_KEY = value;
    }

    get SECRET_KEY() {
        return this._SECRET_KEY;
    }

    secureStorage(secretKey) {
        if (secretKey) {
            this._SECRET_KEY = secretKey;
        }

        const secureStorage = new SecureStorage(localStorage, {
            hash: (key) => {
                key = CryptoJS.SHA256(key, this._SECRET_KEY);
                return key.toString();
            },
            encrypt: (data) => {
                data = CryptoJS.AES.encrypt(data, this._SECRET_KEY);
                data = data.toString();
                return data;
            },
            decrypt: (data) => {
                data = CryptoJS.AES.decrypt(data, this._SECRET_KEY);
                data = data.toString(CryptoJS.enc.Utf8);
                return data;
            }
        });

        return secureStorage;
    }
}

export default new SecureStorageClass();
