import React from 'react';
import Button from '../elements/Button';
import Image from '../elements/Image';
import UserIcons from './UserIcons';
import TextSection from '../elements/TextSection';

function CardSection({ cardSection }) {
  return (
    <div>
        {
            cardSection.textSection ?
            (
                <div className={cardSection.classNameTextSection}> {/* Center the standalone TextSection */}
                    <TextSection textSection={cardSection.textSection} />
                </div>
            )
            :
            (
                <></>
            )
        }
        <div className={cardSection.classNameContainer}>
            {
                cardSection.cards.map((card) => (
                    <div className={card.classNameContainer}>
                        {
                            card.user
                            ?
                            (
                                <div className='flex items-center space-x-1'>
                                    <UserIcons userIcons={card.userIcons} />
                                    <TextSection textSection={card.textSection} />
                                    <Button onClick={card.onClickView} button={card.buttonView} />
                                    <Button onClick={card.onClickDelete} button={card.buttonDelete} />
                                </div>
                            )
                            :
                            (
                                card.image ?
                                (
                                    <>
                                        <Image image={card.image} />
                                        <TextSection textSection={card.textSection} />
                                        <Button onClick={card.onClick} button={card.button} />
                                    </>
                                )
                                :
                                (
                                    <>
                                        <TextSection textSection={card.textSection} />
                                        <Button onClick={card.onClick} button={card.button} />
                                    </>
                                )
                            )
                        }
                    </div>
                ))
            }
        </div>
    </div>
  );
}

export default CardSection;
