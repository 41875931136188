import APIError from "../class/error.class.js";

export default async function getData(data) {
    return fetch(`https://ssl2024.effluence.space${data.endPoint}`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data.requestBody)
    })
    .then(response => {
        if (!response.ok) {
            throw new APIError("Get Data", 400, true, "Something went wrong: Request failed.")
        }
        return response.json();
    })
    .catch(error => {
        console.error('Fetch error:', error);
    });
}
