const imageFooter = {
    classnames: "",
    src: "./images/logo.svg",
    width: "130",   
    height: "",
    alt: ""
}

const imageFooterAnpc = {
    classnames: "mt-4 ",
    src: "./images/anpc-logo.png",
    width: "200",   
    height: "40",
    alt: ""
}

const textSectionsFooter = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mr-4",
        classNameHeader: "font-semibold text-blue-500",
        header: "Email Office",
        textArray: [
            {
                text: "office@effluence.space",
                classNameText: "text-blue-500"
            }
        ]
    },
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mr-4",
        classNameHeader: "font-semibold text-blue-500",
        header: "Email Support",
        textArray: [
            {
                text: "support@effluence.space",
                classNameText: "text-blue-500"
            }
        ]
    }
]

const iconFB = {
    classnames: "mt-2 mb-4 mr-5",
    src: "./images/facebook-logo.svg",
    width: "20",   
    height: "20",
    alt: ""
}

const iconLnk = {
    classnames: "mt-1 mb-4",
    src: "./images/linkedin-logo.svg",
    width: "28",   
    height: "28",
    alt: ""
}


const buttonsFooter = [
    {
        refference: "/policies",
        button: {
            classnames: "sm:my-2 text-white bg-blue-600 px-2 py-1 ml-12 rounded",
            tag: "button",
            text: "Termeni și Condiții"
        }
    }
]

const footer = {
    classNameContainer: "flex flex-col py-12 px-4 mt-6 sm:px-6 lg:px-8",
    imageLeft: imageFooter,
    imageRight: imageFooterAnpc,
    iconFB: iconFB,
    iconLnk: iconLnk,
    textSections: textSectionsFooter,
    buttons: buttonsFooter
}

export default footer;