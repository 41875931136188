import getData from '../../request_methods/dataGet.js';
import postData from '../../request_methods/dataPost.js';
import putData from '../../request_methods/dataPut.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';
import deleteData from '../../request_methods/dataDelete.js';

export const handleCreateAppointment = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/create/post', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        console.log(response)
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        console.log(error)
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleCreateAppointmentCustomer = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/create/post', appointmentData, 301, "");
        const response = await postData(requestObject.getRequest());
        console.log(response)
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        console.log(error)
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetNumberOfAppointments = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/total-number-appointments-month-selected/get', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllAppointments = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/all-appointments/get', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAppointmentById = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/details-about-appointment-by-id/get', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteAppointmentById = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/delete', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateAppointment = async (appointmentData) => {
    try {
        const requestObject = new RequestClass('/appointment/change-details-on-appointment/update', appointmentData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}