import secureStorage from '../../modules/secureStorage/secure.js';
import { handleGetFile, handleGetAllFiles, handleUpdateFile, handleCreateFile, handleGetAllNotesOnFile, handleGetAllClientsOnFile, 
    handleNotariesOnFile, handleAddNoteToFile, handleDeleteNotaryFromFile, 
    handleDeleteClientFromFile,handleAddNotaryToFile, handleAddClientToFile, handleDeleteFile } from '../../modules/files/files';
import { handleGetAllClients } from '../../modules/clients/clients';
import { handleGetAllNotaries } from '../../modules/notary/notary';

export async function fetchFile(id) {
    if(id) {
        try {
            const response = await handleGetFile({
                file_id: id
            });
            return response;
        } catch(error) {
            console.error(error);
        }
    }
}

export async function deleteFile(id) {
    if(id) {
        console.log(id)
        try {
            const response = await handleDeleteFile({
                file_id: id
            });
            window.location.href="/folder"
            return response;
        } catch(error) {
            console.error(error);
        }
    }
}

export async function sendData(id, data) {
    try {
        if(id){
            await handleUpdateFile(data);
        } else {
            const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
            const content = { ...data };
            content["office_id"] = secure.getItem("UDP_UN").office_id;  
            await handleCreateFile(content);
        }
    } catch(error) {
        console.log(error);
    }
    window.location.href = "/folder";
}

export async function sendDataScan(data) {
    try {
            const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
            const content = { ...data };
            content["office_id"] = secure.getItem("UDP_UN").office_id;  
            const response = await handleCreateFile(content);
            console.log(response.data)
            window.location.href = "/file/" + response.data.file_id;
        }
        catch(error) {
            console.log(error);
            window.location.href = "/folder";
    }
}

export async function sendDataClient(data) {
    try {
            const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
            const content = { ...data };
            content["office_id"] = secure.getItem("UDP_UN").office_id;  
            const response = await handleCreateFile(content);
            return response.data
        }
        catch(error) {
            console.log(error);
            window.location.href = "/clients";
    }
}

export async function fetchActiveFiles(id) {
    try {
        const responseFileDetails = await handleGetFile({
            file_id: id
        });
        const documentParsed = responseFileDetails.category.toLowerCase() === "id" ?
                               generateHtmlForEditor(JSON.parse(responseFileDetails.document)) :
                               getTextFromAnnotations(JSON.parse(responseFileDetails.document));
        const parsedData = {
            file_id: responseFileDetails.file_id,
            office_id: responseFileDetails.office_id,
            title: responseFileDetails.title,
            category: responseFileDetails.category,
            status: responseFileDetails.status,
            document_code: responseFileDetails.document_code,
            start_date: responseFileDetails.start_date,
            end_date: responseFileDetails.end_date,
            document: documentParsed
        }
        console.log(parsedData)

        return parsedData;
    } catch(error) {
        
    }
}


function generateHtmlForEditor(data) {
    if (!data) return '';
    let startDateRaw = data.valabilitate.substring(0, 8);
    data.startDate = startDateRaw.substring(0, 6) + '20' + startDateRaw.substring(6, 8);
    data.endDate = data.valabilitate.substring(9, 19);
    data.valabilitate = data.startDate + "-" + data.endDate
    const elements = [
        { label: 'Nume', value: data.nume },
        { label: 'Prenume', value: data.prenume },
        { label: 'Sex', value: data.sex },
        { label: 'Adresa', value: data.adresa },
        { label: 'CNP', value: data.cnp },
        { label: 'Seria', value: data.seria },
        { label: 'Numar', value: data.numar },
        { label: 'Valabilitate', value: `${data.startDate} - ${data.endDate}` },
        { label: 'Emis', value: data.emis },
        { label: 'Text Prelucrat', value: `${data.nume} ${data.prenume}, cetățean român, domiciliat${data.sex === 'F' ? 'ă' : ''} în ${data.adresa}, CNP ${data.cnp}, identificat${data.sex === 'F' ? 'ă' : ''} cu CI seria ${data.seria} nr.${data.numar}/${data.startDate} - ${data.emis}`}
    ];

    return elements.map(el => 
        `<span class="text-blue-500 flex" data-label="${el.label}" data-value="${el.value}">
            <p class="font-semibold mr-4">${el.label}:</p>
            <p class="cursor-pointer">${el.value}</p>
        </span>`
    ).join('<br>');
}



function getTextFromAnnotations(textAnnotations) {
    if(textAnnotations == null) return null;
    // Group words into lines based on their vertical position
    const lines = [];
    let currentLine = [];
    let lastY = null;
    console.log(typeof textAnnotations)
    if(typeof textAnnotations === "object") {
        textAnnotations.slice(1).forEach(annotation => { // Skip the first element which contains the full text
        const boundingPoly = annotation.boundingPoly.vertices;
        const y = boundingPoly[0].y;
        if (lastY === null || Math.abs(y - lastY) < 10) {
            currentLine.push(annotation);
        } else {
            lines.push(currentLine);
            currentLine = [annotation];
        }
        lastY = y;
        });
        if (currentLine.length) lines.push(currentLine);
    
        // Prepare the text lines with formatting adjustments
        let resultText = '';
        lines.forEach((line, index) => {
        let lineText = line.map(annotation => annotation.description).join(' ');
    
        // Remove spaces before and after hyphens and punctuation
        lineText = lineText.replace(/\s-\s/g, '-') // Remove spaces around hyphens
                            .replace(/\s+([.,?!:;])/g, '$1'); // Remove spaces before punctuation
    
        // Append a new line to separate paragraphs if the vertical space is significant
        if (index > 0 && Math.abs(lines[index][0].boundingPoly.vertices[0].y - lines[index - 1][0].boundingPoly.vertices[0].y) > 30) {
            resultText += '<br><br>' + lineText;
        } else {
            resultText += (index > 0 ? '<br>' : '') + lineText;
        }
        });
        return resultText;
    } else {
        const lines = textAnnotations.split('\n\n');
        console.log(lines)
        // Initialize variables
        let formattedText = '';
        let currentParagraph = '';
    
        // Process each line
        lines.forEach((line, index) => {
            const paragraphs = line.split('\n');
            paragraphs.forEach((paragraph, index) => {
                formattedText += paragraph + '<br>';
            
            // Append line to the current paragraph
            if (currentParagraph.length > 0) {
                currentParagraph += ' ';
            }
            currentParagraph += paragraph;
    
            currentParagraph = currentParagraph.replace(/\s-\s/g, '-') // Remove spaces around hyphens
                                               .replace(/\s+([.,?!:;])/g, '$1'); // Remove spaces before punctuation
            });
    
            
        })
        return formattedText;
        
    }
  }
  

export async function addNote(id, note) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        await handleAddNoteToFile({
            file_id: id,
            note: note,
            notary_id: secure.getItem("UDP_UN").notary_id,
            office_id: secure.getItem("UDP_UN").office_id
        });
        window.location.reload()
    } catch(error) {
        console.log(error);
    }
}

export async function deleteNotary(file, notary) {
    try {
        await handleDeleteNotaryFromFile({
            file_id: file,
            notary_id: notary
        });
        window.location.reload()
    } catch(error) {
        console.log(error);
    }
}

export async function deleteClient(file, client) {
    try {
        await handleDeleteClientFromFile({
            file_id: file,
            client_id: client
        });
        window.location.reload()
    } catch(error) {
        console.log(error);
    }
}

export async function addNotary(id, notaryDataAdd) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        await handleAddNotaryToFile({
            file_id: id,
            notary_id: notaryDataAdd,
            office_id: secure.getItem("UDP_UN").office_id,
            start_date: new Date()
        });
        window.location.reload();
    } catch(error) {
        console.log(error);
    }
}

export async function addClient(id, clientDataAdd) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        await handleAddClientToFile({
            file_id: id,
            client_id: clientDataAdd,
            office_id: secure.getItem("UDP_UN").office_id,
        });
        window.location.reload();
    } catch(error) {
        console.log(error);
    }
}

export async function fetchFiles() {
    try{
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetAllFiles({
            office_id: secure.getItem("UDP_UN").office_id
        });
        return response;
    } catch(error) {
        console.log(error);
    }
}