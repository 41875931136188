const buttonsHeader = [
    {
        classnames: "text-white bg-blue-600 px-2 py-1 rounded invisible md:visible",
        tag: "button",
        text: "Sign In",
        reference: "/signin"
    },
    {
        classnames: "text-white bg-blue-600 px-2 py-1 rounded invisible md:visible",
        tag: "button",
        text: "Sign Up",
        reference: "/signup"
    }
];

const dropdown = {
    tagDropdown: "select",
    classNameContainer: "invisible md:visible",
    classNameDropdown: "text-white bg-blue-600 px-2 py-1.5 rounded",
    options: [
                {
            value: "EN",
            label: "EN"
        },
        {
            value: "RO",
            label: "RO"
        }
    ]
}

const buttonsHeaderBurger = [
    {
        classnames: "text-white bg-blue-600 px-2 py-1 rounded",
        tag: "button",
        text: "Sign In",
        reference: "/signin"
    },
    {
        classnames: "text-white bg-blue-600 px-2 py-1 rounded",
        tag: "button",
        text: "Sign Up",
        reference: "/signup"
    }
];

const dropdownBurger = {
    tagDropdown: "select",
    classNameContainer: "",
    classNameDropdown: "text-white bg-blue-600 px-2 py-2 rounded",
    options: [
                {
            value: "EN",
            label: "EN"
        },
        {
            value: "RO",
            label: "RO"
        }
    ]
}

const imageHeader = {
    classnames: "",
    src: "./images/logo.svg",
    width: "130",
    height: "",
    alt: ""
}

const header = {
    classNameContainer : "flex items-center justify-between p-1 md:px-8 lg:px-16 mt-1  shadow-md",
    image: imageHeader,
    dropdown: dropdown,
    buttons: buttonsHeader,
    dropdownBurger: dropdownBurger,
    buttonsBurger: buttonsHeaderBurger
}

export default header;