import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Header from '../../elements/TextSection';
import Button from '../../elements/Button';
import Input from '../../elements/Input';
import DropdownMenu from "../../elements/DropDownMenu";
import TableSection from '../../sections/TableSection';
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import FolderDataClass from '../../data/pages/files/FolderData';
import { fetchFiles, sendDataScan, deleteFile } from "../../../triggers/files/files";

function Folder({ secureStorage }) {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const history = useHistory();

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [files, setFiles] = useState('{}');
    const [showFiles, setShowFiles] = useState('{}');
    const [searchParameter, setSearchParameter] = useState("");
    const [filterParameter, setFilterParameter] = useState("");
    const [searchInput, setSearchInput] = useState('');

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                history.push("/");
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        const response = await fetchFiles();
        setLoadingStatus(false)
        setFiles([...response].sort((a, b) => {return a.status - b.status;}));
        setShowFiles([...response].sort((a, b) => {return a.status - b.status;}));
    }

    const searchDocumentCode = () => {
        const filtered = files.filter(file => file.document_code.includes(searchInput));
        setShowFiles(filtered);
    }; 
    
    const searchTitle = () => {
        const filtered = files.filter(file => file.title.toLowerCase().includes(searchInput.toLocaleLowerCase()));
        setShowFiles(filtered);
    };
    
    const searchCategory = () => {
        const filtered = files.filter(file => file.category.includes(searchInput));
        setShowFiles(filtered);
    };

    const search = {
        "": () => setShowFiles(files),
        document_code: searchDocumentCode,
        title: searchTitle,
        category: searchCategory
    };

    const searchFilter = (value) => {
        const sortedFiles = files.sort(file => file.status === parseInt(value));
        setShowFiles(sortedFiles);
    };
    

    return (
        <div>
            {
                !loadingStatus
                ?
                (
                    <>
                        <div className="flex items-center justify-between">
                            <Header textSection={FolderDataClass.FolderDataText(showFiles).header} />
                        </div>
                        <div className="flex justify-between">
                            <div className="flex">
                                <Input onChange={e => setSearchInput(e.target.value)} input={FolderDataClass.FolderDataText(showFiles).searchBoxFiles} />
                                <Button onClick={() => searchTitle()} button={FolderDataClass.FolderDataText(showFiles).searchFile} />
                            </div>
                            <Button onClick={() => sendDataScan({
                                title: 'Scan ' + new Date().toLocaleString('ro-RO', {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }),
                                category: 'Scan',
                                status: 101,
                                start_date: new Date(),
                                document_code: 'A20-15-2020'
                            })} button={FolderDataClass.FolderDataText(showFiles).addFolder} />
                        </div>
                        <TableSection tableSection={FolderDataClass.FolderDataText(showFiles).folderList} />
                    </>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Folder;
