import '../../styles/elements/input.css'

function Input({ value, onChange, input }) {

    return (
      <div className={input.classnames}>
          <input
            className={`hidden-number-arrows ${input.classnamesInput}`}
            type={input.type}
            name={input.name}
            value={value}
            placeholder={input.placeholder}
            onChange={onChange}
          />
      </div>
    );
  }
  
  export default Input;
  