import React from "react";
import { Route, Redirect } from 'react-router-dom';

import AppRoute from "./AppRoute";

export const PrivateRoute = ({ 
    component: Component, 
    layout: Layout,
    isAuthenticated,
    path,
    ...rest 
}) => (
    <Route
        {...rest}
        render={(props) =>
        isAuthenticated ? (
            <AppRoute path={path} component={Component} layout={Layout} {...props}/>
        ) : (
            <Redirect to="/signin" />
        )
        }
    />
);
  
export const PublicRoute = ({ 
    component: Component, 
    layout: Layout,
    isAuthenticated, 
    path,
    restricted, 
    ...rest 
}) => (
    <Route
        {...rest}
        render={(props) =>
        isAuthenticated && restricted ? (
            <Redirect to="/dashboard" />
        ) : (
            <AppRoute exact path={path} component={Component} layout={Layout} {...props}/>
        )
        }
    />
);
