import React from 'react';
import Button from '../elements/Button';
import TextSection from '../elements/TextSection';
import Image from '../elements/Image';

import '../../styles/sections/footer.css'

function Footer({ footer }) {
  return (
    <div className={footer.classNameContainer}>
        <Image image={footer.imageLeft}/>
        <div className="sm:flex flex-row">
            <a href="#" target="_blank">
               <Image image={footer.iconFB}/>
            </a>
            <div className=' footer-icons'>
            <a href="https://www.linkedin.com/company/effluence/" target="_blank">
               <Image image={footer.iconLnk}/>
            </a>
            </div>
          </div>
          
        <div className="sm:flex justify-between">
          <div className='sm:flex flex-row'>
            {footer.textSections.map( (textSection) => (
              <TextSection textSection={textSection}/>
            ))}
            </div>
        
            <div className="footer-container"> 
              {footer.buttons.map((button) => (
                <a href={button.reference} key={button.reference}>
                  <Button onClick={() => window.location.href = button.reference} button={button.button}/> 
                  <Image image={footer.imageRight}/>
                </a>
              ))}
            </div>

        </div>

    </div>
  );
}

export default Footer;
