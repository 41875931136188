import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";

import CardSection from "../../sections/CardSection";
import Button from "../../elements/Button";
import Input from "../../elements/Input";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';

import FileDataClass from '../../data/pages/files/FileData';
import TextSection from '../../elements/TextSection';
import DocumentEditor from '../../sections/DocumentEditor';
import DropdownMenu from '../../elements/DropDownMenu';
import { fetchActiveFiles, addNote, deleteNotary, deleteClient, addNotary, addClient, deleteFile } from '../../../triggers/files/files.js';

function File() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [infoStatus, setInfoStatus] = useState(false);
    const [note, setNote] = useState('{}');
    const [data, setData] = useState('{}');
    const [fileData, setFileData] = useState();
    const [clientDataAdd, setClientDataAdd] = useState();
    

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice]);

    const getData = async () => {
        const responseFileDetails = await fetchActiveFiles(id);
        setFileData(responseFileDetails);
        setData(FileDataClass.FileDataText(responseFileDetails, deleteFile));
        setLoadingStatus(false);
    }

    const changeInfoStatus = () => {
        setInfoStatus(!infoStatus);
    };

    return(
        <div>
            {
                !loadingStatus
                ?
                (
                    <div className={data.classNameContainer}>  
                        <CardSection cardSection={data.fileCredentials} />
                        <DocumentEditor documentEditor={data.documentEditor} />
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default File;