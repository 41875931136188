import getData from '../../request_methods/dataGet';
import RequestClass from '../../class/request.class';
import APIError from '../../class/error.class';

export default async function handleSignIn(signInData)  {
    try {
        const requestObject = new RequestClass('/authentification/get', signInData, 102, "");
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.message === 'internal server error' || error.name === 'Authentification') 
            throw new APIError("Logare Esuata", error.httpCode, true, "A intervenit o eroare in procesarea datelor, datele de logare sunt incorecte.");
        else
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
    }
}