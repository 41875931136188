class Request {
    constructor(
        endPoint,
        data,
        status,
        token
    ) {
        this.endPoint = endPoint;
        this.data = data;
        this.status = status;
        this.token = token;
    }

    getRequest() {
        return {
            endPoint: this.endPoint,
            requestBody: {
                data: this.data,
                authentificationData: {
                    status: this.status,
                    token: this.token
                }
            }
        };
    }
}

export default Request;