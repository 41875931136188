class AddClientDataClass {
    static AddClientDataText(isEdited) {
        const textSectionAddClient ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Adaugă Client",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const textSectionDate ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Valabilitate Document:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };

        const textSectionDateStart ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "De la:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };

        const textSectionDateEnd ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Valabilitate Document:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const sex ={
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Selectează Sex"
                },
                        {
                    value: true,
                    label: "Masculin"
                },
                {
                    value: false,
                    label: "Feminin"
                }
            ]}
        
        
        
        const Email = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full ",
            name: "",
            placeholder: "Email Client",
            value: ""
        };
        
        const name = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Nume Client",
            value: ""
        };
        
        const cnp = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 pl-4 w-full  ",
            name: "",
            placeholder: "CNP",
            value: ""
        };
        
        const nrserie = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 pl-4 w-full  ",
            name: "",
            placeholder: "Număr ",
            value: ""
        };
        const serie = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2  pl-4 w-full  ",
            name: "",
            placeholder: "Serie",
            value: ""
        };
        const adresa = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Adresă",
            value: ""
        };
        
        const adresares = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Adresa Resedinta",
            value: ""
        };
        
        const cetatenie = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Cetățenie",
            value: ""
        };
        
        const locnastere = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Loc Nastere",
            value: ""
        };
        
        const phoneNumber = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Număr Telefon",
            value: ""
        };
        
        const valstart = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "dd/mm/yyyy",
            value: ""
        };
        
        const valend = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "dd/mm/yyyy",
            value: ""
        };
        
        const createButton = {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: isEdited ? "Salvează" : "Adauga"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "min-h-screen flex items-center justify-center ",
            classNameContainerCard: "min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            textSectionAddClient: textSectionAddClient,
            textSectionDate: textSectionDate,
            name: name,
            email: Email,
            phoneNumber: phoneNumber,
            cnp: cnp,
            sex: sex,
            nrserie: nrserie,
            serie: serie,
            textSectionDateStart: textSectionDateStart,
            valstart: valstart,
            valend: valend,
            adresa: adresa,
            adresares: adresares,
            cetatenie: cetatenie,
            locnastere: locnastere,
            addButton: createButton,
            cancelButton: cancelButton
            
        };
    }
}

export default AddClientDataClass;
