import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import CardSection from '../../sections/CardSection';
import Footer from '../../sections/Footer';
import DropdownMenu from '../../elements/DropDownMenu';
import { MoonLoader } from 'react-spinners';
import AddAppointmentData from '../../data/pages/appointment/AddAppointmentData.js';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { submitUnauthorized } from '../../../triggers/appointment/appointments';
import { getOffice } from '../../../triggers/office/office';
import DateBox from 'devextreme-react/date-box';

function AddApointment() {
    const [appointment, setAppointment] = useState({});
    const [appointmentData, setAppointmentData] = useState({});
    const [warning, setWarning] = useState({});
    const [successStatus, setSuccessStatus] = useState(false)
    const [loadingStatus, setLoadingStatus] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        getData()
    }, [])

    const getData = async () => {
        const response = await getOffice(id);
        if(!response.error) setAppointment(AddAppointmentData.CreateAppointmetText(response.name))
        else {
            localStorage.setItem("HTTP_CODE", response.HTTP_CODE);
            localStorage.setItem("ERR_NM", response.ERR_NM);
            localStorage.setItem("ERR_MSG", response.ERR_MSG);
            window.location.href = "/error"
        }
        setLoadingStatus(false);
    } 

    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            console.log(content)
            setWarning(content);
        }
    }

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validEmail' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['email'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest email nu este valid.',
                '',
                false
            );
            content['email'] = warningObject.warning();
            setWarning(content);
        }
    }
    
    const phoneNumberVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validPhoneNumber' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['phoneNumber'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest numar de telefon nu este valid, trebuie sa contina 10 cifre.',
                '',
                false
            );
            content['phoneNumber'] = warningObject.warning();
            setWarning(content);
        }
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'name': {
                stringVerifier(e.target.value, 'name');
                const content = { ...appointmentData };
                content['name'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'email': {
                emailVerifier(e.target.value);
                const content = { ...appointmentData };
                content['email'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'phoneNumber': {
                phoneNumberVerifier(e.target.value);
                const content = { ...appointmentData };
                content['phoneNumber'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'start_timedate': {
                const selectedDate = e.value;
                const trueDate = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
                const dateChoice = trueDate.getFullYear() + "-" + (Number(trueDate.getMonth()) + 1).toString().padStart(2, "0") + "-" + (trueDate.getDate()).toString().padStart(2, "0") + " " + trueDate.getHours().toString().padStart(2, "0") + ":" + trueDate.getMinutes().toString().padStart(2, "0")+ ":00";
                stringVerifier(dateChoice, 'start_timedate');
                const content = { ...appointmentData };
                content['start_timedate'] = dateChoice;
                setAppointmentData(content);
                break;
            }
            case 'category': {
                stringVerifier(e.target.value, 'category');
                const content = { ...appointmentData };
                content['category'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'status': {
                stringVerifier(e.target.value, 'status');
                const content = { ...appointmentData };
                content['status'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if(typeof warning['name'] !== 'undefined' && typeof warning['phoneNumber'] !== 'undefined' && typeof warning['category'] !== 'undefined'){
            if(!(warning['name'] || warning['phone'] || warning['category'])) {
                await submitUnauthorized(id, appointmentData);
                setSuccessStatus(true);
            } else {
                const content = {...warning};
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Toate campurile sunt necesare!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content); 
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile sunt necesare!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }

    }
    return  (
        <>
            {loadingStatus ? (
                <MoonLoader
                    className="flex m-auto mt-32"
                    color="#2563eb"
                    size={140}
                />
            ) : !successStatus ? (
                <>
                    <div className={appointment.classNameContainer}>
                        <TextSection textSection={appointment.textSectionHeader} />
                        <div className={appointment.classNameContainerCard}>
                            <TextSection textSection={appointment.textSectionHeaderCard} />
                            <Input onChange={(e) => onInputChange(e, 'name')} value={appointmentData.name} input={appointment.name} />
                            {warning['name'] ? <TextSection textSection={warning['name']} /> : null}
                            <Input onChange={(e) => onInputChange(e, 'email')} value={appointmentData.email} input={appointment.email} />
                            <Input onChange={(e) => onInputChange(e, 'phoneNumber')} value={appointmentData.phoneNumber} input={appointment.phoneNumber} />
                            {warning['phoneNumber'] ? <TextSection textSection={warning['phoneNumber']} /> : null}
                            {/* <div>
                                <TextSection textSection={appointment.header2} />
                                <div className="flex flex-row space-x-4 mt-2">
                                    <DateBox
                                        type="datetime"
                                        value={appointment.start_timedate}
                                        onValueChanged={(e) => onInputChange(e, 'start_timedate')}
                                        displayFormat="dd-MM-yyyy HH:mm"
                                        format="dd-MM-yyyy HH:mm"
                                        placeholder="ZZ/LL/AAAA HH:MM"
                                    />
                                </div>
                            </div> */}
                            <DropdownMenu onChange={(e) => onInputChange(e, 'category')} value={appointmentData.category} dropdown={appointment.category} />
                            {warning['category'] ? <TextSection textSection={warning['category']} /> : null}
                            {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null}
                            <div className="flex justify-between">
                                <Button onClick={() => handleSubmit()} button={appointment.createButton} />
                            </div>
                        </div>
                        <Footer footer={appointment.footer} />
                    </div>
                    
                </>
            ) : (
                <>
                    <div className={appointment.classNameContainer}>
                        <TextSection textSection={appointment.textSectionHeader} />
                        <CardSection cardSection={appointment.cardSectionSuccess} />
                    </div>
                    <Footer footer={appointment.footer} />
                </>
            )}
        </>
    );
}

export default AddApointment;
