import getData from '../../request_methods/dataGet.js';
import postData from '../../request_methods/dataPost.js';
import putData from '../../request_methods/dataPut.js';
import deleteData from '../../request_methods/dataDelete.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export const handleCreateTask = async (taskData) => {
    try {
        const requestObject = new RequestClass('/task/create/post', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}

export const handleGetTask = async (taskData) => {
    try {  
        const requestObject = new RequestClass('/task/details/get', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}

export const handleGetAllTasksByOfficeId = async (taskData) => {
    try {  
        const requestObject = new RequestClass('/task/all-tasks-by-office-id/get', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}

export const handleGetAllTasksByUserId = async (taskData) => {
    try {  
        const requestObject = new RequestClass('/task/all-tasks-by-user-id/get', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}

export const handleUpdateTask = async (taskData) => {
    try {
        const requestObject = new RequestClass('/task/update/put', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}

export const handleDeleteTask = async (taskData) => {
    try {
        const requestObject = new RequestClass('/task/delete', taskData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din nou procesul.");
    }
}
