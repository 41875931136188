const textSectionCardSectionAppointmentFeature = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold ",
    header: "Managementul contractelor",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Colaborare Eficientă: Definirea Rolurilor După Confirmarea Programării",
    textArray: [
        {
            text: "Prin acest proces, după confirmarea programării, atât notarul, cât și membrii echipei responsabili de redactarea actului își delimitează responsabilitățile, asigurând o distribuire clară a muncii.",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Delegare Flexibilă: Împuternicirea Notarilor",
    textArray: [
        {
            text: "Notarul are opțiunea de a delega responsabilitatea redactării actului, iar în cazul în care persoana desemnată se confruntă cu dificultăți, actul poate fi transferat altcuiva",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Perspective asupra Redactării Documentelor",
    textArray: [
        {
            text: "Istoricul responsabililor pentru redactarea actelor este păstrat, astfel se generează o statistică cu privire la procesul de redactare a actelor",
            classNameText: "text-sm text-white"
        }
    ]
}

const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatureAppointment = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'center grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-10',
    textSection: textSectionCardSectionAppointmentFeature,
    cards: [
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatureAppointment;