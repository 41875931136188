import React from 'react';

const LayoutVerification = ({ children }) => (
  <div className="flex bg-grey-100">
    <main className="site-content m-auto">
      {children}
    </main>
  </div>
);

export default LayoutVerification;
