const textSectionCardSectionAppointmentFeature = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold ",
    header: "Organized Appointments",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container text-white",
    classNameHeader: "text-header text-white",
    header: "Simplify Scheduling for Your Clients",
    textArray: [
        {
            text: "Through this system, your clients can easily schedule a convenient day and time, eliminating the need for phone calls",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container text-white",
    classNameHeader: "text-header text-white",
    header: "Tailored Appointments and Document Guidance",
    textArray: [
        {
            text: "When clients schedule appointments, they are asked to provide the reason for the appointment so that they can receive a list of the necessary documents to bring",
            classNameText: "text-sm text-white"
        }
    ]
}

const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatureAppointment = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'center grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2 mb-10',
    textSection: textSectionCardSectionAppointmentFeature,
    cards: [
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatureAppointment;