import React from "react";
import { useHistory } from 'react-router-dom';

import Button from '../elements/Button';
import Image from "../elements/Image";
import UserIcons from "./UserIcons";

import '../../styles/sections/cardSection.css';

function TableSection({ tableSection }) {
    const history = useHistory();

    const handleLocationChange = (location) => {
        history.push(location);
        window.location.reload();
    };
    return (
        <div className={tableSection.classNameContainer}>
            <table className="border border-transparent border-separate border-spacing-y-4 w-full">
                <thead>
                    <tr>
                        {tableSection.headers.map((header, index) => (
                            <th key={index} className="text-left p-2">
                                {header}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {tableSection.rows.map((row, rowIndex) => (
                        <tr key={rowIndex} className="shadow-md rounded my-2">
                            {row.cell.map((cell, cellIndex) => (
                                <td key={cellIndex} className="p-2">
                                    {cell}
                                </td>
                            ))}
                            {row.additionalComponents.length > 0 &&
                                row.additionalComponents.map((component, compIndex) => (
                                    <td key={`comp-${rowIndex}-${compIndex}`} className="p-2">
                                        {   component.tagComponent === 'button'
                                            ?
                                            <Button 
                                                onClick={component.onClick || (() => handleLocationChange(component.location))} 
                                                button={component.data} 
                                            />
                                            : 
                                            (
                                                component.tagComponent === 'image'
                                                ? 
                                                <Image image={component.data} />
                                                :
                                                <UserIcons userIcons={component.data} />
                                            )
                                        }
                                    </td>
                                ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
}

export default TableSection;