class TasksDataClass {
    constructor() {}

    static TasksDataText(tasksDone, tasksNow) {
        const headerSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Lista Task-uri",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }

        const addTask = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
            tag: "button",
            text: "Adauga Task"
        }

        const tasksListDone = 
            tasksDone.map((item) => ({
                doneTasks: item,
                text1: item.title,
                text2: item.name_asignee,
                text3: item.name_creator,
                text4: item.date_time_deadline,
                button: {
                    text: "Detalii",
                    location: '/task/' + item.task_id,
                },
                button2: {
                    text: "Revenire"
                },
                button3: {
                    text: "Stergere"
                }
            }));

        const tasksListNow = 
            tasksNow.map((item) => ({
                nowTasks: item,
                text1: item.title,
                text2: item.name_asignee,
                text3: item.name_creator,
                text4: item.date_time_deadline,
                button: {
                    text: "Detalii",
                    location: '/task/' + item.task_id,
                },
                button2: {
                    text: "Finalizare"
                },
                button3: {
                    text: "Stergere"
                }
            }));

        const searchBoxTasks = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded p-2 w-full",
            name: "",
            placeholder: "Cauta Task",
            value: ""
        }

        const dropDownMenuParameterSearch = {
            tagDropdown: "select",
            classNameContainer: "mx-2",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Selecteaza Parametrul"
                },
                {
                    value: "title",
                    label: "Titlu"
                },
                {
                    value: "notary",
                    label: "Nume Angajat"
                }
            ]
        }

        const searchTask = {
            classnames: "text-white bg-blue-600 px-2 py-0.5 rounded mt-4 h-10",
            tag: "button",
            text: "Cauta Task"
        }

        return {
            header: headerSection,
            addTask: addTask,
            searchBoxTasks: searchBoxTasks,
            dropDownMenuParameterSearch: dropDownMenuParameterSearch,
            searchTask: searchTask,
            tasksListNow: tasksListNow,
            tasksListDone: tasksListDone
        }
    }
}

export default TasksDataClass;
