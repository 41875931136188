import secureStorage from '../../modules/secureStorage/secure.js';
import { handleUpdateNotaryToOffice, handleGetAllNotaries, handleAceptOffer, handleGetNotary, handleDeclineOffer } from '../../modules/notary/notary';
import { handleGetAllTasksByUserId, handleGetAllTasksByOfficeId } from '../../modules/tasks/tasks.js';

export async function isAdmin() {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        return secure.getItem("UDP_UN").admin;
    } catch(error) {
        localStorage.setItem('ERR_TYP', 'error');
        localStorage.setItem('ERR_NM', error.name);
        localStorage.setItem('ERR_MSG', error.message);
        localStorage.setItem('ERR_CRD', true);
        return false;
    }
}

export async function submit(user) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        await handleUpdateNotaryToOffice({
            office_id: secure.getItem("UDP_UN").office_id,
            notary_id: secure.getItem("UDP_UN").notary_id,
            credentials: user
        });
        window.location.href = "/notary";
        window.location.reload();
    } catch(error) {
        localStorage.setItem('ERR_TYP', 'error');
        localStorage.setItem('ERR_NM', error.name);
        localStorage.setItem('ERR_MSG', error.message);
        localStorage.setItem('ERR_CRD', true);
        return false;
    }
}

export async function fetchNotaries() {
    try {  
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const responseAllNotaries = await handleGetAllNotaries({
            office_id: secure.getItem("UDP_UN").office_id
        });
        const responseTasksNotaries = await handleGetAllTasksByOfficeId({
            office_id: secure.getItem("UDP_UN").office_id
        });
        let responseDataNotaries = []
        responseAllNotaries.forEach(notary => {
            responseDataNotaries.push(
                {
                    all_tasks: responseTasksNotaries.filter(task => task.asignee_id === notary.notary_id).length,
                    active_tasks: responseTasksNotaries.filter(task => task.asignee_id === notary.notary_id && !task.status).length,
                }
            )
        });
        return [responseAllNotaries, responseDataNotaries, secure.getItem("UDP_UN").admin, `https://effluence.space/signup?office=${secure.getItem("UDP_UN").office_id}`];
    } catch(error) {
        console.log(error)
    }
}

export async function fetchActiveFiles(id) {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH")); 
        const responseNotary = await handleGetNotary({
            notary_id: id ? id : secure.getItem("UDP_UN").notary_id
        });
        const responseTasksPerUser = await handleGetAllTasksByUserId({
            asignee_id: id ? id : secure.getItem("UDP_UN").notary_id    
        });
        return [responseNotary, responseTasksPerUser];
    } catch(error) {
        console.log(error)
    }
}

export async function acceptNotary(notaryId) {
    try {
        await handleAceptOffer({
            notary_id: notaryId
        });
        return true;
    } catch(error) {
        console.error(error);
        return false;
    }
}

export async function declineNotary(notaryId) {
    try {
        await handleDeclineOffer({
            notary_id: notaryId,
        });
        return true;
    } catch(error) {
        console.error(error);
        return false;
    }
}