import React, { createContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import handleTokenVerification from '../../modules/authentification/tokenVerification.js';
import secureStorage from '../../modules/secureStorage/secure.js';

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const history = useHistory();

  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isUserOfOffice, setIsUserOfOffice] = useState(false);
  useEffect(() => {
    setLoading(true);
    verifyData();
  }, []);

  const verifyData = async () => {
    try {
      const response = await handleTokenVerification();
      if(response) {
        setIsAuthenticated(true);
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        if(secure.getItem("UDP_UN").status) setIsUserOfOffice(true);
      } else {
          if(response.error){
            localStorage.setItem("ERR", JSON.stringify({
              error_name: response.error_name,
              error_message: response.error_message,
              error_httpCode: response.error_httpCode
            }));
          } else {
            localStorage.clear();
            history.push("/signin")
          }
      }
    } catch(error) {
      if(error.name.includes("Token")){
        localStorage.clear();
        history.push("/signin")
      }
    }
    setLoading(false);
  }

  return <AuthContext.Provider value={{loading, isAuthenticated, isUserOfOffice}}>{children}</AuthContext.Provider>;
};

export { AuthContext, AuthProvider };