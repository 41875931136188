import React, { useState, forwardRef, useImperativeHandle } from "react";
import "../../styles/sections/notification.css";

const Notification = forwardRef((props, ref) => {
  const [showNotification, setShowNotification] = useState(false);

  const hideNotification = () => {
    setShowNotification(false);
  }
  
  useImperativeHandle(ref, () => ({
    show() {
      setShowNotification(true);
      setTimeout(() => {
        setShowNotification(false);
      }, 5000);
    },
  }));
  return (
    <div
      className="notification bg-white border border-blue-700 text-blue-700"
      id={showNotification ? "show" : "hide"}
    >
        <button className="symbol font-bold" onClick={hideNotification}>{<h1>&#x2613;</h1>}</button>
      <div className="message">{props.message}</div>
    </div>
  );
});

export default Notification;