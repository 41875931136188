class DashboardDataClass {
    static DasboardText(appointmentsAllCurrentMonthGet, activeFilesInOffice) {
            // Card Section Data

            const textSectionCardSectionInfoAppointments = {
                tagHeader: "h1",
                tagText: "div",
                classNameContainer: "text-section-container",
                classNameHeader: "text-2xl font-bold mb-2 text-blue-600",
                header: appointmentsAllCurrentMonthGet,
                textArray: [
                    {
                        classNameText: "text-lg font-semibold text-blue-600",
                        text: "Număr total de programări/luna selectată"
                    }
                ]
            }

            const textSectionCardSectionInfoClients = {
                tagHeader: "h1",
                tagText: "div",
                classNameContainer: "text-section-container",
                classNameHeader: "text-2xl font-bold mb-2 text-blue-600",
                header: activeFilesInOffice,
                textArray: [
                    {
                        classNameText: "text-lg font-semibold text-blue-600",
                        text: "Număr total de taskuri în lucru în birou"
                    }
                ]
            }

            const buttonCardSectionGeneralInfo = {
                classnames: "",
                tag: "button",
                text: ""
            }

            const imageCardSectionGeneralInfo = {
                classnames: "",
                src: "",
                width: "",
                height: "",
                alt: ""
            }

            const cardSectionGeneralInfo = {
                classNameContainer: "grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-2",
                cards: [
                    {
                        classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                        textSection: textSectionCardSectionInfoAppointments,
                        button: buttonCardSectionGeneralInfo,
                        image: imageCardSectionGeneralInfo
                    },
                    {
                        classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                        textSection: textSectionCardSectionInfoClients,
                        button: buttonCardSectionGeneralInfo,
                        image: imageCardSectionGeneralInfo
                    }
                ]
            }

            // Card Section important tasks

            // Card Section Data

            const textSectionCardSectionLatestActivity = {
                tagHeader: "h1",
                tagText: "div",
                classNameContainer: "text-section-container",
                classNameHeader: "text-header",
                header: "Effortless Collaboration",
                textArray: [
                    {
                        classNameText: "text-content",
                        text: ""
                    }
                ]
            }

            const buttonCardSectionLatestActivity = {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
                tag: "button",
                text: "Button Two"
            }

            const imageCardSectionLatestActivity = {
                classnames: "",
                src: "",
                width: "",
                height: "",
                alt: ""
            }

            const cardSectionLatestActivity = {
                classNameContainer: "grid grid-cols-1 gap-1 md:grid-cols-2 lg:grid-cols-1",
                cards: [
                    {
                        classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                        textSection: textSectionCardSectionLatestActivity,
                        button: buttonCardSectionLatestActivity,
                        image: imageCardSectionLatestActivity
                    },
                    {
                        classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                        textSection: textSectionCardSectionLatestActivity,
                        button: buttonCardSectionLatestActivity,
                        image: imageCardSectionLatestActivity
                    }
                ]
            }

            const addClientManual = {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
                tag: "button",
                text: "Adauga client manual"
            }
            
            const addClientScan =  {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
                tag: "button",
                text: "Adauga client scan"
            }

            const addFolder = {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
                tag: "button",
                text: "Adauga Scan"
            }

            const addAppointment = {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
                tag: "button",
                text: "Creeaza o programare"
            }

            const addTask = {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1",
                tag: "button",
                text: "Adauga Task"
            }
            

            return {
                classNameContainer: "display-flex felex align-center",
                informationAboutTheOffice: cardSectionGeneralInfo,
                theLatestActions: cardSectionLatestActivity,
                addClientManual:addClientManual,
                addClientScan: addClientScan,
                addFolder: addFolder,
                addTask: addTask,
                addAppointment: addAppointment
            }
    }
}

export default  DashboardDataClass;