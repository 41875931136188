class AddFileData {

    static AddFileDataText(isEdited) {
        const textSectionHeader ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Adaugare Caz",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const category = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Categorie"
                },
                        {
                    value: "Comodat",
                    label: "Comodat"
                },
                {
                    value: "Divort",
                    label: "Divort"
                },
                {
                    value: "Vanzare",
                    label: "Vanzare"
                },
                {
                    value: "Contract Prenuptial",
                    label: "Contract Prenuptial"
                },
                {
                    value: "Procura",
                    label: "Procura"
                },
                {
                    value: "Declaratii",
                    label: "Declaratii"
                },
                {
                    value: "Succesiune",
                    label: "Succesiune"
                },
                {
                    value: "Legalizare",
                    label: "Legalizare"
                },
            ]
        }
        
        const status = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Status"
                },
                        {
                    value: 101,
                    label: "In asteptare"
                },
                {
                    value: 200,
                    label: "In lucru"
                },
                {
                    value: 500,
                    label: "Arhivat"
                }
            ]
        }
        

        const title = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Nume Caz",
            value: ""
        };

        const documentCode = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "An-Cod-Numar",
            value: ""
        };
        
        
        const createButton = {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-10",
            tag: "button",
            text: isEdited ? "Salveaza" : "Creeaza"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-10",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "min-h-screen flex items-center justify-center ",
            classNameContainerCard: "min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            classNameContainerQuestion: "",
            textSectionHeader: textSectionHeader,
            title: title,
            documentCode: documentCode,
            category: category,
            status: status,
            createButton: createButton,
            cancelButton: cancelButton
            
        };
    }
}

export default AddFileData;