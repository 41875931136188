
function CheckBox({ onChange, value, checkBox }) {
  
  return (
    <label className={checkBox.classnames}>
      <p className={checkBox.textClassName}>{checkBox.text}</p>
      <input
        type="checkbox"
        name={checkBox.name}
        value={value}
        onChange={onChange}
        />
    </label>
  );
}

export default CheckBox;
