import React, { useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import DropdownMenu from '../../elements/DropDownMenu';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import AddClientDataClass from '../../data/pages/clients/AddClientData';
import { sendData, fetchClient } from '../../../triggers/clients/clients';
import DateBox from 'devextreme-react/date-box';
import '../../../styles/sections/calendar.css';

function AddClient() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [data, setData] = useState('{}');
    const [clientaddData, setClientAddData] = useState('{}');
    const [ edited, setEdited] = useState(false);

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                setClientAddData(AddClientDataClass.AddClientDataText(id ? true : false));
                getData();
                setLoadingStatus(false);
            }
            else window.location.href = "/signin";
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        if(id) {
            const response = await fetchClient(id);
            console.log(response)
            setData(response)
        }
    }

    const changeInput = (e, value) => {
        const content = { ...data };
        if (value === 'document_validity_start' || value === 'document_validity_end') {
            content[value] = new Date(e).getFullYear() + "-" + (Number(new Date(e).getMonth()) + 1).toString().padStart(2, "0") + "-" + new Date(e).getDate() ;
        } else {
            console.log(e.target.value)
          content[value] = e.target.value;
        }
        setEdited(true);
        setData(content);
    };    

    return(
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={clientaddData.classNameContainer}>
                        <div className={clientaddData.classNameContainerCard}>
                        <TextSection textSection={clientaddData.textSectionAddClient} />
                        <Input onChange={(e) => changeInput(e, "name")} value={data.name} input={clientaddData.name}/>
                        <Input onChange={(e) => changeInput(e, "email")} value={data.email} input={clientaddData.email}/>
                        <Input onChange={(e) => changeInput(e, "phone_number")} value={data.phone_number} input={clientaddData.phoneNumber}/>
                        <Input onChange={(e) => changeInput(e, "personal_code")} value={data.personal_code} input={clientaddData.cnp}/>
                        <div class="flex flex-row space-x-4">
                            <Input onChange={(e) => changeInput(e, "series")} value={data.series} input={clientaddData.serie}/>
                            <Input onChange={(e) => changeInput(e, "series_no")} value={data.series_no} input={clientaddData.nrserie}/>
                        </div>
                        <Input onChange={(e) => changeInput(e, "address_document")} value={data.address_document} input={clientaddData.adresa}/>
                        <Input onChange={(e) => changeInput(e, "citizenship")} value={data.citizenship} input={clientaddData.cetatenie}/>

                        <TextSection textSection={clientaddData.textSectionDate}/>
                        <div className='flex justify-between space-between mt-2'>
                            <DateBox
                                value={data.document_validity_start}
                                onValueChange={(e) => changeInput(e, "document_validity_start")}
                                displayFormat="dd/MM/yyyy"
                                placeholder="ZZ/LL/AAAA"
                            />
                            <p className='font-bold mx-2'>-</p>
                            <DateBox
                                value={data.document_validity_end}
                                onValueChange={(e) => changeInput(e, "document_validity_end")}
                                displayFormat="dd/MM/yyyy" 
                                placeholder="ZZ/LL/AAAA"
                            />
                        </div>
                        <DropdownMenu onChange={(e) => changeInput(e, "sex")} value={data.sex} dropdown={clientaddData.sex}/>

                        <div className="flex justify-between">
                            <Button
                                button={clientaddData.addButton}
                                onClick={() => sendData(data, id)}
                            />
                            <Button 
                                button={clientaddData.cancelButton}
                                onClick={() => window.location.href = "/clients"}
                            />
                        </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default AddClient;



/*


import React, { useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import DropdownMenu from '../../elements/DropDownMenu';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import AddClientDataClass from '../../data/pages/clients/AddClientData';
import { sendData, fetchClient } from '../../../triggers/clients/clients';
import DateBox from 'devextreme-react/date-box';
import '../../../styles/sections/calendar.css';

function AddClient() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [data, setData] = useState('{}');
    const [clientaddData, setClientAddData] = useState({});
    const [ warning, setWarning] = useState({});

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                setClientAddData(AddClientDataClass.AddClientDataText(id ? true : false));
                getData();
                setLoadingStatus(false);
            }
            else window.location.href = "/signin";
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        if(id) {
            const response = await fetchClient(id);
            console.log(response)
            setData(response)
        }
    }

    
    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = field;
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            setWarning(content);
        }
    }

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'validEmail' },
        ];
        const content = { ...warning }; 
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['email'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest email nu este valid.',
                '',
                false
            );
            content['email'] = warningObject.warning();
            setWarning(content);
        }
    }

    const lengthVerifier = (value, field, min, max) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'Parola';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'length', min: min, max: max},
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Parola este obligatorie, trebuie sa aibe un minim de 8 caractere.',
                '',
                false
            );
            content[field] = warningObject.warning();
            setWarning(content);
        }
    }

    const phoneNumberVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validPhoneNumber' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['phoneNumber'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest numar de telefon nu este valid, trebuie sa contina 10 cifre.',
                '',
                false
            );
            content['phoneNumber'] = warningObject.warning();
            setWarning(content);
        }
    }

    
    const onInputChange = (e, value) => {
        switch (value) {
            case 'name': {
                stringVerifier(e.target.value, 'name');
                const content = { ...user };
                content['name'] = e.target.value;
                setUser(content)
                break;
            }
            case 'email': {
                emailVerifier(e.target.value);
                const content = { ...user };
                content['email'] = e.target.value;
                setUser(content)
                break;
            }
            case 'password': {
                passwordVerifier(e.target.value);
                const content = {...user};
                content['password'] = e.target.value;
                setUser(content)
                break;
            }
            case 'phoneNumber': {
                phoneNumberVerifier(e.target.value);
                const content = {...user};
                content['phoneNumber'] = e.target.value;
                setUser(content)
                break;
            }
            case 'admin': {
                const content = {...user};
                content['admin'] = !content['admin'];
                console.log(content['admin']);
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if(typeof warning['name'] !== 'undefined' && typeof warning['email'] !== 'undefined' && typeof warning['phoneNumber'] !== 'undefined' && typeof warning['password'] !== 'undefined') {
            if(!(warning['name'] || warning['email'] || warning['phoneNumber'] || warning['password'])) {
                const content = {...user};
                if(!content['admin']) content['admin'] = false;
                await submitSignUp(content['email'], content['name'], content['phoneNumber'], content['admin'], content['password']);
                if(localStorage.getItem('ERR_MSG')) {
                    const warningObject = new WarningUser(
                        localStorage.getItem('ERR_TYP'),
                        localStorage.getItem('ERR_NM'),
                        localStorage.getItem('ERR_MSG'),
                        localStorage.getItem('ERR_CRD')
                    );
                    const data = {...warning};
                    data['fields'] = warningObject.warning();
                    setWarning(data);
                    localStorage.removeItem('ERR_TYP');
                    localStorage.removeItem('ERR_NM');
                    localStorage.removeItem('ERR_MSG');
                    localStorage.removeItem('ERR_CRD');
                } else {
                    const data = {...warning};
                    data['fields'] = false;
                    setWarning(data);
                }
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }
    }

    const changeInput = (e, value) => {
        const content = { ...data };
        if (value === 'document_validity_start' || value === 'document_validity_end') {
            content[value] = new Date(e).getFullYear() + "-" + (Number(new Date(e).getMonth()) + 1).toString().padStart(2, "0") + "-" + new Date(e).getDate() ;
        } else {
            console.log(e.target.value)
          content[value] = e.target.value;
        }
        setData(content);
    };    

    return(
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={clientaddData.classNameContainer}>
                        <div className={clientaddData.classNameContainerCard}>
                        <TextSection textSection={clientaddData.textSectionAddClient} />
                        <Input onChange={(e) => onInputChange(e, "name")} value={data.name} input={clientaddData.name}/>
                        <Input onChange={(e) => onInputChange(e, "email")} value={data.email} input={clientaddData.email}/>
                        <Input onChange={(e) => onInputChange(e, "phone_number")} value={data.phone_number} input={clientaddData.phoneNumber}/>
                        <Input onChange={(e) => onInputChange(e, "personal_code")} value={data.personal_code} input={clientaddData.cnp}/>
                        <div class="flex flex-row space-x-4">
                            <Input onChange={(e) => onInputChange(e, "series")} value={data.series} input={clientaddData.serie}/>
                            <Input onChange={(e) => onInputChange(e, "series_no")} value={data.series_no} input={clientaddData.nrserie}/>
                        </div>
                        <Input onChange={(e) => onInputChange(e, "address_document")} value={data.address_document} input={clientaddData.adresa}/>
                        <Input onChange={(e) => onInputChange(e, "citizenship")} value={data.citizenship} input={clientaddData.cetatenie}/>

                        <TextSection textSection={clientaddData.textSectionDate}/>
                        <div className='flex justify-between space-between mt-2'>
                            <DateBox
                                value={data.document_validity_start}
                                onValueChange={(e) => onInputChange(e, "document_validity_start")}
                                displayFormat="dd/MM/yyyy"
                                placeholder="ZZ/LL/AAAA"
                            />
                            <p className='font-bold mx-2'>-</p>
                            <DateBox
                                value={data.document_validity_end}
                                onValueChange={(e) => onInputChange(e, "document_validity_end")}
                                displayFormat="dd/MM/yyyy" 
                                placeholder="ZZ/LL/AAAA"
                            />
                        </div>
                        <DropdownMenu onChange={(e) => onInputChange(e, "sex")} value={data.sex} dropdown={clientaddData.sex}/>

                        <div class="flex justify-between">
                            <Button
                                button={clientaddData.addButton}
                                onClick={() => sendData(data, id)}
                            />
                            <Button 
                                button={clientaddData.cancelButton}
                                onClick={() => window.location.href = "/clients"}
                            />
                        </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default AddClient;
*/