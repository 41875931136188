class AddAppointmentData {
    static CreateAppointmetText(officeName) {
        const textSectionHeader ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "flex text-section-container",
            classNameHeader: "m-auto text-md md:text-2xl  font-bold my-5",
            header: officeName,
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };

        const textSectionHeaderCard ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Programare",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const category = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Categorie *"
                },
                        {
                    value: "Comodat",
                    label: "Comodat"
                },
                {
                    value: "Divort",
                    label: "Divort"
                },
                {
                    value: "Vanzare",
                    label: "Vanzare"
                },
                {
                    value: "Contract Prenuptial",
                    label: "Contract Prenuptial"
                },
                {
                    value: "Procura",
                    label: "Procura"
                },
                {
                    value: "Declaratii",
                    label: "Declaratii"
                },
                {
                    value: "Succesiune",
                    label: "Succesiune"
                },
                {
                    value: "Legalizare",
                    label: "Legalizare"
                },
                {
                    value: "Altele",
                    label: "Altele"
                },
        ]}

           
        const status = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Status"
                },
                        {
                    value: 101,
                    label: "In procesare"
                },
                {
                    value: 200,
                    label: "In Curs"
                },
                {
                    value: 500,
                    label: "Finalizata"
                }
        ]}
        
        const textSection2 ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Data si ora",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const textSection3 ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Ora",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        
        const day = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "DD",
            value: ""
        };
        const month = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "MM",
            value: ""
        };
        const year = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "YYYY",
            value: ""
        };
        
        
        const startHour = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "00",
            value: ""
        };
        const startMinutes = {
            classnames: "mt-4",
            classnamesInput: "text-center border border-blue-500 rounded-full p-1 w-full ",
            name: "",
            placeholder: "00",
            value: ""
        };

        const endHour = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "00",
            value: ""
        };
        const endMinutes = {
            classnames: "mt-4",
            classnamesInput: "text-center border border-blue-500 rounded-full p-1 w-full ",
            name: "",
            placeholder: "00",
            value: ""
        };
        
        const Email = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Email Client",
            value: ""
        };
        
        const name = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Nume Client *",
            value: ""
        };
        
        const phoneNumber = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Număr Telefon *",
            value: ""
        };

        const cardSectionSuccess = {
            classNameContainer: "flex justify-center items-center",
            cards: [
                {
                    classNameContainer: "min-w-[300px] sm:min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
                    textSection: {
                        tagHeader: "h1",
                        tagText: "div",
                        classNameContainer: "text-section-container",
                        classNameHeader: "text-2xl text-center font-bold mb-2 text-blue-600",
                        header: "Programarea a fost inregistrata cu succes!",
                        textArray: [
                            {
                                classNameText: "text-lg font-semibold text-blue-600",
                                text: ""
                            }
                        ]
                    }, 
                    button: {
                        classnames: "",
                        tag: "button",
                        text: ""
                    },
                    image: {
                        classnames: "flex m-auto",
                        src: "../images/appointment-icon.svg",
                        width: "100",
                        height: "100",
                        alt: ""
                    }
                }
            ]
        }

        const imageFooter = {
            classnames: "",
            src: "../images/logo.svg",
            width: "130",   
            height: "",
            alt: ""
        }
        
        const imageFooterAnpc = {
            classnames: "mt-4 ",
            src: "../images/anpc-logo.png",
            width: "200",   
            height: "40",
            alt: ""
        }
        
        const textSectionsFooter = [
            {
                tagHeader: "div",
                tagText: "div",
                classNameContainer: "mr-4",
                classNameHeader: "font-semibold text-blue-500",
                header: "Email Contact",
                textArray: [
                    {
                        text: "contact@effluence.space",
                        classNameText: "text-blue-500"
                    }
                ]
            }
        ]
        
        const iconFB = {
            classnames: "mt-2 mb-4 mr-5",
            src: "../images/facebook-logo.svg",
            width: "20",   
            height: "20",
            alt: ""
        }
        
        const iconLnk = {
            classnames: "mt-1 mb-4",
            src: "../images/linkedin-logo.svg",
            width: "28",   
            height: "28",
            alt: ""
        }
        
        
        const buttonsFooter = [
            {
                refference: "/policies",
                button: {
                    classnames: "sm:my-2 text-white bg-blue-600 px-2 py-1 ml-12 rounded",
                    tag: "button",
                    text: "Termeni și Condiții"
                }
            }
        ]
        
        const footer = {
            classNameContainer: "flex flex-col w-auto py-12 px-4 mt-6 sm:px-6 lg:px-8",
            imageLeft: imageFooter,
            imageRight: imageFooterAnpc,
            iconFB: iconFB,
            iconLnk: iconLnk,
            textSections: textSectionsFooter,
            buttons: buttonsFooter
        }

        const createButton = {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: "Creare"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "min-h-screen items-center block w-auto justify-center ",
            classNameContainerCard: "min-w-[300px] sm:min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            classNameContainerQuestion: "",
            textSectionHeader: textSectionHeader,
            textSectionHeaderCard: textSectionHeaderCard,
            header2: textSection2,
            header3: textSection3,
            name: name,
            email: Email,
            phoneNumber: phoneNumber,
            day: day,
            month: month,
            year: year,
            startHour: startHour,
            startMinutes: startMinutes,
            endHour: endHour,
            endMinutes: endMinutes,
            category: category,
            status: status,
            cardSectionSuccess: cardSectionSuccess,
            createButton: createButton,
            cancelButton: cancelButton,
            footer: footer
        };
    }
}

export default AddAppointmentData;