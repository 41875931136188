import React, { useContext, useEffect, useState } from 'react';
import CardSection from "../../sections/CardSection";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import ProfileDataClass from '../../data/pages/multiplePurpose/ProfileData';
import { fetchActiveFiles } from '../../../triggers/notary/notary';
import TableTaskSectionNow from '../../sections/TableTaskSectionNow.js';

function Profile() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [files, setFiles] = useState('{}');
    const [profile, setProfile] = useState('{}');
    const [tasksDone, setTasksDone] = useState([]);
    const [tasksNow, setTasksNow] = useState([]);

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice]);

    const getData = async () => {
        const [responseNotary, responseTasksPerUser] = await fetchActiveFiles(null);
        console.log(responseTasksPerUser)
        
        setTasksNow(responseTasksPerUser.filter(task => !task.status));

        setProfile(responseNotary);
        setFiles(responseTasksPerUser);
        setLoadingStatus(false);
    }
    return(
        <div>
            {
                !loadingStatus
                ?
                (
                    <div className={ProfileDataClass.ProfileDataText(profile, files, tasksNow).classNameContainer}>  
                        <CardSection cardSection={ProfileDataClass.ProfileDataText(profile, files, tasksNow).profileCredentials} />
                        <div className={ProfileDataClass.ProfileDataText(profile, files, tasksNow).classNameContainerCards}>
                            {/* <CardSection cardSection={ProfileDataClass.ProfileDataText(profile, files).tasks}/> */}
                            
                            <div className="flex-1 mr-4 ">
                                    <h1 className="text-center mt-10 mb-5 font-semibold bg-white p-2 rounded-md">Task-uri In Lucru</h1>
                                    {
                                        tasksNow.length > 0 ? (
                                           <TableTaskSectionNow tasksList={ProfileDataClass.ProfileDataText(profile, files,tasksNow).tasksListNow} />
                                        ) : (
                                            <p className="text-center">Nu exista task-uri curente.</p>
                                        )
                                    }
                                </div>
                                </div>
                        </div>
                    
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Profile;