// Header Data 
import header from "./sectionsRomanian/HeaderData";

// Hero With Video Data
import heroWithVideo from "./sectionsRomanian/HeroWithVideoData";

// Card Section Key Features Data
import cardSectionFeatures from "./sectionsRomanian/CardSectionFeaturesData";

// Text Presentation Data
import textPresentation from "./sectionsRomanian/TextPresentationData";


// Card Section Appointment Feature
import cardSectionFeatureAppointment from "./sectionsRomanian/CardSectionFeatureAppointmentData";

// Card Section Id Scan Feature
import cardSectionFeatureIdScan from "./sectionsRomanian/CardSectionFeatureIdScanData";

// Card Section Management Feature
import cardSectionFeatureManagement from "./sectionsRomanian/CardSectionFeatureManagementData";

// Card Section Prices
import cardSectionPrices from "./sectionsRomanian/CardSectionPrices";

// Contact Data
import contact from "./sectionsRomanian/ContactData";

// Footer Data
import footer from "./sectionsRomanian/FooterData";

// Terms Data
import terms from "./sectionsRomanian/TermsData";

// Final Data for Landing Page
const landinPageData = {
    header: header,
    heroWithVideo: heroWithVideo,
    cardSectionFeatures: cardSectionFeatures,
    textPresentation: textPresentation,
    cardSectionFeatureAppointment: cardSectionFeatureAppointment,
    cardSectionFeatureIdScan: cardSectionFeatureIdScan,
    cardSectionFeatureManagement: cardSectionFeatureManagement,
    cardSectionPrices: cardSectionPrices,
    contact: contact,
    footer: footer,
    terms: terms
}

export default landinPageData;