import postData from '../../request_methods/dataPost.js';
import getData from '../../request_methods/dataGet.js';
import putData from '../../request_methods/dataPut.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export const handleCreateNotary = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/create/post', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleGetAllNotaries = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/all-by-office-id/get', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleGetNotary = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/by-notary-id/get', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleUpdateNotary = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/by-notary-id/put', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleUpdateNotaryToOffice = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/add-notary-to-office/put', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleAceptOffer = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/by-notatry-id-acceppt-offer/put', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o erroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleDeclineOffer = async (clientData) => {
    try {
        const requestObject = new RequestClass('/notary/by-notary-id-decline-offer/put', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleGetDataForAllNotaries = async (clientData) => {
    try {
        const requestObject = new RequestClass('/file-notary/number-files-by-notary-array-id/get', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};
