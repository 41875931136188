import React from "react";
import { useHistory } from 'react-router-dom';
import '../../styles/sections/cardSection.css';
import UserIcons from "./UserIcons";
import { handleUndoDone, deleteTask } from "../../triggers/tasks/tasks";

function TableTaskSectionDone({ tasksList }) {
    const history = useHistory();

    const handleButtonClicked = (location) => {
        history.push(location);
    };

    return (
        <div className="flex justify-center">
            <div className="w-full max-w-4xl p-6">
                <div className="grid grid-cols-1 gap-6">
                    {tasksList.map((task, index) => (
                        <div key={index} className="bg-white-200 shadow-lg rounded-lg p-6">
                            <div className="flex justify-between items-center border-b pb-3 mb-4">
                            <div className="font-semibold text-lg text-gray-700">{task.text1.length > 30 ? task.text1.substring(0, 30) + "..." : task.text1}</div>
                                <div className="flex items-center">
                                    {task.text2 && (
                                        <UserIcons userIcons={{ classNamesContainer: "", usernames: [task.text2] }} />
                                    )}
                                    <span className="ml-2 text-gray-500">{task.text2 ? task.text2 : "Taskul nu are atribuit un utilizator"}</span>
                                </div>
                            </div>
                            <div className="flex justify-between items-center mb-4">
                                <div className="flex items-center">
                                    {/* <UserIcons userIcons={{ classNamesContainer: "", usernames: [task.text3] }} />
                                    <span className="ml-2 text-gray-600">{task.text3}</span> */}
                                </div>
                                <div className="text-gray-500">{task.text4}</div>
                            </div>
                            <div className="flex justify-between space-x-4">
                                <button
                                    className="bg-blue-600 hover:bg-blue-700 text-white font-semibold py-2 px-6 rounded-lg shadow transition duration-300"
                                    onClick={() => handleButtonClicked(task.button.location)}
                                >
                                    {task.button.text}
                                </button>
                                <button
                                    className="bg-orange-400 hover:bg-orange-500 text-white font-semibold py-2 px-6 rounded-lg shadow transition duration-300"
                                    onClick={() => handleUndoDone(task.doneTasks)}
                                >
                                    {task.button2.text}
                                </button>
                                <button
                                    className="bg-red-500 hover:bg-red-600 text-white font-semibold py-2 px-6 rounded-lg shadow transition duration-300"
                                    onClick={() => deleteTask(task.doneTasks)}
                                >
                                    {task.button3.text}
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default TableTaskSectionDone;
