import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';

const RedirectWithLocalStorage = ({ to, localStorageData }) => {
  useEffect(() => {
    for (const key in localStorageData) {
      localStorage.setItem(key, localStorageData[key]);
    }
    localStorage.removeItem("HTTP_CODE");
    localStorage.removeItem("ERR_NM");
    localStorage.removeItem("ERR_MSG");
  }, [localStorageData]);

  return <Redirect to={to} />;
};

export default RedirectWithLocalStorage;
