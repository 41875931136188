import secureStorage from '../../modules/secureStorage/secure.js';
import { handleRegisterOffice, handleGetOfficeCustomer } from '../../modules/office/office';

export async function submit(address, name, email, password, workingHoursStart, workingHoursEnd) {
    try {
        const startHours = workingHoursStart ? workingHoursStart.getHours() : null;
        const startMinutes =  workingHoursStart ? workingHoursStart.getMinutes() : null;
        const endHours = workingHoursEnd ? workingHoursEnd.getHours() : null;
        const endMinutes = workingHoursEnd ? workingHoursEnd.getMinutes() : null;

        const startTime = workingHoursStart ? `${String(startHours).padStart(2, '0')}:${String(startMinutes).padStart(2, '0')}` : null;
        const endTime = workingHoursEnd ? `${String(endHours).padStart(2, '0')}:${String(endMinutes).padStart(2, '0')}` : null

        const response = await handleRegisterOffice({
            office: {
                address: address,
                name: name,
                email: email,
                password: password,
                working_hours: JSON.stringify({
                    start_time: startTime,
                    end_time: endTime
                })
            },
            notary: {
                notary_id: secureStorage.secureStorage(localStorage.getItem("TKN_ATH")).getItem("UDP_UN").notary_id
            }
        });
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        secure.setItem("UDP_UN", {
            notary_id: response.notary_id,
            email_effluence: response.email_effluence,
            office_id: response.office_id,
            admin: response.admin,
            status: response.status,
            name: response.name,
            personal_code: response.personal_code,
            email_effluence: response.email_effluence,
            phone_number: response.phone_number,
            email: response.email,
            address: response.address
        })
        window.location.href = "/dashboard";
    } catch (error) {
        console.error('Error:', error);
    }
}

export async function getOffice(officeId) {
    try {
        const response = await handleGetOfficeCustomer({
            office_id: officeId
        });
        return {
            error: false,
            name: response.name
        }
    } catch (error) {
        return {
            error: true,
            HTTP_CODE: "304",
            ERR_NM: error.name,
            ERR_MSG: error.message
        }
    }
}