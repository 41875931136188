class WelcomePanelClass {
    // static WelcomePanelText(data, acceptOffer, rejectOffer) {
    //     // Logo Section

    //     const logoSection = {
    //         classnames: "flex m-auto",
    //         src: "./images/logo.svg",
    //         width: "200",
    //         height: "400",
    //         alt: ""
    //     }

    //     // Data for Create Office

    //     const textSectionCreateOfficeCard = {
    //         tagHeader: "h1",
    //         tagText: "div",
    //         classNameContainer: "text-section-container text-center",
    //         classNameHeader: "text-header text-center",
    //         header: "Inregistreaza-ti biroul in aplicatie",
    //         textArray: [
    //             {
    //                 classNameText: "text-content",
    //                 text: "Doar prin completarea unui scurt formular biroul dvs. poate sa ia viata!"
    //             }
    //         ]
    //     }

    //     const imageCreateOffice = {
    //         classnames: "flex m-auto",
    //         src: "./images/officeIcon.svg",
    //         width: "140",
    //         height: "150",
    //         alt: ""
    //     }

    //     const buttonCreateOffice = {
    //         classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 flex m-auto",
    //         tag: "button",
    //         text: "Creeaza Birou"
    //     }

    //     const createOfficeCard = {
    //         classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
    //         textSection: textSectionCreateOfficeCard,
    //         button: buttonCreateOffice,
    //         image: imageCreateOffice,
    //         onClick: () => {
    //             window.location.href = "/create-office"
    //         }
    //     }

    //     // Data for Join Office

    //     const textSectionJoinOfficeCard = {
    //         tagHeader: "h1",
    //         tagText: "div",
    //         classNameContainer: "text-section-container text-center",
    //         classNameHeader: "text-header",
    //         header: "Accepta cererea de inrolare in cadrul unui birou",
    //         textArray: [
    //             {
    //                 classNameText: "text-content text-center",
    //                 text: data !== '' ? "Aveti o cerere de la " + data : "Momentan nu aveti o cerere"
    //             }
    //         ]
    //     }

    //     const imageJoinOffice = {
    //         classnames: "flex m-auto",
    //         src: "./images/offerIcon.svg",
    //         width: "190",
    //         height: "200",
    //         alt: ""
    //     }

    //     const buttonJoinOffice = {
    //         classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 flex m-auto",
    //         tag: "button",
    //         text: "Accepta Cererea"
    //     }
        
    //     const buttonDeclineOffice = {
    //         classnames: "text-white bg-red-600 px-2 py-2 rounded mt-2 flex m-auto",
    //         tag: "button",
    //         text: "Respinge Cererea"
    //     }

    //     const joinOfficeCard = {
    //         classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
    //         textSection: textSectionJoinOfficeCard,
    //         buttonJoin: buttonJoinOffice,
    //         buttonDecline: buttonDeclineOffice,
    //         image: imageJoinOffice
    //     }

    //     const officeCard = {
    //         classNameContainer: "",
    //         cards: [ createOfficeCard ]
    //     }

    //     return {
    //         logoSection: logoSection,
    //         officeCard: officeCard,
    //         joinOffice: joinOfficeCard
    //     }
    // }
    static WelcomePanelClass() {
        return {
            textSectionCreateOfficeCard: {
                tagHeader: "h1",
                tagText: "div",
                classNameContainer: "text-section-container text-center",
                classNameHeader: "text-header text-center",
                header: "Cererea dumneavostra este in curs de aprobare",
                textArray: [
                    {
                        classNameText: "text-content",
                        text: "In caz ca apar probleme, veti fi contactat de catre noi in cel mai scurt timp posibil!"
                    }
                ]
            }

        }
    }
}

export default WelcomePanelClass;