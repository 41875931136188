const imageContact = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}

const textSectionsContact = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mt-8 w-full text-center",
        classNameHeader: "text-white text-header",
        header: "Elevate your notarial practice with Enotar",
        textArray: [
            {
                text: "",
                classNameText: ""
            }
        ]
    }
]

const buttonsContact = [
    {
        classnames: "mt-4 text-center text-blue-600 bg-white px-2 py-2 rounded mb-2",
        tag: "button",
        text: "Test the app",
        reference: "/signup"
    }
]

const contact = {
    classNameContainer: "flex flex-col items-center bg-blue-600 rounded-lg mt-6",
    image: imageContact,
    textSections: textSectionsContact,
    buttons: buttonsContact
}

export default contact;