const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container text-center",
    classNameHeader: "text-3xl font-bold mb-6",
    header: "Pricing Options",
    textArray: [
        {
            text: "",
            classNameText: "text-lg text-gray-600 mb-8"
        }
    ]
}

const textSectionCardSectionBundle = {
    tagHeader: "h2",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold mb-4",
    header: "$24.99 USD + VAT (included)",
    textArray: [
        {
            text: "- One month free",
            classNameText: "text-md text-gray-800 my-2"
        },
        {
            text: "- 100 scans",
            classNameText: "text-md text-gray-800 my-2"
        },
        {
            text: "- Management tasks",
            classNameText: "text-md text-gray-800 my-2"
        },
        {
            text: "- Full access to all Effluence services",
            classNameText: "text-md text-gray-800 my-2"
        }
    ]
}

const buttonCardSectionBundle = {
    classnames: "bg-blue-600 text-white px-6 py-3 rounded-lg hover:bg-blue-700 transition duration-300 mt-4",
    tag: "button",
    text: "Get it now"
}

const imageCardSectionBundle = {
    classnames: "flex m-auto mb-6",
    src: "./images/systemManagement.svg",
    width: "180",
    height: "180",
    alt: "System Management Icon"
}

const cardSectionFeatures = {
    classNameTextSection: "text-center mb-12",
    classNameContainer: 'flex justify-center',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-white p-8 shadow-lg rounded-lg max-w-md",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionBundle,
            button: buttonCardSectionBundle,
            image: imageCardSectionBundle
        }
    ]
}

export default cardSectionFeatures;