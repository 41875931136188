import React from 'react';
import '../../styles/sections/calendar.css';

// Elements
import { Scheduler } from 'devextreme-react';

function Calendar({ appointments, fetchAppointments }) {
  const currentDate = new Date();
  const views = ['day', 'workWeek', 'month'];

  const dateChange = (e) => {
    if (e.name === "currentView" || e.name === "currentDate") {
      console.log("Status e:", e);
      console.log("Status e.component:", e.component);
      const newDate = e.component.option("currentDate");
      console.log(newDate)
      if(e.value === "month") {
        if(localStorage.getItem("CRNT_MNTH") !== newDate.getMonth() + 1 ||
            localStorage.getItem("CRNT_YR") !== newDate.getFullYear()) {
              localStorage.setItem("CRNT_MNTH", (newDate.getMonth() + 1).toString().padStart(2, "0"));
              localStorage.setItem("CRNT_YR", newDate.getFullYear());
              fetchAppointments();
        } 
      } else if(e.value === "day") {

      } else {
        
      }
        
    }
  }

  return (
    <div className='my-12'>
      
      <h1 className='sectionDescription'></h1>
      <Scheduler
        dataSource={appointments}
        views={views}
        defaultCurrentView="month"
        defaultCurrentDate={currentDate}
        startDayHour={7}
        editing={false}
        showAllDayPanel={true}
        onOptionChanged={dateChange}
        resources={[
          {
            fieldExpr: 'status',
            allowMultiple: false,
            dataSource: [
              { id: "101", text: 'In asteptare' },
              { id: "200", text: 'In decurs de procesare' },
              { id: "500", text: 'Finalizata' },
            ],
            label: 'Status'
          }
        ]}
      />
    </div>
  );
}

export default Calendar;
