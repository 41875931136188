import React, { useContext, useEffect, useState }from 'react';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import CardSection from '../../sections/CardSection';
import ErrorClass from '../../data/pages/errors/ErrorData';
import { MoonLoader } from 'react-spinners';

function Error() {
    const {loading, isAuthenticated} = useContext(AuthContext);

    const [data, setData] = useState('{}');

    useEffect(() => {
        if(!loading) {
            setData(ErrorClass.ErrorDataText(
                localStorage.getItem("ERR_NM"),
                localStorage.getItem("HTTP_CODE"),
                localStorage.getItem("ERR_MSG"), 
                isAuthenticated
            ));
            localStorage.removeItem("ERR_NM");
            localStorage.removeItem("HTTP_CODE");
            localStorage.removeItem("ERR_MSG");
        }
    }, [loading, isAuthenticated])
    return(
        <>
            {
                data !== '{}'
                ?
                <CardSection cardSection={data} />
                :
                <MoonLoader
                
                />
            }
        </>
    );
}

export default Error;