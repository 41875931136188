import React, { useContext, useEffect, useState, useRef } from 'react';
import TextSection from '../../elements/TextSection';
import Button from '../../elements/Button';
import TableSection from '../../sections/TableSection';
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import NotariesDataClass from '../../data/pages/notary/NotariesData';
import { fetchNotaries, acceptNotary, declineNotary } from '../../../triggers/notary/notary';
import Notification from '../../sections/Notification';

function Employee() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const notificationRef = useRef(null);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [link, setLink] = useState('');
    const [data, setData] = useState('{}');
    const [admin, setAdmin] = useState(false);
    const [notificationMessage, setNotificationMessage] = useState('');

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice])

    const getData = async () => {
        const [responseAllNotaries, responseDataNotaries, admin, link] = await fetchNotaries();
        setLink(link);
        setData(NotariesDataClass.NotariesDataText(responseAllNotaries, handleAccept, handleDecline, responseDataNotaries, admin));
        setAdmin(admin);
        setLoadingStatus(false);
    }
    
    const copyButton = () => {
        navigator.clipboard.writeText(link);
        setNotificationMessage("Linkul pentru inregistrarea angajatului a fost copiat!");
        notificationRef.current.show();
    }

    const handleAccept = async (notaryId) => {
        const success = await acceptNotary(notaryId);
        if (success) {
            await getData();
            setNotificationMessage("Notarul a fost acceptat cu succes!");
            notificationRef.current.show();
        }
    }

    const handleDecline = async (notaryId) => {
        const success = await declineNotary(notaryId);
        if (success) {
            await getData();
            setNotificationMessage("Notarul a fost respins și șters din sistem.");
            notificationRef.current.show();
        }
    }

    return (
        <div>
            {
                !loadingStatus
                ?
                (
                    <>
                        <Notification
                            ref={notificationRef}
                            message={notificationMessage}
                        />
                        <div className="flex items-center justify-between">
                            <TextSection textSection={data.headerSection} />
                            <div className="flex">
                                <Button onClick={() => copyButton()} button={data.addEmployeeLink} />
                            </div>
                        </div>
                        <TableSection tableSection={data.employeeList}/>
                    </>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Employee;