const textSectionTextPresentation = {
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Flexibilitate în integrare",
    textArray: [
        {
            text: "Fie că dețineți deja un website sau nu, serviciile noastre sunt flexibile și se adaptează cu ușurință la cerințele dumneavoastră. Pentru cei care dețin deja un website, oferim opțiunea de a integra aplicația Enotar, în timp ce, pentru cei care nu au un website, putem crea unul special pentru dumneavoastră.",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const buttonTextPresentation = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageTextPresentation = {
    classnames: "objectcontain",
    src: "./images/textPresentation.svg",
    width: "700",
    height: "",
    alt: ""
}

const textPresentation = {
    classNameContainer: "flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 mt-6",
    textSection: textSectionTextPresentation,
    button: buttonTextPresentation,
    image: imageTextPresentation
}

export default textPresentation;