import React, { useState } from 'react';
import TextSection from '../../elements/TextSection';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import data from '../../data/pages/authentification/SignInData';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { submitSignIn } from '../../../triggers/authentification/authentification';

function SignIn() {
    const [warningStatus, setWarningStatus] = useState(false);
    const [credentials, setCredentials] = useState('');
    const [password, setPassword] = useState('');
 
    const handleSubmit = async () => {
        await submitSignIn(credentials, password);
        if(localStorage.getItem('ERR_MSG')) {
            setWarningStatus(true)
            const warningObject = new WarningUser(
                localStorage.getItem('ERR_TYP'),
                localStorage.getItem('ERR_NM'),
                localStorage.getItem('ERR_MSG'),
                localStorage.getItem('ERR_CRD')
            );
            localStorage.setItem('ERR_STR', JSON.stringify(warningObject.warning()));
            localStorage.removeItem('ERR_TYP');
            localStorage.removeItem('ERR_NM');
            localStorage.removeItem('ERR_MSG');
            localStorage.removeItem('ERR_CRD');
        } else setWarningStatus(false);
    }

    return (
        <div className={data.classNameContainerPage}>
            <div className={data.classNameContainerCard}>
                <TextSection textSection={data.textSection} />
                <Input onChange={e => setCredentials(e.target.value)} value={credentials} input={data.effluenceEmail} />
                <Input onChange={e => setPassword(e.target.value)} value={password} input={data.password} />
                {warningStatus ? <TextSection textSection={JSON.parse(localStorage.getItem('ERR_STR'))} /> : null }
                <div className={data.classNameContainerQuestion}>
                    <TextSection textSection={data.textQuestion} />
                    <a href={data.redirectButton.reference}>
                        <Button button={data.redirectButton.button} />
                    </a>
                </div>
                <Button onClick={handleSubmit} button={data.submitButton} />
            </div>
        </div>
    );
}

export default SignIn;
