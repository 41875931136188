class BaseError extends Error {
    constructor(name, httpCode, description, isOperational) {
      super(description);
      Object.setPrototypeOf(this, new.target.prototype);
  
      this.name = name;
      this.httpCode = httpCode;
      this.isOperational = isOperational;
    }
}
  
class APIError extends BaseError {
    constructor(name, httpCode = 500, isOperational = true, description = 'internal server error') {
        super(name, httpCode, description, isOperational);
    }
}

export default APIError;
  