class FileDataClass {
    static FileDataText(fileInfo, deleteFile){

        const textSectionCredentials = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: fileInfo.title,
            textArray: []
        }

        const buttonCredentials = {
            classnames: "text-white bg-red-600 px-2 py-2 rounded mt-2 ml-4 p-8",
            tag: "button",
            text: "Stergere"
        }

        const imageCredentials = {
            classnames: "",
            src: "",
            width: "",
            height: "",
            alt: ""
        }

        const fileCredentials = {
            classNameContainer: "mb-8",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md flex justify-between rounded-lg mt-6 flex",
                    textSection: textSectionCredentials,
                    button: buttonCredentials,
                    image: imageCredentials,
                    onClick: () => deleteFile(fileInfo.file_id)
                }
            ]
        }

        const textSectionNotaries = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "mr-4",
            classNameHeader: "text-header text-blue-500",
            header: "Lista Notari:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }


        const buttonaddNotary = {
            classnames: "border border-blue-500 text-blue-500 bg-white px-2 py-2 mx-2 rounded",
            tag: "button",
            text: "Adauga Notar"
        }

          
        const buttonaddClient = {
            classnames: "border border-blue-500 text-blue-500 bg-white px-2 py-2 mx-2 rounded",
            tag: "button",
            text: "Adauga Client"
        }

        const textSectionClients = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "mr-4",
            classNameHeader: "text-header text-blue-500",
            header: "Lista Clienti:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }
       
        const buttonDiscussion = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mr-2",
            tag: "button",
            text: "Discutie"
        }

        const buttonDiscussionActivated = {
            classnames: "text-blue-600 bg-white border border-blue-600 hover:text-white hover:bg-blue-600 transition px-2 py-2 rounded mt-2 mr-2",
            tag: "button",
            text: "Discutie"
        }

        const buttonFile = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 ml-2",
            tag: "button",
            text: "Document / Smart Scan"
        }

        const buttonFileActivated = {
            classnames: "text-blue-600 bg-white border border-blue-600 hover:text-white hover:bg-blue-600 transition px-2 py-2 rounded mt-2 ml-2",
            tag: "button",
            text: "Document / Smart Scan"
        }

        const documentEditor = {
            headerSection: {
                tagHeader: "h1",
                tagText: "div",
                classNameContainer: "mt-4",
                classNameHeader: "text-3xl font-semibold mb-4",
                header: "Document / Smart Scan",
                textArray: []
            },
            buttonScan: {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded",
                tag: "button",
                text: "Scaneaza"
            },
            buttonScanId: {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded",
                tag: "button",
                text: "Scaneaza CI"
            },
            buttonSave: {
                classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-4",
                tag: "button",
                text: "Salveaza"
            },
            inputScan: {
                classnames: "hidden",
                type: "file",
                name: "scan",
                text: ""
            },
            classNameInput: 'text-white bg-blue-600 px-2 py-2 rounded',
            textInput: 'Scaneaza',
            document: fileInfo.document !== null ? fileInfo.document : '',
            file_id: fileInfo.file_id
        }

        const noteInput = {
            classnames: "w-full",
            classnamesInput: "border border-blue-500 rounded px-2 py-2 w-full",
            type: "text",
            name: "",
            placeholder: "Scrie textul aici...",
            value: ""
        }

        const buttonaddNote = {
            classnames: "text-white bg-blue-600 p-auto rounded",
            tag: "button",
            text: "Adauga comentariu"
        }


        return {
            classNameContainer: "",
            fileCredentials: fileCredentials,
            textSectionNotaries : textSectionNotaries,
            textSectionClients: textSectionClients,
            buttonaddNotary: buttonaddNotary,
            buttonaddClient: buttonaddClient,
            buttonFile: buttonFile,
            buttonFileActivated: buttonFileActivated,
            buttonDiscussion: buttonDiscussion,
            buttonDiscussionActivated: buttonDiscussionActivated,
            noteInput: noteInput,
            buttonaddNote: buttonaddNote,
            documentEditor: documentEditor,
            classNameContainerCards: ""
        }

    }
}

export default FileDataClass;