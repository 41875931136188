import secureStorage from '../../modules/secureStorage/secure.js';
import { handleGetAllClients, handleGetClient, handleCreateClient, handleUpdateClient, handleScanId } from '../../modules/clients/clients.js';
import { handleGetAllFilesWithClient } from '../../modules/files/files.js';



export async function fetchClients() {
    try {
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetAllClients({
            office_id: secure.getItem("UDP_UN").office_id
        })
        return response;
    } catch(error) {

    }
}

export async function fetchData(id) {
    try {
        const responseClient = await handleGetClient({
            client_id: id
        });
        const responseFiles = await handleGetAllFilesWithClient({
            client_id: id
        });
        return [responseClient, responseFiles];
    } catch(error) {
        console.log(error);
    }
};

export async function fetchClient(id) {
    if(id) {
        try {
            const response = await handleGetClient({
                client_id: id
            });
            return response;
        } catch(error) {
            console.error(error);
        }
    }
}

export async function sendData(data, id) {
    try {
        if(id){
            await handleUpdateClient(data);
        } else {
            const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
            const content = { ...data };
            content["office_id"] = secure.getItem("UDP_UN").office_id; 
            await handleCreateClient(content);
        }
    } catch(error) {
    }
    window.location.href = "/clients";
}

export async function getScanId(data) {
    try {
        return await handleScanId(data);
    } catch(error){
    }
}
