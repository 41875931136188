import getData from '../../request_methods/dataGet.js';
import postData from '../../request_methods/dataPost.js';
import putData from '../../request_methods/dataPut.js';
import putForm from '../../request_methods/dataPutFrom.js';
import deleteData from '../../request_methods/dataDelete.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';
//////////////////
// File modules //
//////////////////

export const handleCreateFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/create/post', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllFiles = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/all-files-by-office-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id/put', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateFileDocument = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id-only-document/put', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id/delete', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleScanDocument = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id-only-document-and-scan/put', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) {
            window.location.reload()
            return response.data;
        }
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        console.log(error)
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleScanId = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file/by-file-id-only-document-ci-and-scan/put', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) {
            window.location.reload()
            return response.data;
        }
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        console.log(error)
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

/////////////////////////
// File Notary modules //
/////////////////////////

export const handleAddNotaryToFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/create/post', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleNumberOfFilesByNotary = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/number-file-by-notary-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleNumberOfActiveFilesByNotary = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/number-file-active-by-notary-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleActiveNotariesOnFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/active-notary-by-file-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleNotariesOnFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/all-notary-by-file-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllFilesByNotary = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/all-files-by-notary-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateNotaryStatusOnFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/by-file-id-and-notary-id/delete', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteNotaryFromFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notary/by-file-id-and-notary-id/delete', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

/////////////////////////
// File Client modules //
/////////////////////////

export const handleAddClientToFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-client/create/post', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllClientsOnFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-client/by-file-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllFilesWithClient = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-client/all-files-with-client-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteClientFromFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-client/by-file-id-and-client-id/delete', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

////////////////////////
// File Notes modules //
////////////////////////

export const handleAddNoteToFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notes/create/post', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllNotesOnFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notes/all-notes-by-file-id/get', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateNote = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notes/by-file-note-id/put', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteNoteFromFile = async (fileData) => {
    try {
        const requestObject = new RequestClass('/file-notes/by-file-note-id/delete', fileData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}