import React, { useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import CardSection from '../../sections/CardSection';
import DropdownMenu from '../../elements/DropDownMenu';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import AddTaskDataClass from '../../data/pages/tasks/AddTaskData';
import { updateTask, fetchTask } from '../../../triggers/tasks/tasks';
import DateBox from 'devextreme-react/date-box';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import '../../../styles/sections/calendar.css';
import { fetchNotaries } from '../../../triggers/notary/notary.js';


function AddTask() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [warning, setWarning] = useState({});
    const [data, setData] = useState('{}');
    const [taskAddData, setTaskAddData] = useState('{}');
    const [notaryDataAdd, setNotaryDataAdd] = useState();
    const [ edited, setEdited] = useState(false);

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                console.log("heres")
                getData();
            }
            else window.location.href = "/signin";
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        if(id) {
            const response = await fetchTask(id);
            const notaries = await fetchNotaries();
            setTaskAddData(AddTaskDataClass.AddTaskDataText(id ? true : false, notaries[0]));
            setData(response);
            setNotaryDataAdd(response.asignee_id)
            setLoadingStatus(false);
            console.log(notaries[0]);
        } else {
            const notaries = await fetchNotaries();
            setTaskAddData(AddTaskDataClass.AddTaskDataText(id ? true : false, notaries[0]));
            setLoadingStatus(false);
            console.log(notaries[0]);

        }
    }

    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            console.log(content)
            setWarning(content);
        }
    }

    const changeInput = (e, value) => {
        switch (value) {
            case 'title': {
                stringVerifier(e.target.value, 'title');
                const content = { ...data };
                content['title'] = e.target.value;
                setData(content);
                setEdited(true);
                break;
            }
            case 'description': {
                const content = { ...data };
                content['description'] = e.target.value;
                setData(content);
                setEdited(true);
                break;
            }
            case 'date_time_deadline': {
                const selectedDate = e.value;
                const trueDate = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
                const dateChoice = trueDate.getFullYear() + "-" + (Number(trueDate.getMonth()) + 1).toString().padStart(2, "0") + "-" + (trueDate.getDate()).toString().padStart(2, "0") + " " + trueDate.getHours().toString().padStart(2, "0") + ":" + trueDate.getMinutes().toString().padStart(2, "0")+ ":00";
                stringVerifier(dateChoice, 'date_time_deadline');
                const content = { ...data };
                content['date_time_deadline'] = dateChoice;
                setData(content);
                setEdited(true);
                break;
            }
            default:
                break;
        }
    }
 

    const handleSubmit = () => {
        if((typeof warning['title'] !== 'undefined' && typeof warning['date_time_deadline'] !== 'undefined')||
            (data.title && data.date_time_deadline)){
            if(!(warning['title'] || warning['date_time_deadline'])) {
                data.asignee_id = notaryDataAdd
                updateTask(data,id);
            } else {
                const content = {...warning};
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Titlul si data trebuiesc completate!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content); 
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Titlul si data trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }

    }

    return(
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={taskAddData.classNameContainer}>
                        <div className={taskAddData.classNameContainerCard}>
                        <TextSection textSection={taskAddData.textSectionAddTask} />
                        <Input onChange={(e) => changeInput(e, "title")} value={data.title} input={taskAddData.title}/>
                        {warning['title'] ? <TextSection textSection={warning['title']} /> : null}
                        <Input onChange={(e) => changeInput(e, "description")} value={data.description} input={taskAddData.description}/>

                        <TextSection textSection={taskAddData.textSectionDate}/>
                        <div className='flex justify-between space-between mt-2'>
                             <DateBox
                            type="datetime"
                            value={data.date_time_deadline}
                            onValueChanged={(e) => changeInput(e, "date_time_deadline")}
                            displayFormat="dd-MM-yyyy HH:mm"
                            format="dd-MM-yyyy HH:mm"
                            placeholder="ZZ/LL/AAAA HH:MM"
                            />
                             {warning['date_time_deadline'] ? <TextSection textSection={warning['date_time_deadline']} /> : null }
                        </div>
                    
                        <TextSection textSection={taskAddData.textSectionNotary}/>
                        <div className='bg-white p-2 my-2 rounded flex items-center'>
                            <div className='flex items-center min-w-max'>
                                <DropdownMenu onChange={(e) => setNotaryDataAdd(e.target.value)} value={notaryDataAdd} dropdown={taskAddData.dropdownaddNotary} />
                                {/* <Button onClick={() => addNotary(id, notaryDataAdd )} button={taskAddData.buttonaddNotary} /> */}
                            </div>
                        </div>
                    {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null }
                        <div className="flex justify-between">
                            <Button
                                button={taskAddData.addButton}
                                onClick={() => handleSubmit()}
                            />
                            <Button 
                                button={taskAddData.cancelButton}
                                onClick={() => window.location.href = "/tasks"}
                            />
                        </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default AddTask;