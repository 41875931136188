class NotaryDataClass {
    static NotaryDataText(userInfo, tasksDone, tasksNow){

        const textSectionCredentials = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: userInfo.name,
            textArray: [
                {
                    classNameText: "text-content",
                    text: "Numar de Telefon: " + userInfo.phone_number
                },
                {
                    classNameText: "text-content",
                    text: "Email Effluence: " + userInfo.email_effluence
                },
                {
                    classNameText: "text-content",
                    text: userInfo.email !== null ? "Email Personal: " + userInfo.email : "Emailul Personal: Nu este disponibil."
                }
            ]
        }

        const buttonCredentials = {
            classnames: "",
            tag: "button",
            text: ""
        }

        const imageCredentials = {
            classnames: "",
            src: "../images/profile-icon.svg",
            width: "200",
            height: "200",
            alt: ""
        }

        const profileCredentials = {
            classNameContainer: "",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: textSectionCredentials,
                    button: buttonCredentials,
                    image: imageCredentials
                }
            ]
        }

        const tasksListDone = 
            tasksDone.map((item) => ({
                doneTasks: item,
                text1: item.title,
                text2: item.name_asignee,
                text3: item.name_creator,
                text4: item.date_time_deadline,
                button: {
                    text: "Detalii",
                    location: '/task/' + item.task_id,
                },
                button2: {
                    text: "Revenire"
                },
                button3: {
                    text: "Stergere"
                }
            }));

        const tasksListNow = 
            tasksNow.map((item) => ({
                nowTasks: item,
                text1: item.title,
                text2: item.name_asignee,
                text3: item.name_creator,
                text4: item.date_time_deadline,
                button: {
                    text: "Detalii",
                    location: '/task/' + item.task_id,
                },
                button2: {
                    text: "Finalizare"
                },
                button3: {
                    text: "Stergere"
                }
            }));

        return {
            classNameContainer: "",
            profileCredentials: profileCredentials,
            classNameContainerCards: "",
            tasksListDone: tasksListDone,
            tasksListNow: tasksListNow
        }

    }
}

export default NotaryDataClass;