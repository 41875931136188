class FieldResult {
    constructor(message, value) {
        this.message = message;
        this.value = value;
    }
}

class FieldVerifier {
    constructor() {
        this.errors = [];
    }

    checkNotEmpty(field, fieldName) {
        if (!field) {
            this.errors.push(new FieldResult(`${fieldName} nu poate sa fie gol.`, false));
        } else {
            this.errors.push(new FieldResult('', true));
        }
    }

    checkValidEmail(field, fieldName) {
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(field)) {
            this.errors.push(new FieldResult(`Va rugam sa introduceti un email valid pentru ${fieldName}.`, false));
        } else {
            this.errors.push(new FieldResult('', true));
        }
    }

    checkFieldLength(field, fieldName, minLength, maxLength) {
        if (field.length < minLength || field.length > maxLength) {
            this.errors.push(new FieldResult(`${fieldName} trebuie sa contina un minim de ${minLength} caractere.`, false));
        } else {
            this.errors.push(new FieldResult('', true));
        }
    }

    checkValidPhoneNumber(field, fieldName) {
        const phonePattern = /^\d{10}$/;
        if (!phonePattern.test(field)) {
            this.errors.push(new FieldResult(`Va rugam sa introduceti un numar de telefon valid pentru ${fieldName}.`, false));
        } else {
            this.errors.push(new FieldResult('', true));
        }
    }

    verifyField(field, fieldName, criteria) {
        this.errors = [];
        criteria.forEach(criterion => {
            switch (criterion.name) {
                case 'notEmpty':
                    this.checkNotEmpty(field, fieldName);
                    break;
                case 'validEmail':
                    this.checkValidEmail(field, fieldName);
                    break;
                case 'length':
                    this.checkFieldLength(field, fieldName, criterion.min, criterion.max);
                    break;
                case 'validPhoneNumber':
                    this.checkValidPhoneNumber(field, fieldName);
                    break;
            }
        });
        return this.errors.every(result => result.value);
    }

    getErrors() {
        return this.errors.map(result => result.message).filter(message => message !== '');
    }
}
export default FieldVerifier;