import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import DropdownMenu from '../../elements/DropDownMenu';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import { MoonLoader } from 'react-spinners';
import AddFileDataClass from '../../data/pages/files/AddFileData';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { sendData, fetchFile } from '../../../triggers/files/files.js';
function AddFile() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [fileAddData, setFileAddData] = useState({});
    const [warning, setWarning] = useState({});
    const [data, setData] = useState({});

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                setFileAddData(AddFileDataClass.AddFileDataText(true));
                getData();
            }
            else {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice]);

    const getData = async () => {
        if(id) {
            const dataFile = await fetchFile(id);
            setData(dataFile);
            setWarning({'title': false, 'document_code':false, 'category': false, 'status': false})
        }
        setLoadingStatus(false);
    }

    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            console.log(content)
            setWarning(content);
        }
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'title': {
                stringVerifier(e.target.value, 'title');
                const content = { ...data };
                content['title'] = e.target.value;
                setData(content);
                break;
            }
            case 'document_code': {
                stringVerifier(e.target.value, 'document_code');
                const content = { ...data };
                content['document_code'] = e.target.value;
                setData(content);
                break;
            }
            case 'category': {
                stringVerifier(e.target.value, 'category');
                const content = { ...data };
                content['category'] = e.target.value;
                setData(content);
                break;
            }
            case 'status': {
                stringVerifier(e.target.value, 'status');
                const content = { ...data };
                content['status'] = e.target.value;
                setData(content);
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = () => {
        if(typeof warning['title'] !== 'undefined' && typeof warning['document_code'] !== 'undefined' && typeof warning['category'] !== 'undefined' && typeof warning['status'] !== 'undefined'){
            if(!(warning['address'] || warning['name'] || warning['email'] || warning['password'])) {
                sendData(id, data)
            } else {
                const content = {...warning};
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Toate campurile trebuiesc completate!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content); 
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }

    }


    return (
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={fileAddData.classNameContainer}>
                        <div className={fileAddData.classNameContainerCard}>
                        <TextSection textSection={fileAddData.textSectionHeader} />
                        <Input onChange={(e) => onInputChange(e, "title")}  value={data.title} input={fileAddData.title}/>
                        {warning['title'] ? <TextSection textSection={warning['title']} /> : null }
                        <Input  onChange={(e) => onInputChange(e, "document_code")}  value={data.document_code} input={fileAddData.documentCode}/>
                        {warning['document_code'] ? <TextSection textSection={warning['document_code']} /> : null }
                        <DropdownMenu onChange={(e) => onInputChange(e, "category")} value={data.category} dropdown={fileAddData.category}/>
                        {warning['category'] ? <TextSection textSection={warning['category']} /> : null }
                        <DropdownMenu onChange={(e) => onInputChange(e, "status")} value={data.status} dropdown={fileAddData.status}/>
                        {warning['status'] ? <TextSection textSection={warning['status']} /> : null }
                        {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null }
                        <div class="flex justify-between ">
                            <Button onClick={() => handleSubmit()} button={fileAddData.createButton}/>
                            <Button onClick={() => window.location.href = "/folder"}button={fileAddData.cancelButton}/>
                        </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default AddFile;
