import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import DropdownMenu from '../../elements/DropDownMenu';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import ClientDataClass from '../../data/pages/appointment/CreateAppointmentData';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import {fetchAppointmentData, deleteAppointment, submit} from '../../../triggers/appointment/appointments';
import DateBox from 'devextreme-react/date-box';

function CreateAppointment() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const [appointment, setAppointment] = useState({});
    const [appointmentData, setAppointmentData] = useState({});
    const [warning, setWarning] = useState({});
    const [loadingStatus, setLoadingStatus] = useState(true);

    const { id } = useParams();

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                setAppointment(ClientDataClass.CreateAppointmetText(id))
                if(id) updateDataFields();
                else setLoadingStatus(false);
            }
            else {
                localStorage.clear();
                window.location.href = '/';
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice])

    const updateDataFields = async () => {
        setAppointmentData(await fetchAppointmentData(id));
        setLoadingStatus(false);
    }

    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            console.log(content)
            setWarning(content);
        }
    }

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validEmail' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['email'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest email nu este valid.',
                '',
                false
            );
            content['email'] = warningObject.warning();
            setWarning(content);
        }
    }
    
    const phoneNumberVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validPhoneNumber' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['phoneNumber'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest numar de telefon nu este valid, trebuie sa contina 10 cifre.',
                '',
                false
            );
            content['phoneNumber'] = warningObject.warning();
            setWarning(content);
        }
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'name': {
                stringVerifier(e.target.value, 'name');
                const content = { ...appointmentData };
                content['name'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'email': {
                emailVerifier(e.target.value);
                const content = { ...appointmentData };
                content['email'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'phoneNumber': {
                phoneNumberVerifier(e.target.value);
                const content = { ...appointmentData };
                content['phoneNumber'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'date': {
                const selectedDate = e.value;
                const trueDate = selectedDate instanceof Date ? selectedDate : new Date(selectedDate);
                const dateChoice = trueDate.getFullYear() + "-" + (Number(trueDate.getMonth()) + 1).toString().padStart(2, "0") + "-" + (trueDate.getDate()).toString().padStart(2, "0") + " " + trueDate.getHours().toString().padStart(2, "0") + ":" + trueDate.getMinutes().toString().padStart(2, "0")+ ":00";
                stringVerifier(dateChoice, 'date');
                const content = { ...appointmentData };
                content['start_timedate'] = dateChoice;
                setAppointmentData(content);
                break;
            }
            case 'category': {
                stringVerifier(e.target.value, 'category');
                const content = { ...appointmentData };
                content['category'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            case 'status': {
                stringVerifier(e.target.value, 'status');
                const content = { ...appointmentData };
                content['status'] = e.target.value;
                setAppointmentData(content);
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = () => {
        if((typeof warning['name'] !== 'undefined' && typeof warning['phoneNumber'] !== 'undefined')||
            (appointmentData.name && appointmentData.phoneNumber)){
            if(!(warning['name'] || warning['phone'])) {
                submit(id, appointmentData);
            } else {
                const content = {...warning};
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Toate campurile cu exceptia indicatiilor calendaristice trebuiesc completate!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content); 
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile cu exceptia indicatiilor calendaristice trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }

    }
    return (
    <div className={appointment.classNameContainer}>
        <div className={appointment.classNameContainerCard}>
        {
            !loadingStatus
            ?
            (
                <>
                    <TextSection textSection={appointment.header} />
                    <Input onChange={(e) => onInputChange(e, "name")} value={appointmentData.name} input={appointment.name}/>
                    {warning['name'] ? <TextSection textSection={warning['name']} /> : null }
                    <Input onChange={(e) => onInputChange(e, "email")} value={appointmentData.email} input={appointment.email}/>
                    {warning['email'] ? <TextSection textSection={warning['email']} /> : null }
                    <Input onChange={(e) => onInputChange(e, "phoneNumber")} value={appointmentData.phoneNumber} input={appointment.phoneNumber}/>
                    {warning['phoneNumber'] ? <TextSection textSection={warning['phoneNumber']} /> : null }
                    <div>
                        {/* Date */}
                        <TextSection textSection={appointment.header2} />
                        <div className="flex flex-row space-x-4 mt-2">
                            <DateBox
                            type="datetime"
                            value={appointmentData.start_timedate}
                            onValueChanged={(e) => onInputChange(e, "date")}
                            displayFormat="dd-MM-yyyy HH:mm"
                            format="dd-MM-yyyy HH:mm"
                            placeholder="ZZ/LL/AAAA HH:MM"
                            />
                        </div>
                        {warning['date'] ? <TextSection textSection={warning['date']} /> : null }
                    </div>
                    <DropdownMenu onChange={(e) => onInputChange(e, "category")} value={appointmentData.category} dropdown={appointment.category}/>
                    {warning['category'] ? <TextSection textSection={warning['category']} /> : null }
                    {
                        id?
                        <div>
                            <DropdownMenu onChange={(e) => onInputChange(e, "status")} value={appointmentData.status} dropdown={appointment.status}/>
                        </div>
                        :
                        <></>
                    }
                    {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null }
                    <div class="flex justify-between ">
                        <Button onClick={() => handleSubmit()} button={appointment.createButton}/>
                        {id ? <Button onClick={() => deleteAppointment(id)} button={appointment.deleteButton}/> : null}
                        <Button onClick={() => window.location.href = "/appointments"} button={appointment.cancelButton}/>
                    </div>
                </>
            )
            :
            (
                <MoonLoader
                    className='flex m-auto mt-32'
                    color="#2563eb"
                    size={140}
                />
            )
        }
        </div>
    </div>
);
}

export default CreateAppointment;
