class MenuClass {
    static MenuText(isAdmin, notificationsTasks) {
        const logo = {
            classnames: "ml-9",
            src: "../images/logo.svg",
            width: "130",
            height: "",
            alt: ""
        }
        
        const buttonsMenu = [
            {
                classNameContainer: "",
                reference: "/dashboard",
                icon: {
                    classnames: "blue-500",
                    src: "../images/dashboard-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Dashboard"
                }
            },
            {
                classNameContainer: "",
                reference: "/appointments",
                icon: {
                    classnames: "blue-500",
                    src: "../images/appointment-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Programari"
                }
            },
            {
                classNameContainer: "",
                reference: "/tasks",
                icon: {
                    classnames: "blue-500",
                    src: notificationsTasks.length > 0 ? "../images/icon-tasks-notification.svg" : "../images/icon-tasks.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Task-uri"
                }
            },
           
            {
                classNameContainer: "",
                reference: "/folder",
                icon: {
                    classnames: "blue-500",
                    src: "../images/folder-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: " Scan Document"
                }
            },
            {
                classNameContainer: "",
                reference: "/clients",
                icon: {
                    classnames: "blue-500",
                    src: "../images/clients-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Clienti"
                }
            },
            {
                classNameContainer: "",
                reference: "/notary",
                icon: {
                    classnames: "blue-500",
                    src: "../images/employee-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Angajati"
                }
            },
            // isAdmin ? {
            //     classNameContainer: "",
            //     reference: "/office",
            //     icon: {
            //         classnames: "blue-500",
            //         src: "../images/office-icon.svg",
            //         width: "",
            //         height: "",
            //         alt: ""
            //     },
            //     button: {
            //         classnames: "text-blue-500 rounded",
            //         tag: "button",
            //         text: "Office"
            //     }
            // } : null,
            {
                classNameContainer: "",
                reference: "/profile",
                icon: {
                    classnames: "blue-500",
                    src: "../images/profile-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Profil"
                }
            },
            {
                classNameContainer: "",
                reference: "/help",
                icon: {
                    classnames: "blue-500",
                    src: "../images/help-icon.svg",
                    width: "",
                    height: "",
                    alt: ""
                },
                button: {
                    classnames: "text-blue-500 rounded",
                    tag: "button",
                    text: "Ajutor"
                }
            }
        ].filter(Boolean)
        
        return {
            logo: logo,
            buttons: buttonsMenu,
            classNameContainer: "shadow-md "
        }
    }
}

export default MenuClass;