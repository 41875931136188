
const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Funcțiile Aplicației",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Programări organizate",
    textArray: [
        {
            text: "Simplifică gestionarea zilnică a serviciilor notariale, asigurând productivitate optimă și satisfacția clienților",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionIdScan = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Scanare carte de identitate",
    textArray: [
        {
            text: "Aceasta reprezintă modalitatea prin care puteți integra în mod direct informațiile din cartea de identitate în documentul pe care îl redactați",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionManagement = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Managementul contractelor",
    textArray: [
        {
            text: "Acesta reprezintă modalitatea de a gestiona procesul de redactare al contractului, atât din birou, cât și de acasă, cu ușurință și eficiență",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const buttonCardSectionAppointment = {
    reference: "#appointment",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Mai multe detalii"
}

const buttonCardSectionIdScan = {
    reference: "#idscan",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Mai multe detalii"
}

const buttonCardSectionManagement = {
    reference: "#management",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Mai multe detalii"
}

const imageCardSectionAppointment = {
    classnames: "flex justify-center items-center",
    src: "./images/appointment.svg",
    width: "300",
    height: "300",
    alt: ""
}

const imageCardSectionIdScan = {
    classnames: "flex justify-center items-center",
    src: "./images/idScan.svg",
    width: "300",
    height: "300",
    alt: ""
}

const imageCardSectionManagement = {
    classnames: "flex justify-center items-center",
    src: "./images/management.svg",
    width: "300",
    height: "300",
    alt: ""
}

const cardSectionFeatures = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionAppointment,
            button: buttonCardSectionAppointment,
            image: imageCardSectionAppointment,
            onClick: () => window.location.href = "#appointment"
        },
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIdScan,
            button: buttonCardSectionIdScan,
            image: imageCardSectionIdScan,
            onClick: () => window.location.href = "#idscan"
        },
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionManagement,
            button: buttonCardSectionManagement,
            image: imageCardSectionManagement,
            onClick: () => window.location.href = "#management"
        }
    ]
}

export default cardSectionFeatures;