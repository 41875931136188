
const textSectionCardSection = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Application Features",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Organized Appointments",
    textArray: [
        {
            text: "Simplify the daily management of notary services, ensuring optimal productivity and customer satisfaction",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionIdScan = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "ID Card Scanning",
    textArray: [
        {
            text: "This is the method through which you can directly integrate information from the ID card into the document you are drafting",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionManagement = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Contract Management",
    textArray: [
        {
            text: "This is the way to efficiently manage the contract drafting process, both from the office and remotely, with ease",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const buttonCardSectionAppointment = {
    reference: "#appointment",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "More Details"
}

const buttonCardSectionIdScan = {
    reference: "#idscan",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "More Details"
}

const buttonCardSectionManagement = {
    reference: "#management",
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "More Details"
}

const imageCardSectionAppointment = {
    classnames: "flex justify-center items-center",
    src: "./images/appointment.svg",
    width: "300",
    height: "300",
    alt: ""
}

const imageCardSectionIdScan = {
    classnames: "flex justify-center items-center",
    src: "./images/idScan.svg",
    width: "300",
    height: "300",
    alt: ""
}

const imageCardSectionManagement = {
    classnames: "flex justify-center items-center",
    src: "./images/management.svg",
    width: "300",
    height: "300",
    alt: ""
}

const cardSectionFeatures = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3',
    textSection: textSectionCardSection,
    cards: [
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionAppointment,
            button: buttonCardSectionAppointment,
            image: imageCardSectionAppointment,
            onClick: () => window.location.href = "#appointment"
        },
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIdScan,
            button: buttonCardSectionIdScan,
            image: imageCardSectionIdScan,
            onClick: () => window.location.href = "#idscan"
        },
        {
            classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionManagement,
            button: buttonCardSectionManagement,
            image: imageCardSectionManagement,
            onClick: () => window.location.href = "#management"
        }
    ]
}

export default cardSectionFeatures;