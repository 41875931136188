import React, { useState, useEffect } from 'react';
import TextSection from '../../elements/TextSection';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import CheckBox from '../../elements/CheckBox';
import data from '../../data/pages/authentification/SignUpData';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { submitSignUp } from '../../../triggers/authentification/authentification';
import { useLocation } from 'react-router-dom';

function SignUp() {
    const [user, setUser] = useState({});
    const [warning, setWarning] = useState({});
    const [isOfficeReadOnly, setIsOfficeReadOnly] = useState(false);
    const [isTermsAccepted, setIsTermsAccepted] = useState(false);
    const location = useLocation();

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const officeId = params.get('office');
        if (officeId) {
            setUser(prevUser => ({ ...prevUser, officeId }));
            setIsOfficeReadOnly(true);
        }
    }, [location]);

    const nameVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['name'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content['name'] = warningObject.warning();
            setWarning(content);
        }
    }

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'validEmail' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['email'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest email nu este valid.',
                '',
                false
            );
            content['email'] = warningObject.warning();
            setWarning(content);
        }
    }

    const passwordVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'Parola';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'length', min: '8', max: '129'},
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['password'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Parola este obligatorie, trebuie sa aibe un minim de 8 caractere.',
                '',
                false
            );
            content['password'] = warningObject.warning();
            setWarning(content);
        }
    }

    const phoneNumberVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validPhoneNumber' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['phoneNumber'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest numar de telefon nu este valid, trebuie sa contina 10 cifre.',
                '',
                false
            );
            content['phoneNumber'] = warningObject.warning();
            setWarning(content);
        }
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'name': {
                nameVerifier(e.target.value);
                const content = { ...user };
                content['name'] = e.target.value;
                setUser(content)
                break;
            }
            case 'email': {
                emailVerifier(e.target.value);
                const content = { ...user };
                content['email'] = e.target.value;
                setUser(content)
                break;
            }
            case 'password': {
                passwordVerifier(e.target.value);
                const content = {...user};
                content['password'] = e.target.value;
                setUser(content)
                break;
            }
            case 'phoneNumber': {
                phoneNumberVerifier(e.target.value);
                const content = {...user};
                content['phoneNumber'] = e.target.value;
                setUser(content)
                break;
            }
            case 'admin': {
                const content = {...user};
                content['admin'] = !content['admin'];
                
                break;
            }
            case 'officeId': {
                if (!isOfficeReadOnly) {
                    const content = { ...user };
                    content['officeId'] = e.target.value;
                    setUser(content);
                }
                break;
            }
            default:
                break;
        }
    }

    const handleTermsChange = () => {
        warning['terms'] = false;
        setIsTermsAccepted(!isTermsAccepted);
    };

    const handleSubmit = async () => {
        if (!isTermsAccepted) {
            const content = { ...warning };
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Trebuie sa acceptati termenii si conditiile pentru a crea un cont.',
                true
            );
            content['terms'] = warningObject.warning();
            setWarning(content);
            return;
        }

        if (typeof warning['name'] === 'undefined' || typeof warning['email'] === 'undefined' || typeof warning['phoneNumber'] === 'undefined' || typeof warning['password'] === 'undefined') {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content);
            return;
        }

        if (warning['name'] || warning['email'] || warning['phoneNumber'] || warning['password']) {
            return;
        }

        const content = {...user};
        await submitSignUp(content['email'], content['name'], content['phoneNumber'], content['password'], content['officeId']);
        if (localStorage.getItem('ERR_MSG')) {
            const warningObject = new WarningUser(
                localStorage.getItem('ERR_TYP'),
                localStorage.getItem('ERR_NM'),
                localStorage.getItem('ERR_MSG'),
                localStorage.getItem('ERR_CRD')
            );
            const data = {...warning};
            data['fields'] = warningObject.warning();
            setWarning(data);
            localStorage.removeItem('ERR_TYP');
            localStorage.removeItem('ERR_NM');
            localStorage.removeItem('ERR_MSG');
            localStorage.removeItem('ERR_CRD');
        } else {
            const data = {...warning};
            data['fields'] = false;
            setWarning(data);
        }
    }

    return (
        <div className={data.classNameContainerPage}>
            <div className={data.classNameContainerCard}>
                <TextSection textSection={data.textSection} />
                <Input onChange={e => onInputChange(e, 'name')} value={user['name']} input={data.fullName} />
                {warning['name'] ? <TextSection textSection={warning['name']} /> : null }
                <Input onChange={e => onInputChange(e, 'phoneNumber')} value={user['phoneNumber']} input={data.phoneNumber} />
                {warning['phoneNumber'] ? <TextSection textSection={warning['phoneNumber']} /> : null }
                <Input onChange={e => onInputChange(e, 'email')} value={user['email']} input={data.effluenceEmail} />
                {warning['email'] ? <TextSection textSection={warning['email']} /> : null }
                <Input onChange={e => onInputChange(e, 'password')} value={user['password']} input={data.password} />
                {warning['password'] ? <TextSection textSection={warning['password']} /> : null }
                <Input 
                    onChange={e => onInputChange(e, 'officeId')} 
                    value={user['officeId']} 
                    input={isOfficeReadOnly ? data.officeIdReadOnly : data.officeId}
                />
                {warning['officeId'] ? <TextSection textSection={warning['officeId']} /> : null }
                <div className="flex text-content items-center mt-4 mb-4">
                    <input 
                        type="checkbox" 
                        id="terms" 
                        checked={isTermsAccepted} 
                        onChange={handleTermsChange} 
                        className="mr-2"
                    />
                    <label htmlFor="terms" className="text-sm">
                        Conform <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer" className="text-blue-600 underline">termenilor si conditiilor</a> sunteti deacord cu acestia
                    </label>
                </div>
                {warning['terms'] ? <TextSection textSection={warning['terms']} /> : null }
                <div className={data.classNameContainerQuestion}>
                    <TextSection textSection={data.textQuestion} />
                    <a href={data.redirectButton.reference}>
                        <Button button={data.redirectButton.button} />
                    </a>
                </div>
                <Button onClick={() => handleSubmit()} button={data.submitButton} />
            </div>
        </div>
    );
}

export default SignUp;