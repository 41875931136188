// Header Data 
import header from "./sectionsEnglish/HeaderData";

// Hero Data
import hero from "./sectionsEnglish/HeroData";

import heroWithVideo from "./sectionsEnglish/HeroWithVideoData";

// Card Section Key Features Data
import cardSectionFeatures from "./sectionsEnglish/CardSectionFeaturesData";

// Text Presentation Data
import textPresentation from "./sectionsEnglish/TextPresentationData";


// Card Section Appointment Feature
import cardSectionFeatureAppointment from "./sectionsEnglish/CardSectionFeatureAppointmentData";

// Card Section Id Scan Feature
import cardSectionFeatureIdScan from "./sectionsEnglish/CardSectionFeatureIdScanData";

// Card Section Management Feature
import cardSectionFeatureManagement from "./sectionsEnglish/CardSectionFeatureManagementData";

// Card Section Prices
import cardSectionPrices from "./sectionsEnglish/CardSectionPrices";

// Contact Data
import contact from "./sectionsEnglish/ContactData";

// Footer Data
import footer from "./sectionsEnglish/FooterData";

// Terms Data
import terms from "./sectionsEnglish/TermsData";

// Final Data for Landing Page
const landinPageData = {
    header: header,
    hero: hero,
    heroWithVideo: heroWithVideo,
    cardSectionFeatures: cardSectionFeatures,
    textPresentation: textPresentation,
    cardSectionFeatureAppointment: cardSectionFeatureAppointment,
    cardSectionFeatureIdScan: cardSectionFeatureIdScan,
    cardSectionFeatureManagement: cardSectionFeatureManagement,
    cardSectionPrices: cardSectionPrices,
    contact: contact,
    footer: footer,
    terms: terms
}

export default landinPageData;