import React from 'react';
import Button from '../elements/Button';
import TextSection from '../elements/TextSection';

function Terms({ terms }) {
    
  return (
    <div className={terms.classNameContainer}>

        {terms.button.map((button) => (
              <a href={button.refference}>
                <Button onClick={() => window.location.href = button.reference} button={button.button}/>   
              </a>
          ))}

        {terms.textSections.map((textSection, index) => (
          <TextSection key={index} textSection={textSection} />
        ))}
       

    </div>
  );
}

export default Terms;