import React from 'react';
import { Switch } from 'react-router-dom';

import './App.css';

// Architecture
import { PrivateRoute, PublicRoute } from './architecture/routes/ProtectedRoute';
import { AuthProvider} from './architecture/auth/AuthentificationContext';
import RedirectWithLocalStorage from './architecture/routes/RedirectWithLocalStorage';
import LayoutDefault from './architecture/layouts/LayoutDefault';
import LayoutVerification from './architecture/layouts/LayoutVerification';
import LayoutPresentation from './architecture/layouts/LayoutPresentation';
//Presentation Pages
import Landing from './components/pages/presentation/Landing.js';
import Policies from './components/pages/presentation/Policies.js';

// Authentification Pages
import SignIn from './components/pages/authentification/SignIn.js';
import SignUp from './components/pages/authentification/SignUp.js';

// Default Pages
import WelcomePanel from './components/pages/multiplePurpose/WelcomePanel.js';
import CreateOffice from './components/pages/office/CreateOffice.js';

import Dashboard from './components/pages/multiplePurpose/Dashboard.js';
import Clients from './components/pages/clients/Clients.js';
import Client from './components/pages/clients/Client.js';
import Notaries from './components/pages/notary/Notaries.js';
import Notary from './components/pages/notary/Notary.js';
import Appointments from './components/pages/appointment/Appointments.js';
import Folder from './components/pages/files/Folder.js';
import FileDoc from './components/pages/files/File.js';
import Profile from './components/pages/multiplePurpose/Profile.js';
import CreateAppointment from './components/pages/appointment/CreateAppointment.js';
import AddAppointment from './components/pages/appointment/AddAppointment.js';
import AddClient from './components/pages/clients/AddClient.js';
import Tasks from './components/pages/tasks/Tasks.js';
import AddTask from './components/pages/tasks/AddTask.js';
import AddClientScan from './components/pages/clients/AddClientScan.js';
import AddNotary from './components/pages/notary/AddNotary.js';
import AddFile from './components/pages/files/AddFile.js';
import Help from './components/pages/multiplePurpose/Help.js';

//Error Pages
import Error from './components/pages/errors/Error';

function ProtectedRouter() {
  const errorNotFound = {
    HTTP_CODE: localStorage.getItem("HTTP_CODE") ? localStorage.getItem("HTTP_CODE") : "404",
    ERR_NM: localStorage.getItem("ERR_NM") ? localStorage.getItem("ERR_NM") : "Pagina nu a fost gasita",
    ERR_MSG: localStorage.getItem("ERR_MSG") ? localStorage.getItem("ERR_MSG") : "Puteti sa reincercati sa incarcati pagina din nou sau puteti apasa pe butonul de inapoi pentru a reveni acasa."
  }
  return (
    <AuthProvider >
      <Switch>
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/error" component={Error} layout={LayoutVerification} />
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/" component={Landing} layout={LayoutPresentation} />
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/policies" component={Policies} layout={LayoutPresentation} />
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/signin" component={SignIn} layout={LayoutVerification} />
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/signup" component={SignUp} layout={LayoutVerification} />
        <PublicRoute restricted={false} isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/add-appointment/:id" component={AddAppointment} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/welcome" component={WelcomePanel} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/office" component={CreateOffice} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/tasks" component={Tasks} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/task-add" component={AddTask} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/task/:id" component={AddTask} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/dashboard" component={Dashboard} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/clients" component={Clients} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/folder" component={Folder} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/notary" component={Notaries} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/notary/:id" component={Notary} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/appointments" component={Appointments} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/appointment/:id" component={CreateAppointment} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/appointment/" component={CreateAppointment} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/help" component={Help} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/profile" component={Profile} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/notary/:id" component={Dashboard} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/client/:id" component={Client} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/file/:id" component={FileDoc} layout={LayoutDefault} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/create-appointment" component={CreateAppointment} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/client-add" component={AddClient} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/client-add-scan" component={AddClientScan} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/client-add/:id" component={AddClient} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/notary-add" component={AddNotary} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/file-add" component={AddFile} layout={LayoutVerification} />
        <PrivateRoute isAuthenticated={localStorage.getItem("TKN_ATH")} exact path="/file-add/:id" component={AddFile} layout={LayoutVerification} />
        
        <RedirectWithLocalStorage
          to="/error"
          localStorageData={errorNotFound}
        />
      </Switch>
    </AuthProvider>
  );
}

export default ProtectedRouter;
