const textSection ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Adauga Angajat",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const credentials = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Email/Numar de telefon Angajat",
    value: ""
};

const createButton = {
    classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
    tag: "button",
    text: "Adauga"
};

const cancelButton = {
    classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
    tag: "button",
    text: "Cancel"
};


const addnotData = {
    classNameContainerPage: "min-h-screen flex items-center justify-center ",
    classNameContainerCard: "min-w-[300px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
    classNameContainerQuestion: "",
    textSection: textSection,
    credentials: credentials,
    cancelButton: cancelButton,
    submitButton: createButton
};

export default addnotData;