import React, { useContext, useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import CardSection from "../../sections/CardSection";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import NotaryDataClass from '../../data/pages/notary/NotaryData';
import TableTaskSectionDone from '../../sections/TableTaskSectionDone';
import TableTaskSectionNow from "../../sections/TableTaskSectionNow";
import { fetchActiveFiles } from '../../../triggers/notary/notary';
import { fetchAllTasksByUserId } from '../../../triggers/tasks/tasks';

function Notary() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const {id} = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [notaryData, setNotaryData] = useState('{}');
    const [showTasks, setShowTasks] = useState([]);
    const [personalNotaryData, setPersonalNotaryData] = useState('{}');
    const [tasksDone, setTasksDone] = useState([]);
    const [tasksNow, setTasksNow] = useState([]);

    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) {
                setLoadingStatus(true);
                getData();
            }
            else {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice]);

    const getData = async () => {
        const [responseNotary, responseTasksPerUser] = await fetchActiveFiles(id);

        let tasksDoneArray = [];
        let tasksNowArray = [];

        responseTasksPerUser.forEach(task => {
            if (task.status === true) {
                tasksDoneArray.push(task);
            } else {
                tasksNowArray.push(task);
            }
        });
        setTasksDone(tasksDoneArray);
        setTasksNow(tasksNowArray);

        setNotaryData(NotaryDataClass.NotaryDataText(
            responseNotary, 
            responseTasksPerUser.filter(task => task.status), 
            responseTasksPerUser.filter(task => !task.status)
        ))

        setLoadingStatus(false);
    }

    return(
        <div>
            {
                !loadingStatus
                ?
                (
                    <div className={notaryData.classNameContainer}>  
                        <CardSection cardSection={notaryData.profileCredentials} />
                        <div className={notaryData.classNameContainerCards}>
                            <div className="flex mt-10 justify-between mx-4">
                                <div className="flex-1 mr-4 max-w-[calc(50%-8px)]">
                                    <h1 className="text-center mb-5 font-semibold bg-white p-2 rounded-md">Task-uri In Lucru</h1>
                                    {
                                        tasksNow.length > 0 ? (
                                           <TableTaskSectionNow tasksList={notaryData.tasksListNow} />
                                        ) : (
                                            <p className="text-center">Nu exista task-uri curente.</p>
                                        )
                                    }
                                </div>
                                <div className="flex-1 ml-4 max-w-[calc(50%-8px)]">
                                    <h1 className="text-center mb-5 font-semibold bg-white p-2 rounded-md">Task-uri Completate</h1>
                                    {
                                        tasksDone.length > 0 ? (
                                            <TableTaskSectionDone tasksList={notaryData.tasksListDone} />
                                        ) : (
                                            <p className="text-center">Nu sunt task-uri completate.</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}

export default Notary;