import React, { useContext, useEffect, useState } from "react";
import Header from '../../elements/TextSection';
import Button from '../../elements/Button';
import TableTaskSectionDone from '../../sections/TableTaskSectionDone';
import TableTaskSectionNow from "../../sections/TableTaskSectionNow";
import Input from '../../elements/Input';
import DropdownMenu from "../../elements/DropDownMenu";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import TasksDataClass from '../../data/pages/tasks/TasksData';
import { fetchClients } from "../../../triggers/clients/clients";
import { fetchAllTasksByOfficeId } from "../../../triggers/tasks/tasks";

function Tasks() {
    const { loading, isAuthenticated, isUserOfOffice } = useContext(AuthContext);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [searchParameter, setSearchParameter] = useState('');
    const [searchInput, setSearchInput] = useState("");
    const [clients, setClients] = useState([]);
    const [showTasks, setShowTasks] = useState([]);
    const [tasksNow, setTasksNow] = useState([]);
    const [tasksDone, setTasksDone] = useState([]);

    useEffect(() => {
        if (!loading) {
            if (!isUserOfOffice) window.location.href = "/welcome";
            if (isAuthenticated) getData();
            else window.location.href = "/signin"
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        try {
            const clientsData = await fetchClients();
            setClients(clientsData);

            const tasksData = await fetchAllTasksByOfficeId();
            showAllTasksArranged(tasksData);

            setShowTasks(tasksData)
            setLoadingStatus(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoadingStatus(false);
        }
    };

    const showAllTasksArranged = (tasks) => {
        let tasksDoneArray = [];
        let tasksNowArray = [];

        tasks.forEach(task => {
            if (task.status === true) {
                tasksDoneArray.push(task);
            } else {
                tasksNowArray.push(task);
            }
        });
        setTasksDone(tasksDoneArray);
        setTasksNow(tasksNowArray);
    }

    const searchTitle = () => {
        const filtered = showTasks.filter(task => task.title.toLowerCase().includes(searchInput.toLowerCase()) && !task.status);
        const filteredDone = showTasks.filter(task => task.title.toLowerCase().includes(searchInput.toLowerCase()) && task.status);
        setTasksNow(filtered);
        setTasksDone(filteredDone);
    };

    const searchNotary = () => {
        const filtered = showTasks.filter(task => task.name_asignee && task.name_asignee.toLowerCase().includes(searchInput.toLowerCase()) && !task.status);
        const filteredDone = showTasks.filter(task => task.name_asignee && task.name_asignee.toLowerCase().includes(searchInput.toLowerCase()) && task.status);
        setTasksNow(filtered);
        setTasksDone(filteredDone)
    };

    const search = {
        "": () => showAllTasksArranged(showTasks),
        title: searchTitle,
        notary: searchNotary,
    };

    return (
        <div>
            {
                !loadingStatus
                    ? (
                        <>
                            <div className="flex items-center justify-between">
                                <Header textSection={TasksDataClass.TasksDataText(tasksDone, tasksNow).header} />
                                <div>
                                    <Button onClick={() => window.location.href = "task-add"} button={TasksDataClass.TasksDataText(tasksDone, tasksNow).addTask} />
                                </div>
                            </div>
                            <div className="flex">
                                <Input onChange={e => setSearchInput(e.target.value)} input={TasksDataClass.TasksDataText(tasksDone, tasksNow).searchBoxTasks} />
                                <DropdownMenu onChange={(e) => setSearchParameter(e.target.value)} value={searchParameter} dropdown={TasksDataClass.TasksDataText(tasksDone, tasksNow).dropDownMenuParameterSearch} />
                                <Button onClick={() => search[searchParameter]()} button={TasksDataClass.TasksDataText(tasksDone, tasksNow).searchTask} />
                            </div>
                            <div className="flex mt-10 justify-between mx-4">
                                <div className="flex-1 mr-4 max-w-[calc(50%-8px)]">
                                    <h1 className="text-center mb-5 font-semibold bg-white p-2 rounded-md">Task-uri In Lucru</h1>
                                    {
                                        tasksNow.length > 0 ? (
                                           <TableTaskSectionNow tasksList={TasksDataClass.TasksDataText(tasksDone, tasksNow).tasksListNow} />
                                        ) : (
                                            <p className="text-center">Nu exista task-uri curente.</p>
                                        )
                                    }
                                </div>
                                <div className="flex-1 ml-4 max-w-[calc(50%-8px)]">
                                    <h1 className="text-center mb-5 font-semibold bg-white p-2 rounded-md">Task-uri Finalizate</h1>
                                    {
                                        tasksDone.length > 0 ? (
                                            <TableTaskSectionDone tasksList={TasksDataClass.TasksDataText(tasksDone, tasksNow).tasksListDone} />
                                        ) : (
                                            <p className="text-center">Nu sunt task-uri completate.</p>
                                        )
                                    }
                                </div>
                            </div>
                        </>
                    ) : (
                        <MoonLoader
                            className='flex m-auto mt-32'
                            color="#2563eb"
                            size={140}
                        />
                    )
            }
        </div>
    );
}

export default Tasks;
