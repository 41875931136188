class WarningUser {
    constructor(
        type,
        name,
        message,
        isCard
    ) {
        this.type = type;
        this.name = name;
        this.message = message;
        this.isCard = isCard;
    }

    warning(){
        return {
            tagHeader: "div",
            tagText: "div",
            classNameContainer: this.getClass().classNameContainer,
            classNameHeader: this.getClass().classNameHeader,
            header: this.name,
            textArray: [
                {
                    classNameText: this.getClass().classNameText,
                    text: this.message
                }
            ]
        }
    }

    getClass(){
        const color = this.type === 'warning' ? 'yellow' : (this.type === 'error' ? 'red' : 'green');
        if(this.isCard) {
            return {
                classNameContainer: `border border-blue-500 text-section-container p-1 rounded`,
                classNameHeader: `text-md text-blue-500 font-semibold`,
                classNameText: `text-sm text-blue-500`
            }
        } else {
            return {
                classNameContainer: "p-1",
                classNameHeader: `text-blue-500`,
                classNameText: `text-blue-500`
            }
        }
    }
}

export default WarningUser;