class CreateAppointmentClass {
    static CreateAppointmetText(id) {
        const textSection ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Programare",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const category = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Categorie"
                },
                        {
                    value: "Comodat",
                    label: "Comodat"
                },
                {
                    value: "Divort",
                    label: "Divort"
                },
                {
                    value: "Vanzare",
                    label: "Vanzare"
                },
                {
                    value: "Contract Prenuptial",
                    label: "Contract Prenuptial"
                },
                {
                    value: "Procura",
                    label: "Procura"
                },
                {
                    value: "Declaratii",
                    label: "Declaratii"
                },
                {
                    value: "Succesiune",
                    label: "Succesiune"
                },
                {
                    value: "Legalizare",
                    label: "Legalizare"
                },
                {
                    value: "Altele",
                    label: "Altele"
                },
        ]}

           
        const status = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-white bg-blue-500 px-2 py-2 rounded w-full mt-5",
            options: [
                {
                    value: "",
                    label: "Status"
                },
                        {
                    value: 101,
                    label: "In asteptare"
                },
                {
                    value: 200,
                    label: "In lucru"
                },
                {
                    value: 500,
                    label: "Finalizata"
                }
        ]}
        
        const textSection2 ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Data si ora*",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const textSection3 ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Ora",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        
        const day = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "DD",
            value: ""
        };
        const month = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "MM",
            value: ""
        };
        const year = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "YYYY",
            value: ""
        };
        
        
        const startHour = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "00",
            value: ""
        };
        const startMinutes = {
            classnames: "mt-4",
            classnamesInput: "text-center border border-blue-500 rounded-full p-1 w-full ",
            name: "",
            placeholder: "00",
            value: ""
        };

        const endHour = {
            classnames: "mt-4",
            classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
            name: "",
            placeholder: "00",
            value: ""
        };
        const endMinutes = {
            classnames: "mt-4",
            classnamesInput: "text-center border border-blue-500 rounded-full p-1 w-full ",
            name: "",
            placeholder: "00",
            value: ""
        };
        
        const Email = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Email Client",
            value: ""
        };
        
        const name = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Nume Client*",
            value: ""
        };
        
        const phoneNumber = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Număr Telefon*",
            value: ""
        };
        
        const textQuestion = {
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "",
            textArray: [
                {
                    classNameText: "text-content",
                    text: "Dacă nu aveți cont Effluence, puteăi merge la pagina de înregistrare!"
                }
            ]
        };
        
        const createButton = id ? {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: "Salveaza"
        } : {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: "Creare"
        };

        const deleteButton = {
            classnames: "text-white bg-red-600 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Sterge"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "md:min-h-screen flex items-center justify-center ",
            classNameContainerCard: "sm:m-atuo md:min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            classNameContainerQuestion: "",
            header: textSection,
            header2: textSection2,
            header3: textSection3,
            name: name,
            email: Email,
            phoneNumber: phoneNumber,
            day: day,
            month: month,
            year: year,
            startHour: startHour,
            startMinutes: startMinutes,
            endHour: endHour,
            endMinutes: endMinutes,
            category: category,
            status: status,
            createButton: createButton,
            deleteButton: deleteButton,
            cancelButton: cancelButton
            
        };
    }
}

export default CreateAppointmentClass;