class ErrorData {
    static ErrorDataText(errorName, errorHttpCode, errorMessage, isAuthenticated) {
        const textSectionCardSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-2xl font-bold mb-2 text-blue-600",
            header: errorHttpCode,
            textArray: [
                {
                    classNameText: "text-lg font-semibold text-blue-600",
                    text: errorName + ":"
                },
                {
                    classNameText: "text-lg font-semibold text-blue-600",
                    text: errorMessage
                }
            ]
        }

        const buttonCardSection = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
            tag: "button",
            text: "Inapoi"
        }

        const imageCardSection = {
            classnames: "",
            src: "../images/error-icon.svg",
            width: "300",
            height: "300",
            alt: ""
        }

        const cardSectionGeneralInfo = {
            classNameContainer: "flex",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: textSectionCardSection,
                    button: buttonCardSection,
                    image: imageCardSection,
                    onClick: () => {
                        isAuthenticated
                        ?
                        window.location.href = '/dashboard'
                        :
                        window.location.href = '/signin'
                    }
                }
            ]
        }

        return cardSectionGeneralInfo
    }
}

export default ErrorData;