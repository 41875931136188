const textSectionTextPresentation = {
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Integration Flexibility",
    textArray: [
        {
            text: "Whether you already have a website or not, our services are flexible and easily adapt to your requirements. For those who already have a website, we offer the option to integrate the Enotar app, while for those who do not have a website, we can create one specially for you.",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const buttonTextPresentation = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageTextPresentation = {
    classnames: "objectcontain",
    src: "./images/textPresentation.svg",
    width: "700",
    height: "",
    alt: ""
}

const textPresentation = {
    classNameContainer: "flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0 mt-6",
    textSection: textSectionTextPresentation,
    button: buttonTextPresentation,
    image: imageTextPresentation
}

export default textPresentation;