import React, { useState } from 'react';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import data from '../../data/pages/office/CreateOfficeData';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { submit } from '../../../triggers/office/office.js';
import DateBox from 'devextreme-react/date-box';

function CreateOffice() {
    const [office, setOffice] = useState({});
    const [warning, setWarning] = useState({});


    const stringVerifier = (value, field) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content[field] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest camp este obligatoriu.',
                '',
                false
            );
            content[field] = warningObject.warning();
            console.log(content)
            setWarning(content);
        }
    }

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'validEmail' },
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['email'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Acest email nu este valid.',
                '',
                false
            );
            content['email'] = warningObject.warning();
            setWarning(content);
        }
    }

    const passwordVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'Parola';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'length', min: '8', max: '129'},
        ];
        const content = { ...warning };
        if (fieldVerifier.verifyField(value, fieldName, criteria)) {
            content['password'] = false;
            content['fields'] = false;
            setWarning(content);
        } else {
            const warningObject = new WarningUser(
                'error',
                'Parola este obligatorie, trebuie sa aibe un minim de 8 caractere.',
                '',
                false
            );
            content['password'] = warningObject.warning();
            setWarning(content);
        }
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'address': {
                stringVerifier(e.target.value, 'address');
                const content = {...office};
                content['address'] = e.target.value;
                setOffice(content)
                break;
            }
            case 'name': {
                stringVerifier(e.target.value, 'name');
                const content = {...office};
                content['name'] = e.target.value;
                setOffice(content)
                break;
            }
            case 'email': {
                emailVerifier(e.target.value);
                const content = {...office};
                content['email'] = e.target.value;
                setOffice(content)
                break;
            }
            case 'password': {
                passwordVerifier(e.target.value);
                const content = {...office};
                content['password'] = e.target.value;
                setOffice(content)
                break;
            }
            case 'workingHoursStart': {
                const content = {...office};
                content['workingHoursStart'] = e.value;
                setOffice(content)
                break;
            }
            case 'workingHoursEnd': {
                const content = {...office};
                content['workingHoursEnd'] = e.value;
                setOffice(content)
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = () => {
        if(typeof warning['address'] !== 'undefined' && typeof warning['email'] !== 'undefined' && typeof warning['name'] !== 'undefined' && typeof warning['password'] !== 'undefined'){
            if(!(warning['address'] || warning['name'] || warning['email'] || warning['password'])) {
                submit(office['address'], office['name'], office['email'], office['password'], office['workingHoursStart'], office['workingHoursEnd'])
            } else {
                const content = {...warning};
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Toate campurile cu exceptia programului orar trebuiesc completate!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content); 
            }
        } else {
            const content = {...warning};
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Toate campurile cu exceptia programului orar trebuiesc completate!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content); 
        }

    }

    return(
    <div className={data.classNameContainer}>
        <div className={data.classNameContainerCard}>
        <TextSection textSection={data.textSectionHeader} />
        <Input onChange={(e) => onInputChange(e, 'address')} value={office['address']} input={data.address}/>
        {warning['address'] ? <TextSection textSection={warning['address']} /> : null }
        <Input onChange={(e) => onInputChange(e, 'name')} value={office['name']} input={data.name}/>
        {warning['name'] ? <TextSection textSection={warning['name']} /> : null }
        <Input onChange={(e) => onInputChange(e, 'email')} value={office['email']} input={data.email}/>
        {warning['email'] ? <TextSection textSection={warning['email']} /> : null }
        <Input onChange={(e) => onInputChange(e, 'password')} value={office['password']} input={data.password}/>
        {warning['password'] ? <TextSection textSection={warning['password']} /> : null }
        <div>
            {/* Start Time */}
            <TextSection textSection={data.textSectionWorkingHours} />
            <div class="flex flex-row space-x-4 mt-2">
                <DateBox
                    type="time"
                    value={office['workingHoursStart']}
                    onValueChanged={(e) => onInputChange(e, 'workingHoursStart')}
                    displayFormat="HH:mm"
                    format="HH:mm" 
                    placeholder="HH:MM"
                />
                <p className='font-bold'>-</p>
                <DateBox
                    type="time"
                    value={office['workingHoursEnd']}
                    onValueChanged={(e) => onInputChange(e, 'workingHoursEnd')}
                    displayFormat="HH:mm"
                    format= "HH:mm"
                    placeholder="HH:MM"
                />
            </div>
        </div>
        {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null }
        <div class="flex justify-between">
            <Button onClick={() => handleSubmit()} button={data.createButton}/>
            <Button onClick={() => window.location.href = "/welcome"} button={data.cancelButton}/>
        </div>
        </div>
    </div>
);
}

export default CreateOffice;
