import React, { useState, useEffect, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../elements/Button';
import Input from '../elements/Input';
import TextSection from '../elements/TextSection';
import { PulseLoader } from 'react-spinners';
import { handleUpdateFileDocument, handleScanDocument, handleScanId } from '../../modules/files/files';
import Notification from './Notification';

const DocumentEditor = ({ documentEditor }) => {
  const containerRef = useRef(null);
  const notificationRef = useRef(null);
  const [editorHtml, setEditorHtml] = useState(documentEditor.document);
  const [selectedFile, setSelectedFile] = useState(null);
  const [statusUpload, setStatusUpload] = useState(false);
  const [notificationText, setNotificationText] = useState('')
  useEffect(() => {
    if (containerRef.current) {
        containerRef.current.querySelectorAll('.text-blue-500').forEach(el => {
            el.addEventListener('click', () => {
              setNotificationText("Sectiune de text pentru " + el.getAttribute('data-label') + " a fost copiata")
                const text = el.getAttribute('data-value');
                copyToClipboard(text);
            });
        });
    }
}, [editorHtml]);

function copyToClipboard(text) {
  navigator.clipboard.writeText(text)
  .then(() => {notificationRef.current.show();})
  .catch(() => {
    
  });;
  
}

  const handleEditorChange = (html) => {
    setEditorHtml(html);
  };

  const saveDocument = async () => {
    try {
      await handleUpdateFileDocument({
        file_id: documentEditor.file_id,
        document: editorHtml
      });
    } catch(error) {
      setStatusUpload(false);
      setSelectedFile(null);
    }
  };
  
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
  };
  
  const scanDocument = async () => {
    if (selectedFile) {
      try {
        const reader = new FileReader();
        setStatusUpload(true);
        reader.onload = async (event) => {
          console.log(event.target)
          const base64Data = event.target.result;
          try{
            await handleScanDocument({
              file_id: documentEditor.file_id,
              document: base64Data,
            });
          } catch(error) {
            setStatusUpload(false);
            setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme, va rugam sa reincercati cu un alt document!'});
          }
        };
  
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        setStatusUpload(false);
        setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme de format, va rugam in cazul in care problema persista si cu alte documente sa contactati echia de suport!'});
      }
    } else {
      console.log("No file selected");
    }
  };
  
  const scanId = async () => {
    if (selectedFile) {
      try {
        const reader = new FileReader();
        setStatusUpload(true);
        reader.onload = async (event) => {
          const base64Data = event.target.result;
          try{
            await handleScanId({
              file_id: documentEditor.file_id,
              document: base64Data,
            });
          } catch(error) {
            setStatusUpload(false);
            setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme, va rugam sa reincercati cu un alt document!'});
          }
        };
  
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        setStatusUpload(false);
        setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme de format, va rugam in cazul in care problema persista si cu alte documente sa contactati echia de suport!'});
      }
    } else {
      console.log("No file selected");
    }
  };

  return (
    <>
        <Notification
          ref={notificationRef}
          message={notificationText}
        />
        <div className="bg-white p-8 rounded-lg shadow-lg">
          <TextSection textSection={documentEditor.headerSection} />
          <div className='bg-white border border-blue-500 p-4 rounded-lg mb-4'>
            <div className='flex justify-between'>
              <label onChange={(e) => handleFileChange(e)} className={documentEditor.classNameInput}>
                  <Input input={documentEditor.inputScan} />
                  Selecteaza document
              </label>
              {statusUpload ? <PulseLoader className='py-2' color="#2563eb" />
              : selectedFile ? (<p className='bg-white p-2 text-blue-500'>{selectedFile.name}</p>) : null
              }
                <div className="flex justify-between space-x-4">
                  <Button onClick={(e) => scanId(e)} button={documentEditor.buttonScanId} />
                  <Button onClick={(e) => scanDocument(e)} button={documentEditor.buttonScan} />
                </div>
            </div>
          </div>

          <div className="mt-6">
            <h2 className="text-xl font-semibold mb-2">Previzualizare</h2>
            <div
              ref={containerRef}
              className="bg-gray-200 p-4 border border-gray-300 rounded-lg"
              dangerouslySetInnerHTML={{ __html: editorHtml }}
            />
          </div>
        </div>
    </>
  );
};

// Quill editor modules and formats
const editorModules = {
  toolbar: [
    [{ header: '1' }, { header: '2' }, { font: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['bold', 'italic', 'underline'],
    [{ align: [] }],
    ['link', 'image'],
    ['clean'],
  ],
};

const editorFormats = [
  'header',
  'font',
  'list',
  'bold',
  'italic',
  'underline',
  'align',
  'link',
  'image',
];

export default DocumentEditor;