import getData from '../../request_methods/dataGet.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export default async function handleTokenVerification()  {
    try { 
        const requestObject = new RequestClass('/ping/token', {}, 100, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        console.log(response)
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        throw new APIError("Sesiunea a expirat!", 404, true, "Ne pare rau sesiunea a expirat sau aplicatia a cedat, va sugeram sa va relogati in cont!");
    }
}