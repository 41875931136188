import postData from '../../request_methods/dataPost.js';
import getData from '../../request_methods/dataGet.js';
import putData from '../../request_methods/dataPut.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export const handleRegisterOffice = async (officeData) => {
    try {
        const requestObject = new RequestClass('/office/create-and-add-user/post', officeData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        console.log(response)
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleGetOffice = async (officeData) => {
    try {
        const requestObject = new RequestClass('/office/details/get', officeData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleGetOfficeCustomer = async (officeData) => {
    try {
        const requestObject = new RequestClass('/office/details/get', officeData, 300, "");
        const response = await getData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};

export const handleUpdateOffice = async (officeData) => {
    try {
        const requestObject = new RequestClass('/office/update/put', officeData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if (!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch (error) {
        if (error.httpCode === 500)
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
};
