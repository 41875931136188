
const textSectionHero = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "my-2",
    classNameHeader: "text-2xl font-bold mb-2",
    header: "Notary services simplified, with Enotar",
    textArray: [
        {
            text: "Enotar is the best notary management platform, that makes daily operations, from scheduling appointments to managing documents, more efficient",
            classNameText: "text-lg text-gray-600"
        }
    ]
}

const buttonHero = {
    classnames: "text-white bg-blue-600 px-2 py-2 my-2 rounded",
    tag: "button",
    text: "Start now",
    reference: "/signup"
}

const imageHero = {
    classnames: "object-contain",
    src: "./images/hero.svg",
    width: "",
    height: "",
    alt: ""
}

const hero = {
    classNameContainer: "py-8 md:py-16 lg:py-20 px-4 sm:px-6 lg:px-8 flex flex-col md:flex-row items-center justify-center space-y-8 md:space-y-0",
    textSection: textSectionHero,
    button: buttonHero,
    image: imageHero
}

export default hero;
