import React from 'react';

const LayoutPresentation = ({ children }) => (
  <div className="">
    <main className="">
      {children}
    </main>
  </div>
);

export default LayoutPresentation;
