import getData from '../../request_methods/dataGet.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export default async function handleSignUp(signUpData)  {
    try {
        const requestObject = new RequestClass('/notary/create/post', signUpData, 101, "");
        const response = (await getData(requestObject.getRequest()));
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.message === 'internal server error' || error.name === 'Notary') 
            throw new APIError("Inregistrare Esuata", error.httpCode, true, "Inregistrarea unui cont cu aceste credentiale nu se poate efectua, se pare ca exista un user deja creeat cu emailul sau numarul de telefon.");
        else
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
    }
}