const textSection ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Inregistrare",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const fullName = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "text",
    name: "",
    placeholder: "Nume Prenume",
};

const phoneNumber = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "text",
    name: "",
    placeholder: "Numar de telefon",
    value: ""
};

const effluenceEmail = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "text",
    name: "",
    placeholder: "Adresa de email"
};

const password = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "password",
    name: "admin",
    placeholder: "Parola"
};

const admin = {
    classnames: "flex",
    textClassName: "text-content text-sm mt-2",
    text: "Sunteti administratorul biroului dumneavoastra?",
    name: "admin"
};

const textError = {
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "bg-red-200 border border-red-300 text-section-container p-2 rounded",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "A intervenit o eroare in procesul de inregistrare. Verificati ca datele de inregistrare sa fie corect introduse!"
        }
    ]
};

const textQuestion = {
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Daca aveti un cont Enotar, puteti merge la pagina de autentificare!"
        }
    ]
};

const redirectButton = {
    reference: "/signin",
    button: {
        classnames: "text-blue-600 rounded my-2",
        tag: "button",
        text: "Pagina de autentificare"
    }
};

const officeId = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full",
    type: "text",
    name: "",
    placeholder: "Id birou (optional)"
};

const officeIdReadOnly = {
    classnames: "mt-4",
    classnamesInput: "border border-gray-300 bg-gray-100 text-gray-600 rounded-full p-2 w-full cursor-not-allowed opacity-75",
    type: "text",
    name: "",
    placeholder: "Id birou",
    readOnly: true,
    disabled: true
};

const submitButton = {
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Inregistrare cont"
};

const signInData = {
    classNameContainerPage: "min-h-screen flex items-center justify-center ",
    classNameContainerCard: "min-w-[300px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
    classNameContainerQuestion: "",
    textSection: textSection,
    fullName: fullName,
    phoneNumber: phoneNumber,
    effluenceEmail: effluenceEmail,
    password: password,
    admin: admin,
    officeId: officeId,
    officeIdReadOnly: officeIdReadOnly,
    textError: textError,
    textQuestion: textQuestion,
    redirectButton: redirectButton,
    submitButton: submitButton
};

export default signInData;