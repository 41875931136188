import React, { useState } from 'react';
import Button from '../elements/Button';
import DropDownMenu from '../elements/DropDownMenu';
import Image from '../elements/Image';

import '../../styles/sections/header.css'

function Header({ header }) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLanguageChange = (e) => {
    localStorage.setItem("language", e.target.value);
    window.location.href = "/";
  };

  return (
    <div className={header.classNameContainer}>
      <Image image={header.image} />
      <div className="space-x-4 flex">
        <DropDownMenu onChange={handleLanguageChange} dropdown={header.dropdown} />
        {header.buttons.map(button => (
          <Button onClick={() => window.location.href = button.reference} button={button} key={button.id} />
        ))}
      </div>
      {/* Burger menu button */}
      <div className="md:hidden">
        <button
          onClick={toggleMenu}
          className="burger-menu-icon text-blue-600 hover:text-blue-700 focus:text-blue-700 focus:outline-none"
        >
          <svg
            className="mt-2 mr-8 w-7 h-7"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            ></path>
          </svg>
        </button>
      </div>
      {/* Responsive menu */}
      {isMenuOpen && (
        <div className="md:hidden absolute top-20 right-2 bg-white p-4 z-10 shadow-lg rounded">
          <DropDownMenu onChange={handleLanguageChange} dropdown={header.dropdownBurger} />
          {header.buttonsBurger.map(button => (
            <a href={button.reference} className="block text-gray-600 hover:text-gray-900 my-1" key={button.text}>
              <Button button={button} />
            </a>
          ))}
        </div>
      )}
    </div>
  );
}

export default Header;
