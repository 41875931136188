import React, { useEffect, useState } from 'react';

import Terms from '../../sections/Terms';
import landingPageDataRomanian from'../../data/pages/presentation/LandingPageDataRomanian.js';
import landingPageDataEnglish from'../../data/pages/presentation/LandingPageDataEnglish.js';

function Policies(){
  const [landinPageData, setLandingPageData] = useState(navigator.language.substring(0, 2) === 'en' ?landingPageDataEnglish : landingPageDataRomanian);
  useEffect(() => {
    if (localStorage.getItem("language") != null && localStorage.getItem("language").toLowerCase() === 'ro') {
      setLandingPageData(landingPageDataRomanian); 
    } else {
      setLandingPageData(landingPageDataEnglish);
    }
  }, []);

    return (
        <>
         <div className='container mx-auto px-4 md:px-8 lg:px-16 xl:px-20'>
          
         <Terms terms={landinPageData.terms}/>

          </div>
        </>
      );
}

export default Policies;