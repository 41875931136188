import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({
  component: Component,
  layout: Layout,
  user,
  secureStorage,
  ...rest
}) => {
  Layout = (Layout === undefined) ? props => (<>{props.children}</>) : Layout;
  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} user={user} secureStorage={secureStorage}/>
        </Layout>
      )} />
  );
}

export default AppRoute;