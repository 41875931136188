class ProfileDataClass {
    constructor(){

    }
    
    static ProfileDataText(userInfo, files, tasksNow){

        const textSectionCredentials = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: userInfo.name,
            textArray: [
                {
                    classNameText: "text-content",
                    text: "Numar de Telefon: " + userInfo.phone_number
                },
                {
                    classNameText: "text-content",
                    text: "Email Effluence: " + userInfo.email_effluence
                },
                {
                    classNameText: "text-content",
                    text: userInfo.email !== null ? "Email Personal: " + userInfo.email : "Emailul Personal: Nu este disponibil."
                }
            ]
        }

        const buttonCredentials = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
            tag: "button",
            text: "Iesire Cont"
        }

        const imageCredentials = {
            classnames: "",
            src: "../images/profile-icon.svg",
            width: "200",
            height: "200",
            alt: ""
        }

        const profileCredentials = {
            classNameContainer: "",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: textSectionCredentials,
                    button: buttonCredentials,
                    image: imageCredentials,
                    onClick: () => {
                        window.location.href = "/signin";
                        localStorage.clear()
                    }
                }
            ]
        }


        const buttonTasks = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 mr-2",
            tag: "button",
            text: "Cazuri active"
        }

        const buttonTasksActivated = {
            classnames: "text-blue-600 bg-white border border-blue-600 hover:text-white hover:bg-blue-600 transition px-2 py-2 rounded mt-2 mr-2",
            tag: "button",
            text: "Cazuri active"
        }

        const buttonProfileInformations = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2 ml-2",
            tag: "button",
            text: "Informatii personale"
        }

        const buttonProfileInformationsActivated = {
            classnames: "text-blue-600 bg-white border border-blue-600 hover:text-white hover:bg-blue-600 transition px-2 py-2 rounded mt-2 ml-2",
            tag: "button",
            text: "Informatii personale"
        }

        const tasks = {
            classNameContainer: "",
            cards: files.map((item) => (
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: {
                        tagHeader: "h1",
                        tagText: "div",
                        classNameContainer: "text-section-container",
                        classNameHeader: "text-header",
                        header: item.title,
                        textArray: [
                            {
                                classNameText: "text-content",
                                text: "Categorie: " + item.category
                            },
                            {
                                classNameText: "text-content",
                                text: "Cod Document: " + item.document_code
                            }
                        ]
                    },
                    button: {
                        classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
                        tag: "button",
                        text: "Detalii"
                    },
                    image: {
                        classnames: "",
                        src: "",
                        width: "",
                        height: "",
                        alt: ""
                    },
                    onClick: () => {
                        window.location.href = "/file/" + item.file_id
                    }
                }
            ))
        }

        const tasksListNow = 
        tasksNow.map((item) => ({
            nowTasks: item,
            text1: item.title,
            text2: item.name_asignee,
            text3: item.name_creator,
            text4: item.date_time_deadline,
            button: {
                text: "Detalii",
                location: '/task/' + item.task_id,
            },
            button2: {
                text: "Finalizare"
            },
            button3: {
                text: "Stergere"
            }
        }));

        const textSectionAdmin = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Effortless Collaboration",
            textArray: [
                {
                    classNameText: "text-content",
                    text: "Collaborate seamlessly with clients and colleagues, keeping everyone on the same page."
                }
            ]
        }

        const buttonAdmin = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
            tag: "button",
            text: "Button Two"
        }

        const imageAdmin = {
            classnames: "",
            src: "",
            width: "",
            height: "",
            alt: ""
        }

        const adminCard = {
            classNameContainer: "",
            cards: [
                {
                    classNameContainer: "bg-white p-6 shadow-md rounded-lg mt-6",
                    textSection: textSectionAdmin,
                    button: buttonAdmin,
                    image: imageAdmin
                }
            ]
        }

        return {
            classNameContainer: "",
            profileCredentials: profileCredentials,
            buttonTasks: buttonTasks,
            buttonTasksActivated: buttonTasksActivated,
            buttonProfileInformations: buttonProfileInformations,
            buttonProfileInformationsActivated: buttonProfileInformationsActivated,
            classNameContainerCards: "",
            tasks: tasks,
            tasksListNow: tasksListNow,
            adminInformation: adminCard,
            cardInformation: adminCard
        }

    }
}

export default ProfileDataClass;