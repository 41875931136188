import getData from '../../request_methods/dataGet.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export default async function handleGetDashboard(signInData) {
    try {
        const requestObject = new RequestClass('/multipurpose/dashboard/get', signInData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if (!response.error) {
            return {
                status: 200,
                appointmentsTotalNumberGet: response.data.appointmentsTotalNumberGet,
                appointmentsAllGet: response.data.appointmentsAllGet,
                allFilesByOfficeIdGet: response.data.allFilesByOfficeIdGet
            };
        } else {
            throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
        }
    } catch (error) {
        if (error.httpCode === 500) {
            throw new APIError("A intervenit o eroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        } else {
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
        }
    }
};
