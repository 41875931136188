import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CardSection from "../../sections/CardSection";
import TextSection from "../../elements/TextSection";
import { MoonLoader } from "react-spinners";
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import ClientDataClass from '../../data/pages/clients/ClientData';
import { fetchData } from "../../../triggers/clients/clients";

function Client() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const { id } = useParams();
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [data, setData] = useState('{}');

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else window.location.href = "/";
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getData = async () => {
        const [responseClient, responseFiles] = await fetchData(id);
        setData(ClientDataClass.ClientDataText(responseClient, responseFiles));
        setLoadingStatus(false);
    }

    return(
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={data.classNameContainer}>  
                        <CardSection cardSection={data.profileCredentials} />
                        <TextSection textSection={data.textSectionFiles} />
                        <div className={data.classNameContainerCards}>
                            <CardSection cardSection={data.tasks}/>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default Client;