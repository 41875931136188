import React from 'react';

// Generate a unique color based on a string
const stringToColor = (str) => {
  let hash = 0;
  for (let i = 0; i < str.length; i++) {
    hash = str.charCodeAt(i) + ((hash << 5) - hash);
  }
  const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 16777215)).toString(16);
  return '#' + color;
};

// Get initials from a string
const getInitials = (str) => {
  const words = str.split(' ');
  return words.map((word) => word.charAt(0).toUpperCase()).join('');
};

const UserIcons = ({ userIcons }) => {
  return (
    <div className={userIcons.classNamesContainer}>
      {userIcons.usernames.map((username, index) => (
        <div 
            key={index} 
            className={`w-8 h-8 bg-blue-500 border border-gray-100 border-2 text-white rounded-full flex items-center justify-center ${index !== 0 ? 'ml-[-0.35rem]' : ''}`}
            style={{ backgroundColor: stringToColor(username) }}>
                {getInitials(username)}
        </div>
      ))}
    </div>
  );
};

export default UserIcons;
