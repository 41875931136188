import secureStorage from '../../modules/secureStorage/secure.js';
import handleGetDashBoard from '../../modules/multiplePurpose/dashboard.js';
import { handleGetNumberOfAppointments } from '../../modules/appointment/appointment.js';

export async function fetchEvents() { 
    try{
        const startDate = localStorage.getItem("CRNT_YR") + "-" + localStorage.getItem("CRNT_MNTH").toString().padStart(2, "0") + "-01 01:00:00";
        const endDate = localStorage.getItem("CRNT_YR")  + "-" + (Number(localStorage.getItem("CRNT_MNTH")) + 1).toString().padStart(2, "0") + "-01 01:00:00";
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetDashBoard({
            appointmentsTotalNumberGet: {
                office_id: secure.getItem("UDP_UN").office_id,
                start_timedate: startDate,
                end_timedate: endDate
            },
            appointmentsAllGet: {
                office_id: secure.getItem("UDP_UN").office_id
            },
            allFilesByOfficeIdGet: {
                office_id: secure.getItem("UDP_UN").office_id
            }
        });
        console.log(response.appointmentsAllGet)
        const transformedEvents = response.appointmentsAllGet.map(item => ({
            startDate: new Date(item.start_timedate),
            endDate: new Date(item.end_timedate),
            text: `${item.name} - ${item.category}`,
            description: `Numarul de telefon este: ${item.phone_number}\n${item.email === null ? "Emailul nu este valabil" : "Emailul este: " + item.email}`,
            status: item.status,
            appointmentId: item.appointment_id
        }));
        return [response.appointmentsTotalNumberGet, response.allFilesByOfficeIdGet, transformedEvents];
    } catch(error) {
        
    }
}


export async function fetchAppointmentsCurrentMonth() {
    try {
        const startDate = localStorage.getItem("CRNT_YR") + "-" + localStorage.getItem("CRNT_MNTH").toString().padStart(2, "0") + "-01 01:00:00";
        const endDate = localStorage.getItem("CRNT_YR")  + "-" + (Number(localStorage.getItem("CRNT_MNTH")) + 1).toString().padStart(2, "0") + "-01 01:00:00";
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetNumberOfAppointments({
            office_id: secure.getItem("UDP_UN").office_id,
            start_timedate: startDate,
            end_timedate: endDate
        })
        return response;
    } catch(error) {

    }
}
