import React, { useContext, useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import AddClientDataScanClass from '../../data/pages/clients/AddClientDataScan';
import { handleScanId, handleGetFile } from '../../../modules/files/files';
import '../../../styles/sections/calendar.css';
import { sendDataClient, fetchFile } from '../../../triggers/files/files';
import { sendData } from '../../../triggers/clients/clients';

function AddClientScan() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);

    const [loadingStatus, setLoadingStatus] = useState(true);
    const [clientaddData, setClientAddData] = useState('{}');
    const [selectedFile, setSelectedFile] = useState('{}');

    useEffect(() => {
        setLoadingStatus(true);
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated){
                setClientAddData(AddClientDataScanClass.AddClientDataScanText());
                setLoadingStatus(false);
            }
            else window.location.href = "/signin";
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const scanDocument = async () => {
        if (selectedFile) {
          try {
            const reader = new FileReader();
            reader.onload = async (event) => {
              const base64Data = event.target.result;
              try{
                  const dataScanDocuement = await sendDataClient({
                        title: 'Scan ' + new Date().toLocaleString('ro-RO', {
                            weekday: 'long',
                            day: 'numeric',
                            month: 'long',
                            year: 'numeric',
                            hour: '2-digit',
                            minute: '2-digit'
                        }),
                        category: 'Id',
                        status: 101,
                        start_date: new Date(),
                        document_code: 'A20-15-2020'
                  })
                  const scanId = await handleScanId({
                    file_id: dataScanDocuement.file_id,
                    document: base64Data,
                  });
                  console.log(scanId)
                  const dataClient =  await handleGetFile({
                    file_id: dataScanDocuement.file_id
                  });
                  console.log(dataClient)
                  let startDateRaw = dataClient.valabilitate.substring(0, 8);
                  startDateRaw = startDateRaw.substring(0, 6) + '20' + startDateRaw.substring(6, 8);
                  dataClient.startDate = startDateRaw.replace(/\./g, '-');
                  
                  let endDateRaw = dataClient.valabilitate.substring(9, 19);
                  dataClient.endDate = endDateRaw.replace(/\./g, '-');
                  
                  const dataCeateClient = await sendData({
                    name: dataClient.nume + " " + dataClient.prenume,
                    address_document: dataClient.adresa,
                    presonal_code: dataClient.cnp,
                    series: dataClient.serie,
                    series_no: dataClient.numar,
                    document_validity_start: dataClient.startDate,
                    document_validity_end: dataClient.endDate,
                    sex: dataClient.sex.toLowerCase() === '' ? true : false
                  })
                window.location.href = "/client/"+dataCeateClient.client_id;
              } catch(error) {
                console.log(error)
                setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme, va rugam sa reincercati cu un alt document!'});
              }
            };
      
            reader.readAsDataURL(selectedFile);
          } catch (error) {
            setSelectedFile({name: 'Documentul nu a putut sa fie scanat din cauza unor probleme de format, va rugam in cazul in care problema persista si cu alte documente sa contactati echia de suport!'});
          }
        } else {
          console.log("No file selected");
        }
      };

    return(
        <>
            {
                !loadingStatus
                ?
                (
                    <div className={clientaddData.classNameContainer}>
                        <div className={clientaddData.classNameContainerCard}>
                            <TextSection textSection={clientaddData.textSectionAddClient} />
                            <div className='flex bg-white border border-blue-500 p-4 rounded-lg my-4'>
                                <div className='m-auto'>
                                    <label onChange={(e) => handleFileChange(e)} className='text-white bg-blue-600 px-2 py-2 rounded'>
                                        <Input input={clientaddData.upload} />
                                        Selecteaza document
                                    </label>
                                    {
                                        selectedFile ? (<p className='bg-white p-2 my-2 text-blue-500'>{selectedFile.name}</p>) : null
                                    }
                                </div>
                            </div>
                            <div className="flex justify-between">
                                <Button
                                    button={clientaddData.addButton}
                                    onClick={() => scanDocument()}
                                />
                                <Button 
                                    button={clientaddData.cancelButton}
                                    onClick={() => window.location.href = "/clients"}
                                />
                            </div>
                        </div>
                    </div>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
    );
}

export default AddClientScan;

