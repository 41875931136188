const textSectionsTerms = [
    {
        tagHeader: "div",
        tagText: "div",
        classNameContainer: "mt-8 mb-8 pl-4 pr-4 w-full text-left shadow-xl bg-white", // Updated background color to grey
        classNameHeader: "text-blue-600 text-center mt-4 mb-8 text-header", // Updated header color to blue
        header: "Termeni și Condiții",
        textArray: [
            {
                text: "1.1 Noi înseamnă: dezvoltatorii aplicației EFLUENCE, aplicație deținută de compania TECOOW SRL, înființata în conformitate cu legile din România.",
                classNameText: "text-black" // Making the text black
            },
            {
                text: "1.2 Dumneavoastră/Utilizatorul înseamnă: Societățile notariale sau cabinetele notariale ce accesează website-ul și utilizează serviciile noastre.",
                classNameText: "text-black"
            },
            {
                text: "1.3. Servicii înseamnă: Serviciile prezentate pe Website, specifice profesiei de notar, pe care noi le prestăm cu scopul de a digitaliza și de a îmbunătăți activitatea notarială.",
                classNameText: "text-black"
            },
            {
                text: "1.4 Termeni și Condiții înseamnă: toate dispozițiile prezentate în continuare ce se aplică relației dintre Noi și Dumneavoastră, atunci când accesați sau utilizați Website-ul.",
                classNameText: "text-black"
            },
            {
                text: "1.5 Politicile GDPR înseamnă: Politica privind modulele cookies disponibilă aici.",
                classNameText: "text-black"
            },
            {
                text: "2. Aplicabilitate Termeni și Condiții. Forță obligatorie",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "2.1 Acești Termeni și Condiții, împreună cu Politicile GDPR, se aplică de fiecare dată când Dumneavoastră accesați și utilizați Website-ul. Astfel, în măsura în care navigați și utilizați Website-ul, înțelegeți și acceptați că raportul dintre Dumneavoastră și Noi va fi supus acestor Termeni și Condiții și Politicilor GDPR, dispozițiile prezentate având forță juridică obligatorie. Altfel spus, accesarea și navigarea pe Website reprezintă acordul Dumnevoastră cu privire la aplicarea acestor Termeni și Condiții. În măsura în care nu sunteți de acord cu acești Termeni și Condiții sau cu Politicile GDPR, vă rugăm să nu mai utilizați Website-ul.",
                classNameText: "text-black"
            },
            {
                text: "2.2 Totodată, prin accesarea și utilizarea Website-ului declarați că îndepliniți toate condițiile legale pentru emiterea acordului prevăzut mai sus și că nu există niciun alt impediment legal care să atragă neaplicarea acestor Termeni și Condiții. Noi nu ne asumăm nicio răspundere în cazul în care informațiile furnizate de Dumneavoastră nu sunt corecte.",
                classNameText: "text-black"
            },
            {
                text: "2.3 Prin acceptarea Termenilor și Condițiilor trebuie să aveți în vedere că între Noi și Dumneavoastră nu se încheie un contract de asistență juridică.",
                classNameText: "text-black"
            },
            {
                text: "3. Serviciile pe care le oferim",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "3.1 Dezvoltarea de site-uri pentru utilizatori, site-uri ce conțin diferite funcții. Funcțiile ce vor fi stabilite de utilizator împreună cu noi în prealabil.",
                classNameText: "text-black"
            },
            {
                text: "3.2 Platforma Efluence oferă un mediu în care utilizatorul își poate organiza activitățile interne în vederea redactării actelor notariale, astfel acesta poate să delege/deslege cine să se ocupe de fiecare dosar în parte.",
                classNameText: "text-black"
            },
            {
                text: "3.3 Totodată acesta va dispune de un istoric al delegării dosarelor.",
                classNameText: "text-black"
            },
            {
                text: "3.4 Platforma Efluence va oferi posibilitatea de a vedea statisticile angajaților desemnați în redactarea actelor notariale.",
                classNameText: "text-black"
            },
            {
                text: "3.5 Platforma Effluence îi oferă utilizatorului opțiunea de a vedea programările făcute de clienții lor în site-urile făcute de noi.",
                classNameText: "text-black"
            },
            {
                text: "3.6 Effluence le oferă utilizatorilor și opțiunea de a scana o carte de identitate, în vederea eficientizării procesului de redactare al actului notarial, astfel acesta va putea să introducă datele din cartea de identitate prin intermediul acțiunii “copy paste”.",
                classNameText: "text-black"
            },
            {
                text: "4. Politica de Confidențialitate",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Ultima actualizare: 26.09.2020",
                classNameText: "text-black"
            },
            {
                text: "Noi respectăm confidențialitatea datelor dvs. personale și ne angajăm să protejăm și să gestionăm aceste date în mod responsabil. Această politică de confidențialitate descrie modul în care colectăm, stocăm, utilizăm și protejăm datele dvs. personale atunci când interacționați cu serviciile noastre.",
                classNameText: "text-black"
            },
            {
                text: "Datele Colectate:",
                classNameText: "text-black"
            },
            {
                text: "Colectăm următoarele tipuri de date personale de la dvs.:",
                classNameText: "text-black"
            },
            {
                text: "Informații de contact, cum ar fi numele, adresa de e-mail, numărul de telefon.",
                classNameText: "text-black"
            },
            {
                text: "Informații de identificare, cum ar fi numărul de identificare fiscală sau informații de facturare.",
                classNameText: "text-black"
            },
            {
                text: "Informații demografice, cum ar fi vârsta, sexul sau locația geografică.",
                classNameText: "text-black"
            },
            {
                text: "Informații despre utilizarea serviciilor noastre, cum ar fi datele de autentificare, informațiile de logare, informațiile despre activitatea dvs. pe site-ul nostru sau datele de navigare.",
                classNameText: "text-black"
            },
            {
                text: "Utilizarea Datelor:",
                classNameText: "text-black"
            },
            {
                text: "Utilizăm datele dvs. personale în scopurile următoare:",
                classNameText: "text-black"
            },
            {
                text: "Pentru a vă oferi acces și a vă gestiona contul pe site-ul nostru.",
                classNameText: "text-black"
            },
            {
                text: "Pentru a vă furniza serviciile și a vă satisface cererile sau comenzile.",
                classNameText: "text-black"
            },
            {
                text: "Pentru a vă trimite actualizări și comunicări referitoare la serviciile noastre.",
                classNameText: "text-black"
            },
            {
                text: "Pentru a îmbunătăți serviciile noastre, inclusiv pentru analiza datelor și a comportamentului utilizatorilor.",
                classNameText: "text-black"
            },
            {
                text: "Pentru a respecta legile și reglementările aplicabile.",
                classNameText: "text-black"
            },
            {
                text: "Protejarea Datelor:",
                classNameText: "text-black"
            },
            {
                text: "Ne angajăm să protejăm datele dvs. personale și să adoptăm măsuri de securitate adecvate pentru a preveni accesul neautorizat, divulgarea sau modificarea acestora.",
                classNameText: "text-black"
            },
            {
                text: "Drepturile Dvs. de Confidențialitate:",
                classNameText: "text-black"
            },
            {
                text: "Aveți dreptul să accesați, să corectați sau să ștergeți datele dvs. personale, precum și să vă retrageți consimțământul pentru prelucrarea acestora. Pentru a exercita aceste drepturi sau pentru întrebări legate de politica noastră de confidențialitate, vă rugăm să ne contactați la detaliile de contact afișate pe site.",
                classNameText: "text-black"
            },
            {
                text: "Aceasta este o politică generală de confidențialitate și poate fi supusă unor modificări ulterioare. Vă recomandăm să verificați periodic această politică pentru a fi la curent cu ultimele actualizări.",
                classNameText: "text-black"
            },
            {
                text: "5. Metode de Plată:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Prin utilizarea acestui serviciu, părțile sunt de acord că sistemul de procesare a informațiilor va fi Netopia, în conformitate cu toate condițiile specificate în acest acord.",
                classNameText: "text-black"
            },
            {
                text: "6. Legea Aplicabilă și Jurisdicția:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "6.1 Prin acceptarea Termenilor și Condițiilor înțelegeți și sunteți de acord că legea aplicabilă va fi legea din România, atât sub aspect material, cât și formal.",
                classNameText: "text-black"
            },
            {
                text: "6.2 Locul acceptării Termenilor și Condițiilor se consideră a fi România.",
                classNameText: "text-black"
            },
            {
                text: "6.3 Prin acceptarea acestor Termeni și Condiții sunteți de acord că orice dispută ce ar putea apărea între Dumneavoastră și Noi va fi soluționată de către instanțele de judecată din București, România.",
                classNameText: "text-black"
            },
            {
                text: "7. Metode de Plată:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Prin utilizarea acestui serviciu, părțile sunt de acord că sistemul de procesare a informațiilor va fi Netopia, în conformitate cu toate condițiile specificate în acest acord.",
                classNameText: "text-black"
            },
            {
                text: "8. Soluționarea Disputelor:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "În cazul oricăror dispute sau reclamații legate de serviciile oferite pe acest site, utilizatorii pot contacta echipa noastră de suport la [adresa de contact]. Vom depune eforturi pentru a rezolva orice problemă în mod prompt și eficient.",
                classNameText: "text-black"
            },
            {
                text: "9. Protecția Datelor:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Datele personale colectate de pe acest site vor fi prelucrate în conformitate cu reglementările aplicabile privind protecția datelor, inclusiv Regulamentul General privind Protecția Datelor (GDPR). Utilizatorii au dreptul de a accesa, corecta sau șterge datele lor personale, în conformitate cu politica noastră de confidențialitate.",
                classNameText: "text-black"
            },
            {
                text: "10. Declarație de Răspundere:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Prin utilizarea aplicației Effluence, sunteți de acord că echipa noastră și aplicația în sine își declină orice responsabilitate pentru eventualele neînțelegeri sau dispute ce pot apărea pe parcursul utilizării serviciilor noastre în faza de încercare gratuită.",
                classNameText: "text-black"
            },
            {
                text: "11. Modificarea Acordului Termeni și Condiții:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Ne rezervăm dreptul de a modifica sau actualiza acest Acord Termeni și Condiții în orice moment, fără notificare prealabilă. Utilizatorii sunt responsabili pentru verificarea periodică a acestui document pentru a fi la curent cu orice modificări.",
                classNameText: "text-black"
            },
            {
                text: "12. Utilizând aplicația Effluence, sunteți de acord că echipa noastră și aplicația în sine își declină orice responsabilitate pentru eventualele neînțelegeri sau dispute ce pot apărea pe parcursul utilizării serviciilor noastre în faza de încercare gratuită.",
                classNameText: "text-black"
            },
            {
                text: "13. Politica de Anulare a Abonamentului Lunar:",
                classNameText: "text-blue-600 text-center mt-8 mb-2"
            },
            {
                text: "Ne dorim să vă oferim cea mai bună experiență posibilă cu abonamentul nostru lunar. Înțelegem că pot apărea modificări în programul și nevoile dvs., așa că am creat o politică de anulare flexibilă pentru a vă ajuta să gestionați abonamentul într-un mod convenabil.",
                classNameText: "text-black"
            },
            {
                text: "Anularea cu Notificare Prealabilă de 2 Zile:",
                classNameText: "text-black"
            },
            {
                text: "Aveți dreptul să anulați abonamentul lunar cu cel puțin 2 zile înainte de data de începere a unei noi luni de plată. De exemplu, dacă abonamentul dvs. se reînnoiește în data de 1 a fiecărei luni, trebuie să notificați anularea până în data de 28 a lunii anterioare pentru a evita o nouă taxă de plată.",
                classNameText: "text-black"
            },
            {
                text: "Proces de Anulare Rapid:",
                classNameText: "text-black"
            },
            {
                text: "Pentru a anula abonamentul, ne puteți contacta prin metodele de contact afișate pe site sau puteți utiliza opțiunea de anulare disponibilă în contul dvs. online. Vă rugăm să furnizați informațiile necesare pentru a vă identifica și pentru a procesa anularea în mod eficient.",
                classNameText: "text-black"
            },
            {
                text: "Nicio Rambursare pentru Anulări Târzii:",
                classNameText: "text-black"
            },
            {
                text: "Anulările efectuate cu mai puțin de 2 zile înainte de începerea noii luni de plată nu vor fi rambursate, iar abonamentul va continua până la sfârșitul perioadei de facturare respective.",
                classNameText: "text-black"
            },
            {
                text: "Această politică de anulare este concepută pentru a vă oferi flexibilitatea necesară în gestionarea abonamentului lunar, asigurând în același timp o planificare eficientă a resurselor din partea noastră.",
                classNameText: "text-black mb-4"
            }
        ]
    }
]; 

const buttonsTerms = [
    {
        refference: "/",
        button: {
            classnames: "text-blue-600 px-1.5 py-0.5 text-lg bg-white mt-6 ml-10 rounded mx-auto",
            tag: "button",
            text: "Mergi Înapoi"
        }
    }
]

const terms = {
    classNameContainer: "flex flex-col text-left bg-blue-600 rounded-lg mt-6 mb-6", // Updated background color to blue
    textSections: textSectionsTerms,
    button : buttonsTerms
}

export default terms;
