const textSectionCardSectionAppointmentFeature = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-2xl font-bold",
    header: "Scanare carte de identiate",
    textArray: [
        {
            text: "",
            classNameText: "text-sm text-gray-600"
        }
    ]
}

const textSectionCardSectionFormAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Importarea de Date Fără Stres: Scanarea Cardului de Identitate Simplificată",
    textArray: [
        {
            text: "Prin această metodă, aveți posibilitatea de a importa datele personale din cartea de identitate prin scanarea buletinului",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionCalendarAppointment = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Încărcare Simplă a Fișierelor PDF",
    textArray: [
        {
            text: "Ulterior, încărcați documentul PDF rezultat în aplicație și beneficiați de funcționalitatea copy-paste",
            classNameText: "text-sm text-white"
        }
    ]
}

const textSectionCardSectionIntegrationWebsite = {
    tagHeader: "h1",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header text-white",
    header: "Păstrarea Eficientă a Datelor: Utilizarea Informațiilor Clienților pentru Viitor",
    textArray: [
        {
            text: "În urma acestui procedeu, veți avea avantajul de a păstra datele clientului pentru a le utiliza ulterior în documentele viitoare",
            classNameText: "text-sm text-white"
        }
    ]
}

const buttonCardSectionFeatureAppointment = {
    classnames: "",
    tag: "button",
    text: ""
}

const imageCardSectionFeatureAppointment = {
    classnames: "",
    src: "",
    width: "",
    height: "",
    alt: ""
}


const cardSectionFeatureAppointment = {
    classNameTextSection: "text-center my-2",
    classNameContainer: 'center grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3 mb-10',
    textSection: textSectionCardSectionAppointmentFeature,
    cards: [
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionFormAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionCalendarAppointment,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        },
        {
            classNameContainer: "bg-blue-600 p-6 shadow-md rounded-lg mt-6",
            imageContainer: "flex justify-center items-center",
            textSection: textSectionCardSectionIntegrationWebsite,
            button: buttonCardSectionFeatureAppointment,
            image: imageCardSectionFeatureAppointment
        }
    ]
}

export default cardSectionFeatureAppointment;