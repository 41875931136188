import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { MoonLoader } from 'react-spinners';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import Calendar from '../../sections/Calendar';
import CardSection from '../../sections/CardSection';
import DashboardClass from '../../data/pages/multiplePurpose/DashboardData';
import { fetchEvents, fetchAppointmentsCurrentMonth } from '../../../triggers/multiPurpose/dashboard.js';
import { sendDataScan } from '../../../triggers/files/files.js';
import Button from '../../elements/Button.js';

function Dashboard() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const history = useHistory();

    const [data, setData] = useState('{}');
    const [numberOfActiveFiles, setNumberOfActiveFiles] = useState('');
    const [appointments, setAppointments] = useState([]);

    useEffect(() => {
        const currentDate = new Date();
        localStorage.setItem("CRNT_MNTH", currentDate.getMonth() + 1);
        localStorage.setItem("CRNT_YR", currentDate.getFullYear());
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(isAuthenticated) getData();
            else {
                localStorage.clear();
                history.push("/");
            }
        }
    }, [isAuthenticated, loading, isUserOfOffice]);

    const getData = async () => {
        const [appointmentTotalNumber, allFilesByOfficeId, events] = await fetchEvents();
        setAppointments(events);
        setNumberOfActiveFiles(allFilesByOfficeId);
        setData(DashboardClass.DasboardText(appointmentTotalNumber, allFilesByOfficeId));
    }

    const getAppointments = async () => {
        const appointmentData = await fetchAppointmentsCurrentMonth();
        setData(DashboardClass.DasboardText(appointmentData, numberOfActiveFiles));
    }

    return(
        <>
            {
                data !== '{}'
                ?
                (
                    <>
                        <CardSection cardSection={data.informationAboutTheOffice} />
                        <div className="flex items-center justify-center pt-5 pr-9 space-x-8">
                        <Button onClick={() => window.location.href = "client-add"} button={DashboardClass.DasboardText().addClientManual} />
                        <Button onClick={() => window.location.href = "/task-add"} button={DashboardClass.DasboardText().addTask} />
                        <Button onClick={() => sendDataScan({
                                title: 'Scan ' + new Date().toLocaleString('ro-RO', {
                                    weekday: 'long',
                                    day: 'numeric',
                                    month: 'long',
                                    year: 'numeric',
                                    hour: '2-digit',
                                    minute: '2-digit'
                                }),
                                category: 'Scan',
                                status: 101,
                                start_date: new Date(),
                                document_code: 'A20-15-2020'
                            })}  button={DashboardClass.DasboardText().addFolder} />
                        <Button onClick={() => window.location.href = "/appointment"} button={DashboardClass.DasboardText().addAppointment} />
                        </div>
                        <Calendar appointments={appointments} fetchAppointments={getAppointments} />
                    </>
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </>
        
    );
}

export default Dashboard;
