import React, { useContext, useEffect, useState } from 'react';
import TextSection from '../../elements/TextSection';
import Input from '../../elements/Input';
import Button from '../../elements/Button';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import addnotData from '../../data/pages/notary/AddNotaryData';
import FieldVerifier from '../../../class/verifier_field.class';
import WarningUser from '../../data/pages/errors/WarningUserData';
import { submit } from '../../../triggers/notary/notary.js';

function AddNotary() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const [user, setUser] = useState('');
    const [warning, setWarning] = useState({});
    
    useEffect(() => {
        if(!loading) {
            if(!isUserOfOffice) window.location.href = "/welcome";
            if(!isAuthenticated) {
                localStorage.clear();
                window.location.href = "/";
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const emailVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'validEmail' },
        ];
        if (fieldVerifier.verifyField(value, fieldName, criteria)) return true;
        else return false;
    }

    const phoneNumberVerifier = (value) => {
        const fieldVerifier = new FieldVerifier();
        const fieldName = 'inregistrare';
        const criteria = [
            { name: 'notEmpty' },
            { name: 'validPhoneNumber' },
        ];
        if (fieldVerifier.verifyField(value, fieldName, criteria)) return true;
        else return false;
    }

    const onInputChange = (e, value) => {
        switch (value) {
            case 'notary': {
                const content = {...warning};
                if(!(emailVerifier(e.target.value) || phoneNumberVerifier(e.target.value))) {
                    console.log(!(emailVerifier(e.target.value) || phoneNumberVerifier(e.target.value)))
                    const warningObject = new WarningUser(
                        'error',
                        'Atentie!',
                        'Emailul sau numarul de telefon au fost gresit introduse!',
                        true
                    );
                    content['fields'] = warningObject.warning();
                } else content['fields'] = false;
                setWarning(content);
                setUser(e.target.value)
                break;
            }
            default:
                break;
        }
    }

    const handleSubmit = async () => {
        if(typeof warning['fields'] !== 'undefined'){
            if(!warning['fields']) {
                await submit(user);
                if(localStorage.getItem('ERR_MSG')) {
                    const warningObject = new WarningUser(
                        localStorage.getItem('ERR_TYP'),
                        localStorage.getItem('ERR_NM'),
                        localStorage.getItem('ERR_MSG'),
                        localStorage.getItem('ERR_CRD')
                    );
                    const data = {...warning};
                    data['fields'] = warningObject.warning();
                    setWarning(data);
                    localStorage.removeItem('ERR_TYP');
                    localStorage.removeItem('ERR_NM');
                    localStorage.removeItem('ERR_MSG');
                    localStorage.removeItem('ERR_CRD');
                } else {
                    const data = {...warning};
                    data['fields'] = false;
                    setWarning(data);
                }
            }
            else {
                const content = {...warning}; 
                const warningObject = new WarningUser(
                    'error',
                    'Atentie!',
                    'Emailul sau numarul de telefon au fost gresit introduse!',
                    true
                );
                content['fields'] = warningObject.warning();
                setWarning(content)
            }
        } else {
            const content = {...warning}; 
            const warningObject = new WarningUser(
                'error',
                'Atentie!',
                'Emailul sau numarul de telefon nu au fost introduse!',
                true
            );
            content['fields'] = warningObject.warning();
            setWarning(content)
        }
    }

    return (
        <div className={addnotData.classNameContainerPage}>
            <div className={addnotData.classNameContainerCard}>
                <TextSection textSection={addnotData.textSection} />
                <Input onChange={(e) => onInputChange(e, 'notary')} input={addnotData.credentials} />
                {warning['fields'] ? <TextSection textSection={warning['fields']} /> : null }
                <div class="flex justify-between ">
                    <Button onClick={() => handleSubmit()} button={addnotData.submitButton} />        
                    <Button onClick={() => window.location.href = "/notary"} button={addnotData.cancelButton} />
                </div>
            </div>
        </div>
    );
}

export default AddNotary;
