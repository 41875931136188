class NotariesDataClass {
    static NotariesDataText(notaries, handleAccept, handleDecline, notariesData, isAdmin) {
        const headerSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Lista Angajati",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const addEmployeeManually = {
            classnames: isAdmin ? "text-white bg-blue-600 px-2 py-2 rounded mt-2 mx-1" : "",
            tag: "button",
            text: isAdmin ? "Adauga angajat manual" : ""
        };

        const addEmployeeLink = {
            classnames: isAdmin ? "text-blue-600 bg-white border border-blue-600 px-2 py-2 rounded mt-2 mx-1" : "",
            tag: "button",
            text: isAdmin ? "Link adaugare angajat" : ""
        };
        
        const employeeList = {
            classNameContainer: 'your-container-class-name',
            headers: (isAdmin && notaries.filter(item => item.status == false).length > 0) ?
                     ['Nume', 'Numar total de taskuri', 'Numar de taskuri nefinalizate', 'Detalii', 'Accepta', 'Respinge'] : 
                     ['Nume', 'Numar total de taskuri', 'Numar de taskuri nefinalizate', 'Detalii'],
            rows: notaries.map((item, index) => ({
                cell: [
                  item.name,
                  notariesData[index].all_tasks,
                  notariesData[index].active_tasks
                ],
                additionalComponents: [
                    {
                        tagComponent: "button",
                        data: {
                            classnames: "text-white bg-blue-600 px-2 py-1 rounded",
                            tag: "button",
                            text: "Detalii"
                        },
                        location: "/notary/" + item.notary_id
                    },
                    ...(isAdmin && !item.status ? [
                        {
                            tagComponent: 'button',
                            data: {
                                classnames: "bg-green-600 text-white px-2 py-1 rounded ml-2",
                                tag: "button",
                                text: "Acceptă"
                            },
                            onClick: () => handleAccept(item.notary_id)
                        },
                        {
                            tagComponent: 'button',
                            data: {
                                classnames: "bg-red-600 text-white px-2 py-1 rounded ml-2",
                                tag: "button",
                                text: "Respinge"
                            },
                            onClick: () => handleDecline(item.notary_id)
                        }
                    ] : [])
                ],
              })),
        };
        
        return {
            headerSection: headerSection,
            addEmployeeManually: addEmployeeManually,
            addEmployeeLink: addEmployeeLink,
            employeeList: employeeList
        }
    }
}

export default NotariesDataClass;