class FolderDataClass {
    constructor(){}
    static FolderDataText(files, deleteFile){
        const headerSection = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Lista Documnte",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }
        
        const addFolder = {
            classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-4 mx-1 h-10",
            tag: "button",
            text: "Scan nou"
        }

        const searchFile = {
            classnames: "text-white bg-blue-600 px-2 py-0.5 rounded ml-4 mt-4 h-10",
            tag: "button",
            text: "Cauta scan"
        }

        const searchBoxFiles = {
            classnames: "mt-4 h-10",
            classnamesInput: "border border-blue-500 rounded p-2",
            name: "",
            placeholder: "Cauta Scan",
            value: ""
        }

        const dropDownMenuParameterSearch ={
            tagDropdown: "select",
            classNameContainer: "mx-2",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Selecteaza Parametrul"
                },
                {
                    value: "document_code",
                    label: "Cod Dosar"
                },
                {
                    value: "title",
                    label: "Titlu"
                },
                {
                    value: "category",
                    label: "Categorie"
                }
            ]
        }

        const dropdownMenuFilter ={
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown: "text-blue-500 border border-blue-500 bg-white px-2 py-2 rounded w-full mt-4 h-10",
            options: [
                {
                    value: "",
                    label: "Filtreaza"
                },
                {
                    value: 101,
                    label: "In asteptare"
                },
                {
                    value: 200,
                    label: "In lucru"
                },
                {
                    value: 500,
                    label: "Arhivate"
                }
            ]
        }
        
        
        const folderList = {
            classNameContainer: '',
            headers: ['Titlu', 'Detalii'],
            rows: files.map((item) => (
                {
                    cell: [item.title],
                    additionalComponents: [
                        {
                            tagComponent: "button",
                            data: {
                                classnames: "text-white bg-blue-600 px-2 py-1 rounded",
                                tag: "button",
                                text: "Detalii"
                            },
                            location: "/file/" + item.file_id,
                        }
                    ] 
                }
            ))
        }
        
        return {
            header: headerSection,
            addFolder: addFolder,
            searchFile: searchFile,
            searchBoxFiles: searchBoxFiles,
            dropDownMenuParameterSearch: dropDownMenuParameterSearch,
            dropdownMenuFilter: dropdownMenuFilter,
            folderList: folderList
        }
    }
}

export default FolderDataClass;