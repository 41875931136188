class AddTaskDataClass {
    static AddTaskDataText(isEdited, notaries) {
        const textSectionAddTask ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Adaugă Task",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const textSectionDate ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Deadline Task:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };

        const textSectionNotary ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "Adauga Angajat:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };


        const textSectionDateStart ={ 
            tagHeader: "div",
            tagText: "div",
            classNameContainer: "text-section-container",
            classNameHeader: "text-header",
            header: "De la:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        };
        
        const title = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
            name: "",
            placeholder: "Titlu Task",
            value: ""
        };
        
        const description = {
            classnames: "mt-4",
            classnamesInput: "border border-blue-500 rounded-full p-2 pl-4 w-full  ",
            name: "",
            placeholder: "Descriere",
            value: ""
        };
        
        const textSectionNotaries = {
            tagHeader: "h1",
            tagText: "div",
            classNameContainer: "mr-4",
            classNameHeader: "text-header text-blue-500",
            header: "Lista Notari:",
            textArray: [
                {
                    classNameText: "text-content",
                    text: ""
                }
            ]
        }
        
        const notariesDisplayed = {
            classNameContainer: "flex mr-2",
            cards: notaries.map((item) => (
                {
                    user: true,
                    classNameContainer: "flex bg-white p-2 shadow-md rounded-lg",
                    userIcons: {
                        classNamesContainer: "",
                        usernames: [item.name]
                    },
                    textSection: {
                        tagHeader: "h1",
                        tagText: "div",
                        classNameContainer: "",
                        classNameHeader: "text-header text-blue-500",
                        header: item.name,
                        textArray: [
                            {
                                classNameText: "text-content",
                                text: ""
                            }
                        ]
                    }
                   
                }
            ))
        }

        const dropdownaddNotary = {
            tagDropdown: "select",
            classNameContainer: "",
            classNameDropdown:
              "border border-blue-500 text-blue-500 bg-white px-2 py-2 rounded w-full",
            options: [
              {
                value: "",
                label: "Alege Notar",
              },
              ...notaries.map((item) => ({
                value: item.notary_id,
                label: item.name,
              })),
            ],
        }

        const buttonaddNotary = {
            classnames: "border border-blue-500 text-white bg-blue-500 px-2 py-2 mx-2 rounded",
            tag: "button",
            text: "Adauga Notar"
        }


        const createButton = {
            classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
            tag: "button",
            text: isEdited ? "Salvează" : "Adauga"
        };
        
        const cancelButton = {
            classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
            tag: "button",
            text: "Cancel"
        };
        
        return {
            classNameContainer: "min-h-screen flex items-center justify-center ",
            classNameContainerCard: "min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
            textSectionAddTask: textSectionAddTask,
            textSectionDate: textSectionDate,
            textSectionNotary: textSectionNotary,
            title: title,
            textSectionDateStart: textSectionDateStart,
            description: description,
            addButton: createButton,
            cancelButton: cancelButton,
            notariesDisplayed: notariesDisplayed,
            buttonaddNotary: buttonaddNotary,
            dropdownaddNotary: dropdownaddNotary,
            textSectionNotaries: textSectionNotaries
        };
    }
}

export default AddTaskDataClass;
