import React, { useState } from 'react';
import Button from '../elements/Button';
import Image from '../elements/Image';
import TextSection from '../elements/TextSection';

function TextPresentation({ textPresentation }) {
  return (
    <div className={textPresentation.classNameContainer}>
        <TextSection textSection={textPresentation.textSection} />
        <Button button={textPresentation.button} />
        <Image image={textPresentation.image} />
    </div>
  );
}

export default TextPresentation;
