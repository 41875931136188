import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";

// Elements
import CardSection from '../../sections/CardSection';
import Image from '../../elements/Image';
import Button from '../../elements/Button';
import TextSection from '../../elements/TextSection';
import { AuthContext } from '../../../architecture/auth/AuthentificationContext';
import WelcomePanelDataClass from '../../data/pages/multiplePurpose/WelcomePanelData';
import { MoonLoader } from 'react-spinners';
import { fetchUserRefresh } from '../../../triggers/authentification/authentification.js';

function WelcomePanel() {
    const {loading, isAuthenticated, isUserOfOffice} = useContext(AuthContext);
    const [loadingStatus, setLoadingStatus] = useState(true);
    const [data, setData] = useState('');
    const [user, setUser] = useState(null);

    useEffect(() => {
        if(!loading) {
            setLoadingStatus(true);
            if(isAuthenticated) {
                getUserData();
            } else {
                window.location.href = '/';
            }
        }
    }, [loading, isAuthenticated, isUserOfOffice]);

    const getUserData = async () => {
        const userData = await fetchUserRefresh();
        setUser(userData);
        if(userData.admin) {
            window.location.href = '/dashboard';
        }
        setLoadingStatus(false);
    };

    return(
        <div>
            {
                !loadingStatus
                ?
                (
                    user && !user.status
                    ?
                    <TextSection textSection={WelcomePanelDataClass.WelcomePanelClass().textSectionCreateOfficeCard} />
                    :
                    null
                )
                :
                (
                    <MoonLoader
                        className='flex m-auto mt-32'
                        color="#2563eb"
                        size={140}
                    />
                )
            }
        </div>
    );
}
export default WelcomePanel;