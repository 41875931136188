import getData from '../../request_methods/dataGet.js';
import postData from '../../request_methods/dataPost.js';
import putData from '../../request_methods/dataPut.js';
import deleteData from '../../request_methods/dataDelete.js';
import RequestClass from '../../class/request.class.js';
import APIError from '../../class/error.class.js';

export const handleCreateClient = async (clientData) => {
    try {
        const requestObject = new RequestClass('/client/create/post', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await postData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetClient = async (clientData) => {
    try {  
        const requestObject = new RequestClass('/client/by-client-id/get', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleGetAllClients = async (clientData) => {
    try {  
        const requestObject = new RequestClass('/client/all-by-office-id/get', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleUpdateClient = async (clientData) => {
    try {
        const requestObject = new RequestClass('/client/by-client-id/put', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await putData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleDeleteClient = async (clientData) => {
    try {
        const requestObject = new RequestClass('/client/by-client-id/delete', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await deleteData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}

export const handleScanId = async (clientData) => {
    try {
        const requestObject = new RequestClass('/client/scan-id/post', clientData, 200, localStorage.getItem("TKN_ATH"));
        const response = await getData(requestObject.getRequest());
        if(!response.error) return response.data;
        else throw new APIError(response.error_name, response.error_httpCode, true, response.error_message);
    } catch(error) {
        if(error.httpCode === 500) 
            throw new APIError("A intervenit o erroare interna", 500, true, "Va rugam sa reincercati procesul, daca eroarea persista va rugam sa contactati echipa pentru suport.");
        else
            throw new APIError("A intervenit o eroare in procesarea datelor", error.httpCode, true, "Incercati din now procesul.");
    }
}