import secureStorage from '../../modules/secureStorage/secure.js';
import handleSignIn from '../../modules/authentification/signIn.js';
import handleSignUp from '../../modules/authentification/signUp.js';
import {handleGetNotary} from '../../modules/notary/notary.js';

export async function submitSignIn(credentials, password) {
    try {
        const response = await handleSignIn({
            credentials: credentials,
            password: password
        });
        localStorage.clear();
        localStorage.setItem("TKN_ATH", response.token)

        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        secure.setItem("UDP_UN", {
            notary_id: response.user_data.notary_id,
            office_id: response.user_data.office_id,
            admin: response.user_data.admin,
            status: response.user_data.status,
            name: response.user_data.name,
            email_effluence: response.user_data.email_effluence,
            phone_number: response.user_data.phone_number,
            email: response.user_data.email,
        });
        if(response.user_data.status) window.location.href = "/dashboard";
        else window.location.href = "/welcome";
        return true;
    } catch (error) {
        localStorage.setItem('ERR_TYP', 'error');
        localStorage.setItem('ERR_NM', error.name);
        localStorage.setItem('ERR_MSG', error.message);
        localStorage.setItem('ERR_CRD', true);
        return false;
    }
}

export async function submitSignUp(email, name, phoneNumber, password, officeId) {
    try {
        const response = await handleSignUp({
            email: email,
            name: name,
            phone_number: phoneNumber,
            password: password,
            office_id: officeId
        });
        console.log(response)
        localStorage.setItem("TKN_ATH", response.token)

        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        secure.setItem("UDP_UN", {
            notary_id: response.user_data.notary_id,
            office_id: response.user_data.office_id,
            admin: response.user_data.admin,
            status: response.user_data.status,
            name: response.user_data.name,
            email_effluence: response.user_data.email_effluence,
            phone_number: response.user_data.phone_number,
            email: response.user_data.email,
        });
        window.location.href = "/welcome";
        return true;
    } catch (error) {
        localStorage.setItem('ERR_TYP', 'error');
        localStorage.setItem('ERR_NM', error.name);
        localStorage.setItem('ERR_MSG', error.message);
        localStorage.setItem('ERR_CRD', true);
        return false;
    }
}

export async function fetchUser() {
    try {
        const token = localStorage.getItem("TKN_ATH");
        const secure = secureStorage.secureStorage(token);
        const user = secure.getItem("UDP_UN");
        return user;
    } catch (error) {
        console.error("Failed to fetch user data:", error);
        return null;
    }
}

export async function fetchUserRefresh() {
    try {        
        const secure = secureStorage.secureStorage(localStorage.getItem("TKN_ATH"));
        const response = await handleGetNotary({
            notary_id: secure.getItem("UDP_UN").notary_id
        });

        secure.setItem("UDP_UN", {
            notary_id: response.notary_id,
            office_id: response.office_id,
            admin: response.admin,
            status: response.status,
            name: response.name,
            email_effluence: response.email_effluence,
            phone_number: response.phone_number,
            email: response.email,
        });
        const user = secure.getItem("UDP_UN");
        return user;
    } catch (error) {
        console.error("Failed to fetch user data:", error);
        return null;
    }
}