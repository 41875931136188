import React, { useEffect, useState } from "react";
import Logo from '../elements/Image';
import Button from '../elements/Button';
import Icon from '../elements/Image';
import MenuClass from '../data/layoutDefault/MenuData';
import { fetchAllTasksByUserId } from '../../triggers/tasks/tasks'
import { isAdmin } from '../../triggers/notary/notary'

function Menu() {
    const [tasks, setTasks] = useState([]);
    const [admin, setAdmin] = useState(false);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const response = await fetchAllTasksByUserId();
        setTasks([...response].filter((item) => item.status == false));
        const adminStatus = await isAdmin(); // Call isAdmin function
        setAdmin(adminStatus); // Set admin state
    }

    const mappingMethod = (buttonData) => {
        if (buttonData) { // Add this check
            return (
                <div className={buttonData.classNameContainer}>
                    <a
                        href={buttonData.reference}
                        className="flex items-center py-4 px-8 font-semibold text-gray-600 transition duration-300 hover:bg-blue-100 hover:shadow-md"
                    >
                        <div className="w-7 h-7 px-1 py-1 mr-3 flex items-center justify-center rounded-full">
                            <Icon image={buttonData.icon}/>
                        </div>
                        <Button button={buttonData.button}/>
                    </a>
                </div>
            );
        }
        return null; // Return null for falsy buttonData
    };

    return (
        <div className={MenuClass.MenuText(admin, tasks).classNameContainer}>
            <Logo image={MenuClass.MenuText(admin, tasks).logo} />
            <div className="flex flex-col h-screen">
                {MenuClass.MenuText(admin, tasks).buttons.map(buttonData => mappingMethod(buttonData))}
            </div>
        </div>
    );
}

export default Menu;