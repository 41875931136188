const textSection ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Autentificare",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const officeId = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Office ID",
    value: ""
};

const effluenceEmail = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Email/Numar de Telefon",
    value: ""
};

const password = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "password",
    name: "",
    placeholder: "Parola cont",
    value: ""
};


const textQuestion = {
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "",
    textArray: [
        {
            classNameText: "text-content",
            text: "Daca nu aveti cont Enotar, puteti merge la pagina de inregistrare!"
        }
    ]
};

const redirectButton = {
    reference: "/signup",
    button: {
        classnames: "text-blue-600 rounded my-2",
        tag: "button",
        text: "Pagina de inregistrare"
    }
};

const submitButton = {
    classnames: "text-white bg-blue-600 px-2 py-2 rounded mt-2",
    tag: "button",
    text: "Logare in cont"
};

const signInData = {
    classNameContainerPage: "min-h-screen flex items-center justify-center ",
    classNameContainerCard: "min-w-[300px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
    classNameContainerQuestion: "",
    textSection: textSection,
    officeId: officeId,
    effluenceEmail: effluenceEmail,
    password: password,
    textQuestion: textQuestion,
    redirectButton: redirectButton,
    submitButton: submitButton
};

export default signInData;