const textSectionHeader ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Creeare cont birou",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const textSectionWorkingHours ={ 
    tagHeader: "div",
    tagText: "div",
    classNameContainer: "text-section-container",
    classNameHeader: "text-header",
    header: "Interval orar program",
    textArray: [
        {
            classNameText: "text-content",
            text: ""
        }
    ]
};

const hour = {
    classnames: "mt-4",
    classnamesInput: " text-center border border-blue-500  rounded-full p-1 w-full  ",
    type: "text",
    name: "",
    placeholder: "00",
    value: ""
};

const minutes = {
    classnames: "mt-4",
    classnamesInput: "appearance-none text-center border border-blue-500 rounded-full p-1 w-full ",
    type: "text",
    name: "",
    placeholder: "00",
    value: ""
};

const address = {
    classnames: "mt-4",
    classnamesInput: "appearance-none border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Adresa Biroului",
    value: ""
};

const name = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Nume Birou",
    value: ""
};

const email = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    name: "",
    placeholder: "Email Birou",
    value: ""
};

const password = {
    classnames: "mt-4",
    classnamesInput: "border border-blue-500 rounded-full p-2 w-full  ",
    type: "password",
    name: "",
    placeholder: "Parola",
    value: ""
};

const createButton = {
    classnames: "text-white bg-blue-600 px-2 mt-5 py-2 rounded mt-2",
    tag: "button",
    text: "Creare"
};

const cancelButton = {
    classnames: "text-white bg-gray-400 px-2 py-2 mt-5 rounded mt-2",
    tag: "button",
    text: "Cancel"
};

const clientappData = {
    classNameContainer: "min-h-screen flex items-center justify-center ",
    classNameContainerCard: "min-w-[400px] bg-white rounded-lg shadow-md p-6 w-full md:w-2/3 lg:w-1/2",
    classNameContainerQuestion: "",
    textSectionHeader: textSectionHeader,
    textSectionWorkingHours: textSectionWorkingHours,
    address: address,
    name: name,
    email: email,
    password: password,
    hour: hour,
    minutes: minutes,
    createButton: createButton,
    cancelButton: cancelButton
    
};

export default clientappData;