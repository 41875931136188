import React, { useEffect, useState } from 'react';

// import sections

import Header from '../../sections/Header.js';
import HeroWithVideo from '../../sections/HeroWithVideo.js';
import CardSection from '../../sections/CardSection.js';
import TextPresentation from '../../sections/TextPresentation.js';
import Contact from '../../sections/Contact.js';
import Footer from '../../sections/Footer.js';
import landingPageDataRomanian from'../../data/pages/presentation/LandingPageDataRomanian.js';
import landingPageDataEnglish from'../../data/pages/presentation/LandingPageDataEnglish.js';

function Landing() {
  const [landingPageData, setLandingPageData] = useState(navigator.language.substring(0, 2) === 'en' ? landingPageDataEnglish : landingPageDataRomanian);
  useEffect(() => {
    if (localStorage.getItem("language") != null && localStorage.getItem("language").toLowerCase() === 'ro') {
      setLandingPageData(landingPageDataRomanian); 
    } else {
      setLandingPageData(landingPageDataEnglish);
    }
  }, []);

  return (
    <>
      <Header header={landingPageData.header}/>
      <div className='container mx-auto px-4 md:px-8 lg:px-16 xl:px-20'>
        <HeroWithVideo heroWithVideo={landingPageData.heroWithVideo}/>
        <CardSection cardSection={landingPageData.cardSectionFeatures}/>
        <TextPresentation textPresentation={landingPageData.textPresentation}/>
        <div id="appointment">
          <CardSection cardSection={landingPageData.cardSectionFeatureAppointment}/>
        </div>
        <div id="idscan">
          <CardSection cardSection={landingPageData.cardSectionFeatureIdScan}/>
        </div>
        <div id="management">
          <CardSection cardSection={landingPageData.cardSectionFeatureManagement}/>
        </div>
        {/* <CardSection cardSection={landingPageData.cardSectionPrices}/> */}
        <Contact contact={landingPageData.contact}/>
      </div>
      <Footer footer={landingPageData.footer}/>
    </>
  );
}

export default Landing;